import { Button, Grid, Theme, Typography, Card, CardContent } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';
import { actions as appointmentActions, IReturn as Appointment } from '../../../api/resources/Appointment';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { actions as illegibleWizardActions } from '../../../actions/illegibleWizard';
import { ApplicationState } from '../../../reducers';
import { getActiveWizard, getIllegibleWizard, getNewWizard, getAlreadyConfirmed } from '../../../selectors/wizard';
import { IWizardState as IllegibleWizard } from '../../../reducers/illegibleWizard';
import { IWizardState as NewWizard } from '../../../reducers/newWizard';
import Moment from 'react-moment';
import { IReturn as LivescanVendor } from '../../../api/resources/LivescanVendor';
import { getEnvironmentID, isEnvironmentEmployer } from '../../../selectors/environment';
import QRCode from 'qrcode.react';
import { Alert } from '@material-ui/lab';
import { ErrorRounded } from '@material-ui/icons';

interface IProps {
	activeWizard?: string;
	dispatch: any;
	environmentID?: number;
	illegibleWizard: IllegibleWizard;
	isEnvironmentEmployer: boolean;
	newWizard: NewWizard;
	alreadyConfirmed?: boolean;
}

interface IState {
	isConfirming: boolean;
	isError: boolean;
	errorType?: ERRORTYPES;
	errorText?: string;
	appointment?: Appointment;
	lsv?: LivescanVendor;
}

enum ERRORTYPES {
	UNKNOWN,
	PAYMENT,
	TIMING
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		activeWizard: getActiveWizard(state),
		environmentID: getEnvironmentID(state),
		illegibleWizard: getIllegibleWizard(state),
		isEnvironmentEmployer: isEnvironmentEmployer(state),
		newWizard: getNewWizard(state),
		alreadyConfirmed: getAlreadyConfirmed(state),
	}
}

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		},
		timeSelection: {
			flex: 1,
		},
		fill: {
			flex: 1,
		},
		map: {
			flex: 1,
			'& svg': {
				width: '100%',
			},
			'& canvas': {
				width: '100%',
			}
		}
	});

type FullProps = WithStyles<typeof styles> & IProps & RouteComponentProps;
class SchedulerConfirmation extends React.PureComponent<FullProps, IState> {
  
  state = {
	isConfirming: true,
	isError: false,
	appointment: undefined as Appointment|undefined,
	lsv: undefined as LivescanVendor|undefined,
	errorType: undefined as ERRORTYPES|undefined,
	errorText: undefined as string|undefined,
  };

  public componentDidMount() {
	const likelyDuplicate = this.preventDuplicate();
	
	if (!likelyDuplicate) {
		this.confirmAppointment();
	}	
  }

  public componentWillUnmount() {
	const { dispatch, history, alreadyConfirmed } = this.props;

	if (alreadyConfirmed) {
		history.push("/");
	}
  }
  
  render() {
    const { activeWizard, classes, illegibleWizard, newWizard } = this.props;
	const { appointment, isConfirming, isError, errorType, errorText, lsv } = this.state;

	const qrpayload = JSON.stringify(appointment);

	return (
      <AnonLayout title={
		  activeWizard ? (
			  isConfirming ? 
			  'We are confirming your appointment...' :
			  isError ? 
			  'Uh oh' :
			  'Your appointment is scheduled'
		   ) : 'Uh oh'
		} subtitle={
			activeWizard ? (
				isConfirming ? 
				undefined : 
				isError ? 
				undefined :
				'Please print this page and bring it with you'
			) : undefined
		}>
        {activeWizard ? (
			isConfirming ? (
				<Card>
					<CardContent>
						Please wait...
					</CardContent>
				</Card>
			) : (
				isError ? (
					<Card>
						<CardContent>
							<Alert severity="error">We were unable to schedule your appointment</Alert>
							
							{errorType == ERRORTYPES.UNKNOWN && (
								<>
									<Typography variant="h6">We encountered an unknown error trying to schedule your appointment. Please try again later.</Typography>
								</>
							)}
							{errorType == ERRORTYPES.PAYMENT && (
								<>
									<Typography variant="h6">We ran into an issue trying to process your payment: {errorText} Please correct your payment information and try again.</Typography>
									<Button color="primary" component={RouterLink} to='/payment' variant='contained'>Fix payment details</Button>
								</>	
							)}
							{errorType == ERRORTYPES.TIMING && (
								<>
									<Typography variant="h6">We ran into an issue scheduling your appointment: {errorText} Please select an alternate appointment slot and try again.</Typography>
									<Button color="primary" component={RouterLink} to={`/schedule/${activeWizard === 'new' ? newWizard.lsv?.id : illegibleWizard.lsv?.id}/${activeWizard}`} variant='contained'>Select alternate time</Button>
								</>	
							)}							
						</CardContent>
					</Card>
				) : (
					<Card>            
						<CardContent>
							<Grid container spacing={2}>
								<Grid item>
									<Typography variant="h6">Appointment Date and Time</Typography>
									<Typography variant="body1">
										<Moment format="L" tz={lsv?.timezone}>{appointment?.date_time}</Moment><br />
										<Moment format="LT" tz={lsv?.timezone}>{appointment?.date_time}</Moment>
									</Typography>

									<Typography variant="h6">Appointment Location</Typography>
									<Typography variant="body1">
										{lsv?.name}<br />
										{lsv?.location_street1}<br />
										{lsv?.location_city}, {lsv?.location_state} {lsv?.location_postal}
									</Typography>

									{lsv?.applicant_instructions && <>
										<Typography variant="h6">Arrival Instructions</Typography>
										<Typography variant="body1">
											{lsv?.applicant_instructions}
										</Typography>
									</>
									}
									
									<Typography variant="h6">Reg #</Typography>
									<Typography variant="body1">{appointment?.id}</Typography>
								</Grid>
								<Grid item className={classes.fill} />
								<Grid item>
									<QRCode value={qrpayload} size={300} />
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				)
			)
		) : (
			<Card>
				<CardContent>
					<Typography variant="body1">It looks like you tried to refresh the appointment confirmation page. If you need to book an appointment, please return to the beginning of the process.</Typography>
					<Button component={RouterLink} to='/' variant='contained'>Return to start</Button>
				</CardContent>
			</Card>
		)}
		
      </AnonLayout>
    );
  }

  private preventDuplicate() {
	const { alreadyConfirmed, history } = this.props;

	if (alreadyConfirmed) {
		history.push("/");
		return true;
	}
	
	return false;
  } 
 
  private async confirmAppointment() {
	const { activeWizard, dispatch, environmentID, illegibleWizard, isEnvironmentEmployer, newWizard } = this.props;
	if (!activeWizard) {
	  return;
	}

	const employer = isEnvironmentEmployer ? environmentID : null;
	let appointment = undefined as {result:Appointment}|undefined;
	let lsv = undefined as LivescanVendor|undefined;
	// debugger;
	if (activeWizard === 'new') {
		appointment = await dispatch(appointmentActions.schedule(undefined, {
			mode: 'new',
			employer: employer as number,
			lsv: newWizard.lsv?.id as number,
			dateTime: newWizard.appointmentDateTime as Date,
			applicantType: newWizard.applicantType,
			location: newWizard.location,
			notes: newWizard.notes,
			vendor: newWizard.vendor,
			firstName: newWizard.firstName,
			middleName: newWizard.middleName,
			lastName: newWizard.lastName,
			dob: newWizard.dob,
			ssn4: newWizard.ssn4,
			phone: newWizard.phone,
			email: newWizard.email,
			gender: newWizard.gender,
			race: newWizard.race,
			height: newWizard.height,
			weight: newWizard.weight,
			eye: newWizard.eye,
			hair: newWizard.hair,
			pob: newWizard.pob,
			citizenship: newWizard.citizenship,
			reason: newWizard.reason,
			residence: newWizard.residence,
			waiver_signatures: newWizard.waiverSignatures,
			paymentMethod: newWizard.paymentMethod,
			cardNumber: newWizard.cardNumber,
			cardExpiry: newWizard.cardExpiry,
			cardName: newWizard.cardholderName,
			cardCode: newWizard.cardCode,
			cardZip: newWizard.cardZip
		})).catch((error: any) => {
			if (!error.result) {
				this.setState({
					isConfirming: false,
					isError: true,
					errorType: ERRORTYPES.UNKNOWN,
					errorText: "An unknown error has occurred. Please try your request again later."
				});
			} else {
				const errorTypeMap: { [key: string]: ERRORTYPES } = {
					'PAYMENT': ERRORTYPES.PAYMENT,
					'TIMING': ERRORTYPES.TIMING
				};
				
				this.setState({
					isConfirming: false,
					isError: true,
					errorType: errorTypeMap[error.result.error_type] || ERRORTYPES.UNKNOWN,
					errorText: error.result.error
				});
			}
		});
		lsv = newWizard.lsv as LivescanVendor;
	}
	else {
		appointment = await dispatch(appointmentActions.schedule(undefined, {
			mode: 'illegible',
			employer: environmentID as number,
			lsv: illegibleWizard.lsv?.id as number,
			dateTime: illegibleWizard.appointmentDateTime as Date,
			applicantId: illegibleWizard.applicant?.id as number
		}))
		lsv = illegibleWizard.lsv as LivescanVendor;
	}

	// If we received an empty return value from dispatch, something went wrong. Don't set state.
	if (!appointment) {
		return;
	}
	
	if (activeWizard === 'new') 
		await dispatch(newWizardActions.confirm());
	else
		await dispatch(illegibleWizardActions.confirm());
	
	this.setState({
		isConfirming: false,
		isError: false,
		appointment: appointment?.result as Appointment,
		lsv
	});
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerConfirmation)));