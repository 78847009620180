import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as BlockedTime, actions as blockedTimeActions } from '../../../api/resources/BlockedTime';
import moment from 'moment';

const styles = (theme: Theme) =>
    createStyles({
        
    });

type PartialBlockedTime = Partial<BlockedTime>;

interface IState {
    block?: PartialBlockedTime;
}

interface IProps {
    block?: BlockedTime;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class AvailabilityBlockAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        block: {} as PartialBlockedTime,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            const { block } = this.props;
        
            this.setState({
                block: block ? {
                    ...block,
                    start_datetime: moment(block.start_datetime).format("YYYY-MM-DDTHH:mm"),
                    end_datetime: moment(block.end_datetime).format("YYYY-MM-DDTHH:mm"),
                } : {} as PartialBlockedTime
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open } = this.props;
        const { block } = this.state;

        const valid = (block.start_datetime && block.end_datetime);
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{block?.id ? (<>Edit Blocked Time</>) : (<>Add Blocked Time</>)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {block?.id ? (
                            <>
                                Edit blocked time details below.
                            </>
                        ) : (
                            <>
                                Enter new blocked time details below.
                            </>
                        )}                        
                    </DialogContentText>
                    <TextField
                        id="start-time"
                        label="Blocked time start"
                        type="datetime-local"
                        value={block.start_datetime}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(evt) => {
                            const block = {
                                ...this.state.block,
                                start_datetime: evt.target.value
                            };
                            this.setState({block});
                        }}
                    />
                    <TextField
                        id="end-time"
                        label="Blocked time end"
                        type="datetime-local"
                        value={block.end_datetime}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(evt) => {
                            const block = {
                                ...this.state.block,
                                end_datetime: evt.target.value
                            };
                            this.setState({block});
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!valid} onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { dispatch } = this.props;
        const { block } = this.state;

        const transformedBlock = {
            ...block,
            start_datetime: moment(block.start_datetime).toISOString(),
            end_datetime: moment(block.end_datetime).toISOString(),
        }

        if (block?.id) {
            await dispatch(blockedTimeActions.update(block.id, transformedBlock)).catch(console.error);
        } 
        else {
            await dispatch(blockedTimeActions.create(undefined, transformedBlock)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(AvailabilityBlockAddEditModal));