import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as WaiverSignature, actions as waiverSignatureActions } from '../../api/resources/WaiverSignature';

const styles = (theme: Theme) =>
    createStyles({
        
    });

interface IProps {
    signature: WaiverSignature;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class WaiverDeleteModal extends React.PureComponent<FullProps> {
    render() {
        const { open } = this.props;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete Waiver Signature?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you wish to delete this waiver signature?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        const { dispatch, signature } = this.props;
        dispatch(waiverSignatureActions.delete(signature.id)).catch(console.error);
        
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(WaiverDeleteModal));