import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  createStyles,
  Grid,
  List,
  Paper,
  Theme,
  WithTheme,
  withTheme,
  WithStyles,
  withStyles,
  Typography,
  Fab,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import '../../../styles/App.css';
import MainLayout from '../../../components/MainLayout';
import LoadIndicator from '../../../components/LoadIndicator';
import RoleGate from '../../../components/RoleGate';
import AvailabilityBlockAddModal from '../../../components/availability/blocks/AddEditModal';
import AvailabilityHoursCard from '../../../components/availability/hours/Card';
import AvailabilityBlocksCard from '../../../components/availability/blocks/Card';
import AvailabilityDurationCard from '../../../components/availability/duration/Card';
import { actions as lsvActions, IReturn as LivescanVendor } from '../../../api/resources/LivescanVendor';
import { actions as availableTimeActions, IReturn as AvailableTime } from '../../../api/resources/AvailableTime';
import { actions as blockedTimeActions, IReturn as BlockedTime } from '../../../api/resources/BlockedTime';
import { ApplicationState } from '../../../reducers';
import { getSessionContextID } from '../../../selectors';

const styles = (theme: Theme) =>
    createStyles({
        fab: {
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },   
        paperActions: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
        paperTitle: {
          padding: theme.spacing(2),
          paddingBottom: 0,
        },
        centeredPaperContent: {
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        centeredAction: {
          margin: theme.spacing(1),
        },
        paperContent: {
          padding: theme.spacing(2),
        },
        item: {
          display: 'flex',
        },
        itemPaper: {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        spacer: {
          flex: 1,
        }
    });

interface IState {
  isLoading: boolean;
  showAddModal: boolean;
};

interface IProps {
  lsv?: LivescanVendor;
  availableTimes: AvailableTime[];
  blockedTimes: BlockedTime[];
  lsvId: number;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
  const lsvId = getSessionContextID(state);

	return {
    // lsv: lsvId ? state.livescanvendor.data[lsvId] : undefined,
    availableTimes: Object.values(state.availabletime.data).filter((time) => time.lsv == lsvId),
    blockedTimes: Object.values(state.blockedtime.data).filter((time) => time.lsv == lsvId),
    lsv: lsvId ? state.livescanvendor.data[lsvId] : undefined,
    lsvId,
	};
};

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class SchedulerAvailability extends React.PureComponent<FullProps, IState> {
  state = {
    isLoading: false,
    showAddModal: false,
  }
  
  public async componentDidMount() {
    const { lsvId, dispatch } = this.props;
    // dispatch(lsvActions.cacheread({}, lsvId)).catch(console.error);
    dispatch(availableTimeActions.index(undefined, undefined, {lsv: lsvId})).catch(console.error);
    dispatch(blockedTimeActions.index(undefined, undefined, {lsv: lsvId})).catch(console.error);
    dispatch(lsvActions.cacheread({}, lsvId)).catch(console.error);
  }

  render() {
    const { isLoading, showAddModal } = this.state;
    const { availableTimes, blockedTimes, classes, lsv } = this.props;
    
    return (
      <>
        <MainLayout title='Manage Availability' behavior='scheduler'>
            <RoleGate role='admin' />
            {isLoading ? (
              <LoadIndicator />
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Standard Availability</Typography>
                    <AvailabilityHoursCard times={availableTimes} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6">Upcoming Blocked Times</Typography>
                    <AvailabilityBlocksCard blocks={blockedTimes} />
                  </Grid>

                  {lsv && (
                    <Grid item xs={12}>
                      <Typography variant="h6">Appointment Duration</Typography>
                      <AvailabilityDurationCard lsv={lsv} />
                    </Grid>
                  )}
                </Grid>

                <Fab color="primary" aria-label="add" className={classes.fab} onClick={this.handleAddClick}>
                  <AddIcon />
                </Fab>
                <AvailabilityBlockAddModal open={showAddModal} onDismiss={this.handleDismiss} />
              </>
            )}
        </MainLayout>
      </>
    );
  }

  private handleAddClick = () => {
    this.setState({showAddModal: true});
  }

  private handleDismiss = (shouldRefresh: boolean) => {
    this.setState({showAddModal: false});
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(SchedulerAvailability)));