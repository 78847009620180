import React from 'react';
import Routes from './Routes';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment-timezone';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Routes />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
