import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	brand_primary_color: string;
	brand_secondary_color: string;
	contact_first_name: string;
	contact_last_name: string;
	contact_email: string;
	contact_phone: string;
	display_disclaimer: boolean;
	display_waiver: boolean;
	extra_fields: string;
	id: number;
	logo_image: string;
	logo_url: string;
	name: string;
	prompt_payment: boolean;
	default_payment: string;
	payment_options: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "employer",
	rootUrl: getAPIUrl("/employers"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});