import { Theme, Card, CardContent, List } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import LSVItem from '../../../components/lsv/Item';
import '../../../styles/App.css';
import EmptyState from '../../../components/EmptyState';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { actions as illWizardActions } from '../../../actions/illegibleWizard';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { actions as livescanVendorActions, IReturn as LivescanVendor } from '../../../api/resources/LivescanVendor';
import { ApplicationState } from '../../../reducers';
import { getEnvironmentID, isEnvironmentEmployer } from '../../../selectors/environment';

interface IProps {
	dispatch: any,
	isIllegible: boolean,
	employerID?: number,
	isEnvironmentEmployer?: boolean,
}

const styles = (theme: Theme) => createStyles({});

const mapStateToProps = (state: ApplicationState) => {
	return {
		employerID: getEnvironmentID(state),
		isEnvironmentEmployer: isEnvironmentEmployer(state),
	};
}

interface IState {
	agreed: boolean;
	locations: LivescanVendor[];
}

type FullProps = WithStyles<typeof styles> & IProps & RouteComponentProps;
class SchedulerLocations extends React.PureComponent<FullProps, IState> {
  
  state = {
	agreed: false,
	locations: [] as LivescanVendor[]
  };

  componentDidMount() {
	this.loadDependencies();
  }

  public componentDidUpdate(prevProps: FullProps) {
	const { employerID } = this.props;

	if (prevProps.employerID != employerID)
	  this.loadDependencies();
  }

  async loadDependencies() {
	const { dispatch, employerID, isEnvironmentEmployer } = this.props;
	if (!employerID) {
		return;
	}
	const locations = await dispatch(livescanVendorActions.index(undefined, undefined, isEnvironmentEmployer ? {employer: employerID} : {network: employerID})).catch(console.error);
	this.setState({
		locations: locations.result
	});
  }

  render() {
    const { isIllegible } = this.props;
	const { agreed, locations } = this.state;

	const handleCheck = () =>
		this.setState({agreed: !agreed});
	
	const locationsMarkup = locations && locations.sort((a, b) => a.name.localeCompare(b.name)).map((result, index) => (
		<LSVItem lsv={result} onClick={this.onProceed} index={index} key={index} />
	));

    return (
      <AnonLayout title={isIllegible ? 'Illegible Prints' : 'New Application'} subtitle='Please select a fingerprint location below'>
        <Card>            
			<CardContent>
				<List>
				{locationsMarkup && locationsMarkup.length > 0 ? locationsMarkup : (
					<EmptyState icon='error' text='None to display' />
				)}
				</List>
			</CardContent>
		</Card>
      </AnonLayout>
    );
  }

  onProceed = (lsv: LivescanVendor) => {
	const { dispatch, history, isIllegible } = this.props;
	
	if (isIllegible) {
		dispatch(illWizardActions.selectLSV(lsv));
		history.push(`/schedule/${lsv.id}/illegible`);
	}
	else {
		dispatch(newWizardActions.selectLSV(lsv));
		history.push(`/schedule/${lsv.id}/new`);
	}		
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerLocations)));