import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	amount: number;
	appointment: number;
	id: number;
	payment_token: number;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "paymenttransaction",
	rootUrl: getAPIUrl("/paymenttransactions"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});