import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';

class Password extends React.PureComponent<TextFieldProps> {
	state = { showPassword: false };
    
    render() {
		const { showPassword } = this.state;
		const handleClickShowPassword = () => {
			this.setState({ showPassword: !this.state.showPassword });
		};

		return (
			<TextField
				{...this.props}
				type={showPassword ? 'text' : 'password'}
				autoComplete="password"
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={handleClickShowPassword}>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		);
	}
}

export default Password;
