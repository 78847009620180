import React from 'react';
import {
    Avatar,
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { IReturn as Applicant } from '../../api/resources/Applicant';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        itemSection: {
            flexBasis: '100%',
        }
    });

// export interface IApplicant {
//     id: number;
//     appointment_type: number;
//     created: Date;
//     dob: Date;
//     first_name: string;
//     email: string;
//     employer: number;
//     last_name: string;
//     phone: string;
//     phone_ext: string;
//     ssn4: string;
//     status_code: number;
//     transaction_id: string;
//     type: string;
//     ori: string;
//     category: string | null;
//     vendor: number | null;
// }

interface IProps {
    index: number;
    applicant: Applicant;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class ApplicantItem extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
    }
    
    render() {
        const { index, applicant, classes } = this.props;
        const { anchorEl, expanded } = this.state;

        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem button={true} component={RouterLink} to={
                    `/record/${applicant.id}`
                } >
                    <ListItemAvatar>
                        <Avatar>{applicant.first_name[0]+applicant.last_name[0]}</Avatar>
                    </ListItemAvatar>
                    <ListItemText className={classes.itemSection} primary={(
                        <>{applicant.last_name}, {applicant.first_name}</>
                    )} secondary={(
                        <>{applicant.position}</>
                    )}/>
                    <ListItemText className={classes.itemSection} secondary={(
                        <>
                            {applicant.transaction_id}
                            <br />{applicant.ssn4}
                        </>
                    )} />
                    <NavigateNext />
                </ListItem>                
            </React.Fragment>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }
}

export default withTheme(withStyles(styles)(ApplicantItem));