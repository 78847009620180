import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as User, actions as userActions } from '../../api/resources/User';

const styles = (theme: Theme) =>
    createStyles({
        
    });

type PartialUser = Partial<User>;

interface IState {
    user?: PartialUser;
}

interface IProps {
    user?: User;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class UserAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        user: {} as PartialUser,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            const { user } = this.props;
        
            this.setState({
                user: user || {} as PartialUser
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open } = this.props;
        const { user } = this.state;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{user?.id ? (<>Edit User</>) : (<>Add User</>)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {user?.id ? (
                            <>
                                Edit user details below.
                            </>
                        ) : (
                            <>
                                Enter new user details below.
                            </>
                        )}                        
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="User Email"
                        type="email"
                        fullWidth
                        value={user.email}
                        onChange={(evt) => {
                            const user = {
                                ...this.state.user,
                                username: evt.target.value
                            };
                            this.setState({user});
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { dispatch } = this.props;
        const { user } = this.state;
        if (user?.id) {
            await dispatch(userActions.update(user.id, user)).catch(console.error);
        } 
        else {
            await dispatch(userActions.create(undefined, user)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(UserAddEditModal));