import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Theme,
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as ApplicantType, actions as applicantTypeActions } from '../../../api/resources/ApplicantType';

const styles = (theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
        },
    });

interface IProps {
    applicantType: number | null;
    onChange: (evt: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => void;
};

interface IState {
    applicantTypes: ApplicantType[];
}

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class ApplicantPositionCard extends React.PureComponent<FullProps, IState> {
    public state = {
        applicantTypes: [] as ApplicantType[]
    }
    
    public componentDidMount() {
        this.loadDependencies();
    }

    public async loadDependencies() {
        const { dispatch } = this.props;
        const result = await dispatch(applicantTypeActions.index(undefined, undefined, undefined)).catch(console.error);
        if (!result)
            return;
        
        const oris = [] as number[];
        const uniqueAppTypes = Object.values(result.result).filter((value: ApplicantType) => {
            const exists = oris.includes(value.ori);
            oris.push(value.ori);
            return !exists;
        });
        this.setState({applicantTypes: uniqueAppTypes});
    }

    render() {
        const { applicantType, classes, onChange } = this.props;
        const { applicantTypes } = this.state;

        const typeMap = applicantTypes.sort((a, b) => a.description.localeCompare(b.description)).map((appType) => (
            <MenuItem value={appType.id} key={appType.id}>{appType.description}</MenuItem>
        ));

        return (
            <FormControl className={classes.formControl}>
                <InputLabel id="app-type-select-label" shrink={true}>App Type</InputLabel>
                <Select
                displayEmpty={true}
                labelId="app-type-select-label"
                id="app-type-select"
                value={applicantType}
                onChange={onChange}
                >
                    {typeMap}
                </Select>
            </FormControl>
        )
    }
}

export default connect()(withStyles(styles)(ApplicantPositionCard));