import React from 'react';
import {
    Avatar,
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { IReturn as LSV } from '../../api/resources/LivescanVendor';
import Moment from 'react-moment';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    });

interface IProps {
    index: number;
    time: string|Date;
    onClick: (time: string|Date) => void;
    zone?: string;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class TimeItem extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
    }
    
    render() {
        const { index, classes, onClick, time, zone } = this.props;
        const { anchorEl, expanded } = this.state;

        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem button={true} onClick={() => onClick(time)} >
                    <ListItemText primary={(
                        <Moment format="LT" tz={zone}>{time}</Moment>
                    )}/>
                    <NavigateNext />
                </ListItem>                
            </React.Fragment>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }
}

export default withTheme(withStyles(styles)(TimeItem));