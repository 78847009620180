import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormControl,
    List,
    ListItem,
    ListItemText,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as ORI, actions as oriActions } from '../../../api/resources/ORI';
import { IReturn as ApplicantType, actions as applicantTypeActions } from '../../../api/resources/ApplicantType';
import { IReturn as LivescanVendor, actions as livescanVendorActions } from '../../../api/resources/LivescanVendor';
import ApplicantTypeItem from './Item';
import EmptyState from '../../EmptyState';
import { Add } from '@material-ui/icons';
import CSVReader, { IFileInfo } from 'react-csv-reader';

const styles = (theme: Theme) =>
    createStyles({
        
    });

type PartialORI = Partial<ORI>;
type PartialApplicantType = Partial<ApplicantType>;

type ViewBucket = 'DEFAULT' | 'ADD' | 'IMPORT';

interface IState {
    activeView: ViewBucket;
    applicantTypes: ApplicantType[];
    // oris: ORI[];
    // showAddApplicantType: boolean;
    newORI: PartialORI;
    newApplicantType: PartialApplicantType;
}

interface IProps {
    lsv?: LivescanVendor;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class ApplicantTypeAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        activeView: 'DEFAULT' as ViewBucket,
        applicantTypes: [] as ApplicantType[],
        // oris: [] as ORI[],
        // showAddApplicantType: false,
        newORI: {} as PartialORI,
        newApplicantType: {} as PartialApplicantType,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            this.loadDependencies();
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    private loadDependencies = async () => {
        const { dispatch, lsv } = this.props;

        if (!lsv) {
            return;
        }

        // const results = await dispatch(livescanVendorActions.users(lsv.id, undefined, undefined)).catch(console.error);
        dispatch(oriActions.index(undefined, undefined, {lsv: lsv.id})).catch(console.error);
        const results = await dispatch(applicantTypeActions.index(undefined, undefined, {lsv: lsv.id})).catch(console.error);

        if (!results) {
            return;
        }

        const applicantTypes = (results.result as unknown) as ApplicantType[];

        this.setState({applicantTypes});
    }

    render() {
        const { lsv, open } = this.props;
        const { activeView, newApplicantType, newORI, applicantTypes } = this.state;

        const showAddApplicantType = (activeView === 'ADD');
        const showDefaultView = (activeView === 'DEFAULT');
        const showImportView = (activeView === 'IMPORT');

        // const canAddUser = newUser.email && newUser.password;
        // TODO: proper logic
        const canAddApplicantType = true;

        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Manage ORIs for {lsv?.name}</DialogTitle>
                <DialogContent>
                    {showAddApplicantType && (
                        <>
                            <DialogContentText>
                                Add a new ORI by providing details below.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="ori"
                                label="ORI"
                                fullWidth
                                value={newORI.ori}
                                onChange={(evt) => {
                                    const newORI = {
                                        ...this.state.newORI,
                                        ori: evt.target.value
                                    };
                                    this.setState({newORI});
                                }}
                                required
                            />
                            <TextField
                                margin="dense"
                                id="description"
                                label="Description"
                                fullWidth
                                value={newORI.description}
                                onChange={(evt) => {
                                    const newORI = {
                                        ...this.state.newORI,
                                        description: evt.target.value
                                    };
                                    this.setState({newORI});
                                }}
                                required
                            />
                            <TextField
                                margin="dense"
                                id="slug"
                                label="slug"
                                fullWidth
                                value={newORI.slug}
                                onChange={(evt) => {
                                    const newORI = {
                                        ...this.state.newORI,
                                        slug: evt.target.value
                                    };
                                    this.setState({newORI});
                                }}
                                required
                            />
                            <TextField
                                margin="dense"
                                id="agency"
                                label="Agency"
                                fullWidth
                                value={newORI.agency}
                                onChange={(evt) => {
                                    const newORI = {
                                        ...this.state.newORI,
                                        agency: evt.target.value
                                    };
                                    this.setState({newORI});
                                }}
                            />
                            <TextField
                                margin="dense"
                                id="price"
                                label="Pricing"
                                fullWidth
                                type="number"
                                value={newApplicantType.pricing}
                                onChange={(evt) => {
                                    const newApplicantType = {
                                        ...this.state.newApplicantType,
                                        pricing: (evt.target.value as unknown) as number|undefined
                                    };
                                    this.setState({newApplicantType});
                                }}
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={newORI.prompt_vendor} 
                                        onChange={(evt) => {
                                            const newORI = {
                                                ...this.state.newORI,
                                                prompt_vendor: !this.state.newORI.prompt_vendor
                                            };
                                            this.setState({newORI});
                                        }}
                                    />
                                } 
                                label="Vendor type" 
                            />
                        </>
                    )}

                    {showImportView && (
                        <>
                            <DialogContentText>
                                Bulk import a set of applicant types from a CSV by selecting a file below.
                            </DialogContentText>
                            <CSVReader onFileLoaded={this.handleImportLoaded} parserOptions={{header: true}} />
                        </>
                    )}

                    {showDefaultView && (
                        <>
                            <DialogContentText>
                                {applicantTypes && applicantTypes.length ? (
                                    <>The following ORIs are active at this LSV:</>
                                ) : (
                                    <>Begin adding ORI to this LSV below.</>
                                )}
                            </DialogContentText>
                            <List>
                                {applicantTypes && applicantTypes.length > 0 && applicantTypes.map((applicantType, index) => (
                                    <ApplicantTypeItem applicantType={applicantType} index={index} onDelete={this.handleRemoveClick} />
                                ))}
                                
                                <ListItem button onClick={this.handleAddClick}>
                                    <Add />
                                    <ListItemText primary={'Add new ORI'} />
                                </ListItem>
                            </List>
                        </>
                    )}
                    
                </DialogContent>
                <DialogActions>
                    {showAddApplicantType && (
                        <>
                            <Button onClick={this.handleAddDismiss} color="primary">
                                Cancel
                            </Button>
                            <Button disabled={!canAddApplicantType} onClick={this.handleAddSave} color="primary">
                                Save
                            </Button>
                        </>
                    )}

                    {showImportView && (
                        <>
                            <Button onClick={this.handleImportDismiss} color="primary">
                                Cancel
                            </Button>
                        </>
                    )}

                    {showDefaultView && (
                        <>
                            <Button onClick={this.handleImportClick}>
                                Import
                            </Button>
                            <Button onClick={this.handleCancel} color="primary">
                                Close
                            </Button>
                        </>
                    )}
                    
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleRemoveClick = async (applicantType: PartialApplicantType) => {
        const { dispatch } = this.props;
        
        // await dispatch(oriActions.delete(applicantType.ori, undefined, undefined)).catch(console.error);
        await dispatch(applicantTypeActions.delete(applicantType.id, undefined, undefined)).catch(console.error);

        this.loadDependencies();
    }
    
    private handleAddClick = () => {
        this.setState({
            activeView: 'ADD',
            newApplicantType: {} as PartialApplicantType,
            newORI: {} as PartialORI,
        });
    }

    private handleAddDismiss = () => {
        this.setState({activeView: 'DEFAULT'});
    }

    private handleImportClick = () => {
        this.setState({activeView: 'IMPORT'});
    }

    private handleImportDismiss = () => {
        this.setState({activeView: 'DEFAULT'});
    }

    private handleImportLoaded = async (data: Array<any>, fileInfo: IFileInfo) => {
        const { dispatch, lsv } = this.props;
        const requiredFields = ['description', 'slug', 'agency', 'pricing'];
        const fields = Object.keys(data[0]);

        if (!lsv) {
            return;
        }

        requiredFields.forEach((key) => {
            if (fields.indexOf(key) < 0) {
                alert("Selected file must have description, slug, agency and pricing at minimum");
                return;
            }
        });

        await dispatch(oriActions.bulk_import(undefined, {data, lsv: lsv.id}, undefined)).catch(console.error);
        
        this.loadDependencies();

        this.setState({activeView: 'DEFAULT'});
    }
    
    private handleAddSave = async () => {
        const { dispatch, lsv } = this.props;
        const { newApplicantType, newORI } = this.state;

        if (!lsv) {
            return;
        }

        const result = await dispatch(oriActions.create(undefined, {...newORI, lsv: lsv.id}, undefined)).catch(console.error);
        
        if (!result) {
            return;
        }       

        const ori = result.result;
        await dispatch(applicantTypeActions.create(undefined, {...newApplicantType, ori: ori.id, lsv: lsv.id})).catch(console.error);

        this.loadDependencies();

        this.setState({activeView: 'DEFAULT'});
    }
}

export default connect()(withStyles(styles)(ApplicantTypeAddEditModal));