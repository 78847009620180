import { Call, Resource, CombineResource, IStateBucket } from "restdux";
import { getAPIUrl, getCallAPIUrlFunction, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	email: string;
	id: number;
	is_self: boolean;
	first_name: string;
	last_name: string;
	is_superuser: boolean;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "user",
	rootUrl: getAPIUrl("/users"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {
	set_password: Call<{}, {password: string, old_password?: string}, IReturn, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "PATCH",
		reducer: (state: IStateBucket<IReturn> = {data: {}, meta: {}}, action) => {
			if (!action.id || !action.sent) {
				return state;
			}
			switch (action.type) {
				case types.set_password.success:
					return resource.updateEntity(state, action.id, action.result);
			}
			return state;
		},
		url: getCallAPIUrlFunction("/users", "set_password"),
	}),
});