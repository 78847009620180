import { Button, Grid, Theme, Paper, Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';
import { actions as applicantActions, IReturn as Applicant } from '../../../api/resources/Applicant';
import { actions as illWizardActions } from '../../../actions/illegibleWizard';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../reducers';
import { getLookupData } from '../../../selectors/wizard';

interface IProps {
	dispatch: any,
	lookupData?: {
		regNumber?: string;
		tcr?: string;
		firstName?: string;
		lastName?: string;
		email?: string;
	}
}

interface IState {
	applicant?: Applicant;
	isLoading: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		lookupData: getLookupData(state),
	}
}

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
			'&>div': {
				marginTop: theme.spacing(2),
			},
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		}
	});

type FullProps = WithStyles<typeof styles> & IProps & RouteComponentProps;
class SchedulerLookupResult extends React.PureComponent<FullProps, IState> {
  
  	state = {
		applicant: undefined as Applicant|undefined,
		isLoading: true
	};	

  public componentDidMount() {
	this.loadDependencies();
  }

  private async loadDependencies() {
	const { dispatch, lookupData } = this.props;
	
	if (!lookupData) {
		return;
	}
	
	const { regNumber: reg_number, lastName: last_name, email } = lookupData;

	if (!last_name || !email) {
		return;
	}
	
	const applicantResult = await dispatch(applicantActions.lookup(undefined, undefined, {
		reg_number,
		last_name,
		email
	})).catch(console.error);

	this.setState({
		applicant: applicantResult ? applicantResult.result : undefined,
		isLoading: false
	});
  }
	
  render() {
	const { classes } = this.props;
	const { applicant, isLoading } = this.state;
	
	return (
      <AnonLayout title='Illegible Prints' subtitle='Please review and confirm your info below'>
        <Paper className={classes.paper}>
			{isLoading ? (
				<Typography variant="subtitle1">Searching...</Typography>
			) : (
				applicant ? (
					<>
						<Typography variant="body2">
							Is this the correct record?
						</Typography>

						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="body1">Name</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">{applicant.last_name}, {applicant.first_name}</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="body1">Phone</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">{applicant.phone}</Typography>
							</Grid>
						</Grid>

						<Grid container={true} className={classes.centeredButton} justify="center" spacing={2}>
							<Grid item>
								<Button component={RouterLink} to="/lookup" variant="contained">
									Back
								</Button>
							</Grid>
							<Grid item>
								<Button onClick={this.handleProceed} variant="contained" color="primary">
									Continue
								</Button>
							</Grid>
						</Grid>
					</>
				) : (
					<>
						<Typography variant="body2">
							We were unable to locate a matching record. Please try again.
						</Typography>
						<Grid container={true} className={classes.centeredButton} justify="center" spacing={2}>
							<Grid item>
								<Button component={RouterLink} to="/lookup" variant="contained">
									Back
								</Button>
							</Grid>
						</Grid>
					</>
				)
			)}			
		</Paper>
      </AnonLayout>
    );
  }

  private handleProceed = () => {
	const { dispatch, history } = this.props;
	const { applicant } = this.state;
	
	if (!applicant) {
		return;
	}
	
	dispatch(illWizardActions.setRecord(applicant));
	
	history.push("/locations/illegible");
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerLookupResult)));