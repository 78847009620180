export const actionTypes = {
    ENVIRONMENT_READ_INITIATE: 'environment.read.initiate',
    ENVIRONMENT_READ_SUCCESS: 'environment.read.success',
};

export const actions = {
    environmentRead: (id: number) => ({
        type: actionTypes.ENVIRONMENT_READ_INITIATE,
        id
    }),

    environmentReadSuccess: (record: any, recordType: 'EMPLOYER'|'NETWORK') => ({
        type: actionTypes.ENVIRONMENT_READ_SUCCESS,
        record,
        recordType
    })
};
