import React from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        
    });

interface IProps {
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles>;
class AccountUsernameModal extends React.PureComponent<FullProps> {
    render() {
        const { open } = this.props;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Modify Login Email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To change your desired login email, enter the new email address below.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(AccountUsernameModal));