import { IReturn as LivescanVendor } from "../api/resources/LivescanVendor";
import { IReturn as Applicant } from "../api/resources/Applicant";

export const actionTypes = {
    CLEAR: 'wizard.illegible.clear',
    START: 'wizard.illegible.start',
    ACCEPT_DISCLAIMER: 'wizard.illegible.accept.disclaimer',
    SET_DATA: 'wizard.illegible.set.data',
    SET_RECORD: 'wizard.illegible.set.record',
    SELECT_LSV: 'wizard.illegible.select.lsv',
    SELECT_DATETIME: 'wizard.illegible.select.datetime',
    CONFIRMED: 'wizard.illegible.confirmed',
};

export const actions = {
    clear: () => ({
        type: actionTypes.CLEAR
    }),

    start: () => ({
        type: actionTypes.START
    }),
    
    acceptDisclaimer: () => ({
        type: actionTypes.ACCEPT_DISCLAIMER
    }),

    setData: (
        email: string,
        lastName: string,
        regNumber: string,
    ) => ({
        type: actionTypes.SET_DATA,
        regNumber,
        lastName, 
        email
    }),

    setRecord: (applicant: Applicant) => ({
        type: actionTypes.SET_RECORD,
        applicant
    }),
    
    selectLSV: (lsv: LivescanVendor) => ({
        type: actionTypes.SELECT_LSV,
        lsv
    }),
    
    selectDateTime: (date: Date) => ({
        type: actionTypes.SELECT_DATETIME,
        date
    }),

    confirm: () => ({
        type: actionTypes.CONFIRMED
    }),
};
