import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  Theme,
  withTheme, 
  WithTheme 
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import '../../../styles/App.css';
import MainLayout from '../../../components/MainLayout';
import RoleGate from '../../../components/RoleGate';
import AppointmentRow from '../../../components/appointment/Row';
import { actions as appointmentActions, IReturn as Appointment } from '../../../api/resources/Appointment';
import { ApplicationState } from '../../../reducers';
import moment from 'moment';
import EmptyState from '../../../components/EmptyState';

const styles = (theme: Theme) => createStyles({
  reportTable: {
    border: "solid 1px",
    width: "100%",
    "& tr": {
      border: "solid 1px",
      "& td, & th": {
        border: "solid 1px",
        padding: "8px 4px",
      }
    }
  }
});

interface IProps {
  appointments: Appointment[];
}

interface IState {}

interface ISearch {
  after: string;
  before: string;
  name?: string;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp & RouteComponentProps;

const mapStateToProps = (state: ApplicationState, ownProps: FullProps) => {
  return {
    appointments: Object.values(state.appointment.data).sort((a: Appointment, b: Appointment) => moment(a.date_time).toDate().getTime() - moment(b.date_time).toDate().getTime())
  };
}

class SchedulerReport extends React.PureComponent<FullProps> {
  public async componentDidMount() {
    const { dispatch } = this.props;
    const { after, before, name } = this.getRequest();
    
    let params = { after, before } as ISearch;

    if (name !== null && name !== '') {
      params = {
        ...params,
        name
      };
    }

    dispatch(appointmentActions.index(undefined, undefined, params));
  }

  private getRequest = () => {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const after = query.get("after") || "",
          before = query.get("before") || "",
          name = query.get("name") || "";
    return { after, before, name };
  }
  
  render() {
    const { appointments, classes } = this.props;
    const { after, before, name } = this.getRequest();
    
    const afterDate = moment(after).toDate(),
          beforeDate = moment(before).toDate();
      
    const afterString = moment(after).format('l'),
          beforeString = moment(before).format('l');
      
    const filteredAppointments = appointments.filter((appt) => {
      const apptDate = moment(appt.date_time).toDate();
      const dateMatch = apptDate >= afterDate && apptDate <= beforeDate;
      
      if (name === '') {
        return dateMatch;
      }
      
      const nameMatch = appt.first_name.indexOf(name) >= 0 || appt.last_name.indexOf(name) >= 0;
      return dateMatch && nameMatch;
    });
    
    const appointmentMarkup = filteredAppointments.map((appointment, index) => (
      <AppointmentRow index={index} appointment={appointment} key={index} />
    ));
    
    return (
      <MainLayout title={`Report ${afterString} - ${beforeString}`} behavior='scheduler'>
        <RoleGate role='admin' />
        
        { appointmentMarkup.length > 0 ? (
            <table className={classes.reportTable}>
              <tr>
                <th>Date</th><th>Time</th><th>Last Name</th><th>First Name</th><th>Appt #</th><th>Type</th><th>Phone</th><th>Email</th><th>Status</th><th>Location</th>
              </tr>
              { appointmentMarkup }
            </table>
          ) : (
            <EmptyState icon='error' text='No appointments to display' />
        ) }
      </MainLayout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(withTheme(withStyles(styles)(SchedulerReport))));