import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles
} from '@material-ui/core';
import { IReturn as Applicant } from '../../api/resources/Applicant';
import { IReturn as Note, actions as noteActions } from '../../api/resources/Note';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';


const styles = (theme: Theme) =>
    createStyles({});

interface IProps {
    applicant?: Applicant;
    note?: Note;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

const FormSchema = Yup.object().shape({
    note_text: Yup.string().required(),
});

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class NoteAddEditModal extends React.Component<FullProps> {
    render() {
        const { note, open } = this.props;
    
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogTitle id="form-dialog-title">{note?.id ? (<>Edit note</>) : (<>Add note</>)}</DialogTitle>
                <Formik
                    initialValues={{
                        note_text: note ? note.note_text : '',
                    }}
                    validationSchema={FormSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        this.handleSave(values);
                    }}
                >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            {/* <DialogContentText>
                                {note?.id ? (
                                    <>Edit note below.</>
                                ) : (
                                    <>Add new note below.</>
                                )}                        
                            </DialogContentText> */}
                            <Field component={TextField} label="Note" name="note_text" multiline fullWidth required autofocus />
                            
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancel} color="primary">Cancel</Button>
                            <Button onClick={submitForm} color="primary">Save</Button>
                        </DialogActions>
                    </Form>
                )}
                </Formik>
                
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = (values: Partial<Note>) => {
        this.saveAndDismiss(values);
    }

    private async saveAndDismiss(values: Partial<Note>) {
        const { dispatch } = this.props;
        const { note } = this.props;
        if (note?.id) {
            const hydratedModel = {
                ...note,
                ...values
            };
            await dispatch(noteActions.update(note.id, hydratedModel)).catch(console.error);
        } 
        else {
            const { applicant } = this.props;
            const hydratedModel = {
                applicant: applicant?.id,
                ...values
            };
            await dispatch(noteActions.create(undefined, hydratedModel)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(NoteAddEditModal));