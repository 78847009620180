import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    // TextField,
    Theme, 
    withStyles, 
    WithStyles,
    Grid,
    List,
    Select, 
    Typography, 
    MenuItem, 
    FormControl,
    InputLabel
} from '@material-ui/core';
import { IReturn as Appointment, actions as appointmentActions } from '../../api/resources/Appointment';
import { IReturn as Applicant, actions as applicantActions } from '../../api/resources/Applicant';
import { IReturn as ApplicantType, actions as applicantTypeActions } from '../../api/resources/ApplicantType';
import { IReturn as Location, actions as locationActions } from '../../api/resources/Location';
import { actions as livescanVendorActions, IReturn as LivescanVendor } from '../../api/resources/LivescanVendor';
import { DatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import EmptyState from '../EmptyState';
import TimeItem from '../../components/time/Item';
import moment from 'moment';
import Moment from 'react-moment';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { ApplicationState } from '../../reducers';
import { getLSVContextID, getNetworkContextID } from '../../selectors';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';


const styles = (theme: Theme) =>
    createStyles({
        timesList: {
            // maxHeight: '80vh',
            maxHeight: '300px',
            overflowY: 'scroll',
        },
        formControl: {
            marginTop: 16,
            width: '100%',
        }
    });

type PartialAppointment = Partial<Appointment>;
type PartialApplicant = Partial<Applicant>;

interface IState {
    appointment?: PartialAppointment;
    dateSelection: Date;
    step: number;
    times: Date[];
    lsvId?: number;
    isWalkin: boolean;
}

interface IProps {
    applicantTypes?: ApplicantType[];
	locations?: Location[];
	appointment?: Appointment;
    applicant?: Applicant;
    lsvId?: number;
    lsvs?: LivescanVendor[];
    networkId?: number;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
    step?: number;
};

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
	const { appointment } = ownProps;
    
    return {
        applicantTypes: Object.values(state.applicanttype.data),
        locations: Object.values(state.location.data),
        lsvId: getLSVContextID(state),
        lsvs: Object.values(state.livescanvendor.data),
        networkId: getNetworkContextID(state),
        applicant: appointment ? Object.values(state.applicant.data).find((item)=>item.id===appointment.applicant) : undefined,
    };
};

const FormSchema = Yup.object().shape({
    applicant_type: Yup.number().required(),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().email().required(),
    // location: Yup.number().required(),
    location: Yup.mixed().notRequired(),
    phone: Yup.string().required(),
    notes: Yup.string(),
});

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class AppointmentAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        appointment: {} as PartialAppointment,
        dateSelection: new Date(),
        lsvId: undefined as number|undefined,
        step: 1,
        times: [] as Date[],
        isWalkin: false,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            const { dispatch } = this.props;
            const { appointment, networkId, lsvId, step } = nextProps;
        
            this.setState({
                appointment: appointment || {} as PartialAppointment,
                dateSelection: appointment ? new Date(appointment.date_time) : new Date(),
                lsvId: appointment ? appointment.lsv : undefined,
                step: step ? step : 1,
                times: [] as Date[],
                isWalkin: false,
            });
            this.handleDateSelection(appointment ? new Date(appointment.date_time) : new Date());

            dispatch(applicantTypeActions.index(undefined, undefined, undefined)).catch(console.error);
		    dispatch(locationActions.index(undefined, undefined, undefined)).catch(console.error);

            if (appointment && step == 2) {
                dispatch(applicantActions.find(appointment.applicant, {}, undefined)).catch(console.error);
            }

            if (networkId) {
                dispatch(livescanVendorActions.index(undefined, undefined, {})).catch(console.error).then((result) => {
                    if (result && !appointment) {
                        const lsvs = (result.result as unknown) as LivescanVendor[];
                        if (lsvs.length > 0) {
                            this.setState({lsvId: lsvs[0].id});
                        }                        
                    }
                })
            }
            else {
                if (!lsvId) {
                    return false;
                }
                dispatch(livescanVendorActions.index(lsvId, undefined, {})).catch(console.error);
            }

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    componentDidUpdate(prevProps: IProps, prevState: IState): void {
        const { lsvId, dateSelection } = this.state;
        if (lsvId !== prevState.lsvId) {
            this.handleDateSelection(dateSelection);
        }
    }

    render() {
        const { applicant, applicantTypes, open, classes, locations, lsvId: lsvIdProp, lsvs, networkId } = this.props;
        const { appointment, dateSelection, lsvId, step, times, isWalkin } = this.state;

        const lsv = lsvs ? lsvs.find(lsv => (lsv.id === lsvId || lsv.id === lsvIdProp)) : undefined;
        
        const timesMarkup = times.map((time, index) => (
            <TimeItem time={time} index={index} key={index} zone={lsv?.timezone} onClick={this.handleTimeSelection} />
        ));

        const typesMarkup = applicantTypes ? applicantTypes.sort((a, b) => a.description.localeCompare(b.description)).map((applicantType, index) => (
            <MenuItem value={applicantType.id} key={index}>{applicantType.description}</MenuItem>
        )) : [];
        
        const locationsMarkup = locations ? locations.sort((a, b) => a.name.localeCompare(b.name)).map((location, index) => (
            <MenuItem value={location.id} key={index}>{location.name}</MenuItem>
        )) : [];

        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth={true}>
                <DialogTitle id="form-dialog-title">{appointment?.id ? (<>Update Appointment</>) : (isWalkin ? <>Add Walk-in</> : <>Schedule Appointment</>)}</DialogTitle>
                {step === 1 && (
                    <>
                        <DialogContent>
                            <Grid container>
                                <Grid item md={6} sm={12}>
                                    <DatePicker
                                        autoOk
                                        orientation="landscape"
                                        variant="static"
                                        openTo="date"
                                        value={dateSelection}
                                        onChange={(value) => this.handleDateSelection(value)}
                                        minDate={Date()}
                                        minDateMessage='Must select date today or later'
                                    />
                                    {networkId && lsvs && lsvs.length > 0 && lsvId && (
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel id="lsvSelectorLabel">LSV Location</InputLabel>
                                            <Select
                                                labelId="lsvSelectorLabel"
                                                id="lsvSelector"
                                                value={lsvId}
                                                onChange={(evt) => {
                                                    this.setState({lsvId: evt.target.value as number});
                                                }}
                                            >
                                                {lsvs.map((lsv: LivescanVendor) => (
                                                    <MenuItem value={lsv.id} key={lsv.id}>{lsv.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <List className={classes.timesList}>
                                        {timesMarkup && timesMarkup.length > 0 ? timesMarkup : (
                                            <EmptyState icon='error' text='No times to display' />
                                        )}
                                    </List>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancel} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleWalkin} color="primary">
                                Walk-in
                            </Button>
                        </DialogActions>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Formik
                            initialValues={{
                                applicant_type: applicant ? applicant.applicant_type : undefined as number|undefined,
                                first_name: applicant ? applicant.first_name : '',
                                last_name: applicant ? applicant.last_name : '',
                                location: undefined as number|undefined,
                                email: applicant ? applicant.email : '',
                                phone: applicant ? applicant.phone : '',
                            }}
                            validationSchema={FormSchema}
                            onSubmit={(values, {setSubmitting}) => {
                                this.handleSave(values);
                            }}
                        >
                        {({ submitForm, isSubmitting, values }) => (
                            <Form>
                                <DialogContent>
                                    <Typography variant="subtitle1">
                                        <Moment format="LL" tz={lsv?.timezone}>{appointment.date_time}</Moment><br />
                                        {isWalkin ? (
                                            <KeyboardTimePicker
                                                margin="normal"
                                                label="Walk-in time"
                                                value={appointment.date_time}
                                                onChange={this.handleTimeChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                            />
                                        ) : (
                                            <Moment format="LT" tz={lsv?.timezone}>{appointment.date_time}</Moment>
                                        )}
                                        
                                    </Typography>
                                    
                                    <Grid container spacing={2}>
                                        <Grid item sm={6} xs={12}>
                                            <Field component={TextField} label="First name" name="first_name" variant="filled" fullWidth required autoFocus />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <Field component={TextField} label="Last name" name="last_name" variant="filled" fullWidth required />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <Field component={TextField} label="Phone number" name="phone" variant="filled" fullWidth required />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <Field component={TextField} label="Email" name="email" variant="filled" fullWidth required />
                                        </Grid>

                                        {(!appointment.id) && (
                                            <>
                                                <Grid item sm={6} xs={12}>
                                                    <Field component={TextField} name="applicant_type" label="Applicant Type" variant="filled" fullWidth select required>
                                                        {typesMarkup}
                                                    </Field>
                                                </Grid>

                                                <Grid item sm={6} xs={12}>
                                                    {values.applicant_type && applicantTypes && applicantTypes.find((at) => at.id === values.applicant_type)?.prompt_location && (
                                                        <Field component={TextField} name="location" label="Location" variant="filled" fullWidth select required>
                                                            {locationsMarkup}
                                                        </Field>
                                                    )}                                            
                                                </Grid>
                                            </>
                                        )}                                        

                                        <Grid item xs={12}>
                                            <Field component={TextField} label="Notes" name="notes" variant="filled" fullWidth required />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCancel} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={submitForm} color="primary">
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                        </Formik>
                    </>
                )}
            </Dialog>
        )
    }

    private handleDateSelection = (selection: Date|null) => {
        const { dispatch, lsvId: propLsvId } = this.props;
        const { lsvId: stateLsvId } = this.state;

        const lsvId = propLsvId || stateLsvId;

        if (!selection || !lsvId) {
            return;
        }

        this.setState({dateSelection: selection});
        dispatch(appointmentActions.availability(undefined, undefined, {
            lsv: lsvId,
            date: moment(selection).format("YYYY-MM-DD"),
        })).then((result) => {
            if (!result.result) {
                return;
            }
            const times = result.result as string[];
            this.setState({
                times: times.map((time) => moment(time).toDate())
            })
        }).catch(console.error);
    }

    private handleTimeSelection = (selection: string|Date) => {
        const time = moment(selection).toDate();
        const { appointment, lsvId } = this.state;

        this.setState({
                appointment: {
                    ...appointment,
                    date_time: time.toISOString(),
                    lsv: lsvId
                }
            }, this.advanceState);
    }

    private handleTimeChange = (date: MaterialUiPickersDate, value?: string|null|undefined) => {
        const { appointment } = this.state;
        if (!date || !moment(date).isValid()) {
            return;
        }
        this.setState({
            appointment: {
                ...appointment,
                date_time: date.toISOString(),
            }
        });
    }

    private handleWalkin = () => {
        const time = moment().toDate();
        const { appointment, lsvId } = this.state;

        this.setState({
                appointment: {
                    ...appointment,
                    date_time: time.toISOString(),
                    lsv: lsvId,
                },
                isWalkin: true
            }, this.advanceState);
    }

    private advanceState = () => {
        const { appointment } = this.state;

        if (appointment.id) {
            this.saveAndDismiss();
        }
        else {
            this.setState({
                step: 2,
            });
        }
    }
    
    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = (values: PartialApplicant) => {
        this.saveAndDismiss(values);
    }

    private async saveAndDismiss(values?: PartialApplicant) {
        const { dispatch } = this.props;
        const { appointment, step } = this.state;
        
        if (appointment?.id) {
            if (step === 1) {
                await dispatch(appointmentActions.update(appointment.id, appointment)).catch(console.error);
            }
            else {
                await dispatch(applicantActions.update(appointment.applicant, values)).catch(console.error);
            }
        } 
        else {
            const updatedValues = (values as any).location ? {...values, locations: [(values as any).location as number]} : values;
            const applicantResult = await dispatch(applicantActions.create(undefined, updatedValues)).catch(console.error);

            if (!applicantResult) {
                return;
            }
            await dispatch(appointmentActions.create(undefined, {...appointment, applicant: applicantResult.result.id})).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AppointmentAddEditModal));