import React from 'react';
import {
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import LocationDeleteModal from './DeleteModal';
import { IReturn as Location } from '../../../api/resources/Location';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    });

interface IProps {
    index: number;
    entity: Location;
    onDelete: (location: Location) => void;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
    showDeleteModal: boolean;
    showEditModal: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class ApplicantLocationItem extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
        showDeleteModal: false,
        showEditModal: false,
    }
    
    render() {
        const { index, entity, classes } = this.props;
        const { anchorEl, expanded, showDeleteModal, showEditModal } = this.state;

        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem>
                    <ListItemText primary={(
                        <>{entity.name}</>
                    )}/>
                    <ListItemSecondaryAction>
                        <IconButton onClick={this.handleExpandMenu}>
                            <MoreVert />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={expanded}
                    onClose={this.handleCloseMenu}
                >
                    {/* <MenuItem onClick={this.handleEditClick}>Edit</MenuItem>
                    <Divider /> */}
                    <MenuItem onClick={this.handleDeleteClick}>Delete</MenuItem>
                </Menu>
                <LocationDeleteModal open={showDeleteModal} location={entity} onDismiss={this.handleDeleteDismiss} />
            </React.Fragment>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }

    private handleDeleteClick = () => {
        this.setState({
            expanded: false, 
            showDeleteModal: true
        });
    }

    private handleDeleteDismiss = (shouldRefresh: boolean) => {
        const { entity, onDelete } = this.props;
        if (shouldRefresh) {
            onDelete(entity);
        }
        this.setState({showDeleteModal: false});
    }
}

export default withTheme(withStyles(styles)(ApplicantLocationItem));