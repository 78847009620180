import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Theme,
    withTheme, 
    WithTheme
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as Appointment } from '../../api/resources/Appointment';
import { IReturn as Applicant, actions as applicantActions } from '../../api/resources/Applicant';
import { IReturn as ApplicantType, actions as applicantTypeActions } from '../../api/resources/ApplicantType';
import { IReturn as LivescanVendor, actions as livescanVendorActions } from '../../api/resources/LivescanVendor';
import { IReturn as Location, actions as locationActions } from '../../api/resources/Location';
import { ApplicationState } from '../../reducers';
import moment from 'moment';

const styles = (theme: Theme) => createStyles({});

interface IProps {
    index: number;
    appointment: Appointment;
    applicant?: Applicant;
    applicantType?: ApplicantType;
    lsv?: LivescanVendor;
    location?: Location;
};

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { appointment } = ownProps;
    const applicant = appointment ? Object.values(state.applicant.data).find((applicant: Applicant)=>applicant.id === appointment.applicant) : undefined;
  
    return {
        applicant,
        applicantType: applicant ? Object.values(state.applicanttype.data).find((applicantType: ApplicantType)=>applicantType.id === applicant.applicant_type) : undefined,
        location: applicant && applicant.locations.length > 0 ? Object.values(state.location.data).find((location: Location)=>location.id === applicant.locations[0]) : undefined,
        lsv: appointment ? Object.values(state.livescanvendor.data).find((lsv: LivescanVendor)=>lsv.id === appointment.lsv) : undefined,
    };
  };


type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class AppointmentRow extends React.PureComponent<FullProps> {
    public state = {}

    public componentDidMount() {
        this.loadDependencies();
        this.loadApplicantDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { appointment, applicant } = this.props;

        if (prevProps.appointment !== appointment)
            this.loadDependencies();
        
        if (prevProps.applicant !== applicant)
            this.loadApplicantDependencies();
    }

    public async loadDependencies() {
        const { appointment, dispatch } = this.props;

        if (appointment) {
            dispatch(applicantActions.cacheread({}, appointment.applicant)).catch(console.error)
            dispatch(livescanVendorActions.cacheread({}, appointment.lsv)).catch(console.error)
        }            
    }

    public async loadApplicantDependencies() {
        const { applicant, dispatch } = this.props;

        if (applicant) {
            dispatch(applicantTypeActions.cacheread({}, applicant.applicant_type)).catch(console.error)
            if (applicant.locations.length > 0) {
                dispatch(locationActions.cacheread({}, applicant.locations[0])).catch(console.error)
            }            
        }            
    }
    
    render() {
        const { index, appointment, applicant, applicantType, classes, lsv, location } = this.props;
        const isNetwork = this.isNetwork();

        const tz = lsv?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        return (
            <tr>
                <td>
                    {moment(appointment.date_time).tz(tz).format('l')}
                </td>
                <td>
                    {moment(appointment.date_time).tz(tz).format('LT')}
                </td>
                <td>
                    {applicant?.last_name}
                </td>
                <td>
                    {applicant?.first_name}
                </td>
                <td>
                    {appointment.id}
                </td>
                <td>
                    {applicantType?.description}
                </td>
                <td>
                    {applicant?.phone}
                </td>
                <td>
                    {applicant?.email}
                </td>
                <td>
                    {appointment.status}
                </td>
                <td>
                    {location?.name}
                </td>
            </tr>
        )
    }

    private isNetwork() {
        const hostName = window.location.hostname;
        return hostName.indexOf('network') >= 0;
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(AppointmentRow)));