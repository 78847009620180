import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

interface ISubstitutableField {
	id: string;
	value: string;
}

export interface IFieldData {
	capture?: string[];
	required?: string[];
	substitute?: ISubstitutableField[];
}

export interface IReturn {
	employer: number;
	field_data: IFieldData;
	id: number;
	waiver_data: string;
	waiver_template: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "waiver",
	rootUrl: getAPIUrl("/waivers"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});