import { 
    AppBar, 
    Container, 
    CssBaseline, 
    Drawer, 
    Theme, 
    Toolbar, 
    Typography, 
    IconButton,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { Menu, Search } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getSessionRole } from '../selectors';
import { ApplicationState } from '../reducers';
import Footer from './Footer';
import BimsNavigation from './bims/Navigation';
import SchedulerNavigation from './scheduler/Navigation';
import NetworkNavigation from './network/Navigation';


const drawerWidth = 260;

const styles = (theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100vh',
        },
        drawer: {

        },
        drawerPaper: {
			width: drawerWidth,
		},
        toolbar: theme.mixins.toolbar,
        title: {
            flexGrow: 1,
        }
    });

const mapStateToProps = (state: ApplicationState) => ({
    role: getSessionRole(state)
});

interface IProps {
    behavior: 'bims'|'network'|'scheduler',
    children?: React.ReactNode,
    role: string,
    showSearch?: boolean,
    title?: string,
    waiting?: boolean,
};

interface IState {
    open: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class MainLayout extends React.PureComponent<FullProps> {
    public static defaultProps: Readonly<IProps> = {
        behavior: 'bims',
        children: null,
        role: '',
        showSearch: false,
        title: 'BIMS',
        waiting: false,
    };
    public state = {
        open: false,
    };

    render() {
        const { behavior, children, classes, showSearch, title, theme } = this.props;
        const { open } = this.state;
        
        return (
            <React.Fragment>
                <CssBaseline />
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                        >
                            <Menu />
                        </IconButton>
                        <Typography variant="h6" noWrap={true} className={classes.title}>
                            {title}
                        </Typography>
                        {showSearch && (
                            <IconButton
                                color="inherit"
                                aria-label="Search"
                                component={RouterLink} 
                                to="/results"
                            >
                                <Search />
                            </IconButton>
                        )}
                        
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer}>
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={open}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{ keepMounted: true}}
                    >
                        {behavior === 'bims' ? (
                            <BimsNavigation />
                        ) : (behavior === 'network' ? (
                            <NetworkNavigation />
                        ) : (
                            <SchedulerNavigation />
                        ))}
                    </Drawer>
                </nav>
                <Container maxWidth="md" className={classes.container}>
                    {/* <Header role={role} /> */}
                    <main>
                        <div className={classes.toolbar} />
					    { children }
                    </main>
                </Container>
                <Footer title='Cryms' description='Streamlined employment application background check workflows' />
            </React.Fragment>
        )
    }

    private handleDrawerToggle = () => {
        this.setState({ open: !this.state.open });
    };
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(MainLayout)));