import React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  Card,
  CardContent,
  Fab,
  List,
  TextField,
  Theme,
  withTheme, 
  WithTheme 
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Add as AddIcon } from '@material-ui/icons';
import '../../../styles/App.css';
import MainLayout from '../../../components/MainLayout';
import LoadIndicator from '../../../components/LoadIndicator';
import RoleGate from '../../../components/RoleGate';
import LSVPanel from '../../../components/lsv/Panel';
import LSVAddModal from '../../../components/lsv/AddEditModal';
import { actions as livescanVendorActions, IReturn as LivescanVendor } from '../../../api/resources/LivescanVendor';
import { ApplicationState } from '../../../reducers';

const styles = (theme: Theme) =>
    createStyles({
        fab: {
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },      
        searchBar: {
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0',
        },
        filterBox: {
          minWidth: 120,
        },
        filler: {
          display: 'flex',
          flex: 1
        }
    });

interface IProps {
  lsvs: LivescanVendor[];
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
  return {
    lsvs: Object.values(state.livescanvendor.data).filter((lsv) => lsv.is_archived === false),
  };
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class LSVs extends React.PureComponent<FullProps> {
  state = {
    isLoading: false,
    filter: 'all',
    search: '',
    showAddModal: false,
  }
  
  public async componentDidMount() {
    this.retrieveData();
  }

  public async retrieveData() {
    const { dispatch } = this.props;
    const { search } = this.state;
    if (search) {
      dispatch(livescanVendorActions.index(undefined, undefined, {search}));
    }
    else {
      dispatch(livescanVendorActions.index(undefined, undefined, {}));
    }
  }

  render() {
    const { classes, lsvs } = this.props;
    const { isLoading, search, filter, showAddModal } = this.state;

    const lsvMarkup = lsvs
      .filter((lsv) => !search || lsv.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
      .map((lsv, index) => {
        return (
          <LSVPanel index={index} lsv={lsv} key={index} />
        )
      });
    
    return (
      <MainLayout title='Manage Locations' behavior='network'>
          <RoleGate role='admin' />
          {isLoading ? (
            <LoadIndicator />
          ) : (
            <>
              <div className={classes.searchBar}>
                <div className={classes.filler} />
                <TextField label="Search" variant="filled" onChange={(evt) => this.setState({search:evt.target.value})} />
              </div>
              <Card>            
                <CardContent>
                  <List>
                    { lsvMarkup }                
                  </List>
                </CardContent>
              </Card>
            </>
          )}
          <Fab color="primary" aria-label="add" className={classes.fab} onClick={this.handleAddClick}>
            <AddIcon />
          </Fab>
          <LSVAddModal open={showAddModal} onDismiss={this.handleDismiss} />
      </MainLayout>
    );
  }

  private handleFilterChange = (event: ChangeEvent<{name?: string, value: unknown}>) => {
    this.setState({filter: event.target.value});
  }

  private handleAddClick = () => {
    this.setState({showAddModal: true});
  }

  private handleDismiss = (shouldRefresh: boolean) => {
    this.setState({showAddModal: false});
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(LSVs)));