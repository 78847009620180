import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import UserChangePasswordModal from './ChangePasswordModal';
import UserDeleteModal from './DeleteModal';
import UserEditModal from './AddEditModal';
import { IReturn as User, actions as userActions } from '../../api/resources/User';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    });

interface IProps {
    index: number;
    user: User;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
    showChangeModal: boolean;
    showDeleteModal: boolean;
    showEditModal: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class UserItem extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
        showChangeModal: false,
        showDeleteModal: false,
        showEditModal: false,
    }
    
    render() {
        const { index, user, classes } = this.props;
        const { anchorEl, expanded, showChangeModal, showDeleteModal, showEditModal } = this.state;

        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem>
                    <ListItemText primary={(
                        <>{user.email}</>
                    )} secondary={(
                        <>{user.is_superuser ? 'Admin' : 'User'}</>
                    )}/>
                    {!user.is_self && (
                        <ListItemSecondaryAction>
                            <IconButton onClick={this.handleExpandMenu}>
                                <MoreVert />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}                    
                </ListItem>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={expanded}
                    onClose={this.handleCloseMenu}
                >
                    <MenuItem onClick={this.handleEditClick}>Edit</MenuItem>
                    <MenuItem onClick={this.handleChangePasswordClick}>Change Password</MenuItem>
                    <Divider />
                    <MenuItem onClick={this.handleDeleteClick}>Delete User</MenuItem>
                </Menu>
                <UserChangePasswordModal user={user} open={showChangeModal} onDismiss={this.handleChangePasswordDismiss} />
                <UserDeleteModal open={showDeleteModal} onDismiss={this.handleDeleteDismiss} />
                <UserEditModal user={user} open={showEditModal} onDismiss={this.handleEditDismiss} />
            </React.Fragment>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }

    private handleChangePasswordClick = () => {
        this.setState({
            expanded: false,
            showChangeModal: true,
        });
    }

    private handleChangePasswordDismiss = (shouldRefresh: boolean) => {
        this.setState({showChangeModal: false});
    }

    private handleDeleteClick = () => {
        this.setState({
            expanded: false, 
            showDeleteModal: true
        });
    }

    private handleDeleteDismiss = (shouldDelete: boolean) => {
        if (shouldDelete) {
            const { dispatch, user } = this.props;
            dispatch(userActions.delete(user.id)).catch(console.error);
        }
        this.setState({showDeleteModal: false});
    }

    private handleEditClick = () => {
        this.setState({
            expanded: false,
            showEditModal: true
        });
    }

    private handleEditDismiss = (shouldRefresh: boolean) => {
        this.setState({showEditModal: false});
    }
}

export default connect()(withTheme(withStyles(styles)(UserItem)));