import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction 
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import '../../styles/App.css';
import MainLayout from '../../components/MainLayout';
import LoadIndicator from '../../components/LoadIndicator';
// import UserCard from '../components/User/Card';
import RoleGate from '../../components/RoleGate';


class Admin extends React.PureComponent {
  state = {
    isLoading: false,
    users: [],
  }
  
  render() {
    const { isLoading } = this.state;
    
    return (
      <MainLayout title='Administration'>
          <RoleGate role='admin' />
          {isLoading ? (
            <LoadIndicator />
          ) : (
            <Card>            
              <CardContent>
                <List>
                  <ListItem button={true} component={RouterLink} to="/entities">
                    <ListItemText primary="Manage Entities" />
                    <ListItemSecondaryAction>
                      <NavigateNext />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider component="li" />
                  <ListItem button={true} component={RouterLink} to="/statuscodes">
                    <ListItemText primary="Manage Status Codes" />
                    <ListItemSecondaryAction>
                      <NavigateNext />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider component="li" />                  
                  <ListItem button={true} component={RouterLink} to="/users">
                    <ListItemText primary="Manage Users" />
                    <ListItemSecondaryAction>
                      <NavigateNext />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider component="li" />                  
                  <ListItem button={true} component={RouterLink} to="/vendors">
                    <ListItemText primary="Manage Vendors" />
                    <ListItemSecondaryAction>
                      <NavigateNext />
                    </ListItemSecondaryAction>
                  </ListItem>                  
                </List>
              </CardContent>
            </Card>
          )}
      </MainLayout>
    );
  }
}

export default connect()(Admin);