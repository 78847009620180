import React from 'react';
import {
    Avatar,
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { IReturn as LSV } from '../../api/resources/LivescanVendor';
import { IReturn as LivescanVendor } from '../../api/resources/LivescanVendor';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemSection: {
            flexBasis: '100%',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    });

// export interface IApplicant {
//     id: number;
//     appointment_type: number;
//     created: Date;
//     dob: Date;
//     first_name: string;
//     email: string;
//     employer: number;
//     last_name: string;
//     phone: string;
//     phone_ext: string;
//     ssn4: string;
//     status_code: number;
//     transaction_id: string;
//     type: string;
//     ori: string;
//     category: string | null;
//     vendor: number | null;
// }

interface IProps {
    index: number;
    lsv: LSV;
    // appointmentType: string;
    onClick: (lsv: LivescanVendor) => void;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class LSVItem extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
    }
    
    render() {
        const { index, lsv, classes, onClick } = this.props;
        const { anchorEl, expanded } = this.state;

        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem button={true} onClick={() => onClick(lsv)} >
                    <ListItemAvatar>
                        <Avatar>{lsv.name[0]}</Avatar>
                    </ListItemAvatar>
                    <ListItemText className={classes.itemSection} primary={(
                        <>{lsv.name}</>
                    )} secondary={(
                        <>{lsv.location_street1}{lsv.location_street2 && ', '+lsv.location_street2}, {lsv.location_city}, {lsv.location_state} {lsv.location_postal}</>
                    )}/>
                    <ListItemText className={classes.itemSection} secondary={(
                        <>
                            {lsv.location_phone}
                        </>
                    )} />
                    <NavigateNext />
                </ListItem>                
            </React.Fragment>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }
}

export default withTheme(withStyles(styles)(LSVItem));