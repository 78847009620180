import React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  Fab,
  Grid,
  Paper,
  Theme,
  Typography,
  withTheme, 
  WithTheme 
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Add as AddIcon } from '@material-ui/icons';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import '../../styles/App.css';
import MainLayout from '../../components/MainLayout';
import LoadIndicator from '../../components/LoadIndicator';
import RoleGate from '../../components/RoleGate';
import ApplicantAddModal from '../../components/applicant/AddModal';
import { ApplicationState } from '../../reducers';
import { actions as savedSearchActions, IReturn as SavedSearch } from '../../api/resources/SavedSearch';

const mapStateToProps = (state: ApplicationState) => {
	return {
		searches: Object.values(state.savedsearch.data),
	};
};

const styles = (theme: Theme) =>
    createStyles({
        fab: {
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },      
        searchBar: {
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0',
        },
        filterBox: {
          minWidth: 120,
        },
        searchGroup: {
          marginTop: 40,
        },
        resultCard: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '16px',
          textTransform: 'uppercase',
          cursor: 'pointer',
          transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:hover': {
            background: theme.palette.action.hover
          }
        }
    });

interface IState {
  isLoading: boolean;
  filter: string;
  showAddModal: boolean;
}

interface IProps {
  searches: SavedSearch[];
}

type FullProps = WithStyles<typeof styles> & WithTheme & RouteComponentProps & IProps & IDispatchProp;
class Dashboard extends React.PureComponent<FullProps, IState> {
  state = {
    isLoading: false,
    filter: 'all',
    showAddModal: false,
  }
  
  public componentDidMount() {
    this.loadData();
  }

  public async loadData() {
    const { dispatch } = this.props;
		try {
			await dispatch(savedSearchActions.index(undefined, undefined, {}));
			this.setState({ isLoading: false });
		} catch (error) {
      // dispatch(addErrorNotification(error));
      console.error(error);
		}
  }
  
  render() {
    const { classes, searches } = this.props;
    const { isLoading, filter, showAddModal } = this.state;

    let groups: SavedSearch[][] = [];
    searches.forEach((search) => {
      if (groups[search.group-1] === undefined) {
        groups[search.group-1] = [search];
      }
      else {
        groups[search.group-1].push(search);
      }
    });
    
    const searchesMarkup = groups.map((searchGroup, index) => {
      return (
        <Grid container spacing={2} justify='center' className={classes.searchGroup} key={index}>
          {searchGroup.map((search, index) => {
            return (
              <Grid item md={3} sm={6} xs={12} key={index}>
                <Paper onClick={() => this.handleSearchClick(search)} className={classes.resultCard}>
                  <Typography variant="h6">
                    {search.count}
                  </Typography>
                  <Typography variant="body2">
                    {search.description}
                  </Typography>
                </Paper> 
              </Grid>
            )
          })}
        </Grid>
      )
    });
    
    return (
      <MainLayout title='BIMS Dashboard' showSearch={true}>
          <RoleGate role='admin' />
          {isLoading ? (
            <LoadIndicator />
          ) : (
            <>
              {searchesMarkup}
            </>
          )}
          <Fab color="primary" aria-label="add" className={classes.fab} onClick={this.handleAddClick}>
            <AddIcon />
          </Fab>
          <ApplicantAddModal open={showAddModal} onDismiss={this.handleDismiss} />
      </MainLayout>
    );
  }

  private handleFilterChange = (event: ChangeEvent<{name?: string, value: unknown}>) => {
    if (event.target.value != undefined) {
      this.setState({filter: event.target.value as string});
    }    
  }

  private handleAddClick = () => {
    this.setState({showAddModal: true});
  }

  private handleDismiss = (shouldRefresh: boolean) => {
    if (shouldRefresh) {
      this.loadData();
    }
    this.setState({showAddModal: false});
  }
  
  private handleSearchClick = (search: SavedSearch) => {
    const { history } = this.props;
    history.push(`/results?${search.query_string}`);    
  }
}

export default connect(mapStateToProps)(withRouter(withTheme(withStyles(styles)(Dashboard))));