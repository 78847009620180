import React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  Card,
  CardContent,
  Fab,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Select,
  TextField,
  Theme,
  withTheme, 
  WithTheme 
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Add as AddIcon, NavigateNext } from '@material-ui/icons';
import '../../styles/App.css';
import MainLayout from '../../components/MainLayout';
import LoadIndicator from '../../components/LoadIndicator';
import RoleGate from '../../components/RoleGate';
import VendorItem from '../../components/vendor/Item';
import VendorAddModal from '../../components/vendor/AddEditModal';
import { actions as vendorActions, IReturn as Vendor } from '../../api/resources/Vendor';
import { ApplicationState } from '../../reducers';

const styles = (theme: Theme) =>
    createStyles({
        fab: {
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },      
        searchBar: {
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0',
        },
        filterBox: {
          minWidth: 120,
        },
        filler: {
          display: 'flex',
          flex: 1
        }
    });

interface IProps {
  vendors: Vendor[];
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    vendors: Object.values(state.vendor.data),
  };
}
    
type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class Vendors extends React.PureComponent<FullProps> {
  state = {
    isLoading: false,
    filter: 'all',
    search: '',
    showAddModal: false,
  }
  
  componentDidMount() {
    this.retrieveData();
  }

  public async retrieveData() {
    const { dispatch } = this.props;
    const { search } = this.state;
    if (search) {
      dispatch(vendorActions.index(undefined, undefined, {search}));
    }
    else {
      dispatch(vendorActions.index(undefined, undefined, {}));
    }
  }
  
  render() {
    const { classes, vendors } = this.props;
    const { isLoading, filter, search, showAddModal } = this.state;

    const vendorsMarkup = vendors
      .filter((vendor) => !search || vendor.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
      .map((vendor, index) => {
        return (
          <VendorItem index={index} vendor={vendor} key={index} />
        )
      });
    
    return (
      <MainLayout title='Manage Vendors'>
          <RoleGate role='admin' />
          {isLoading ? (
            <LoadIndicator />
          ) : (
            <>
              <div className={classes.searchBar}>
                <div className={classes.filler} />
                <TextField label="Search" variant="filled" onChange={(evt) => this.setState({search:evt.target.value})} />
                {/* <FormControl variant="filled" className={classes.filterBox}>
                  <InputLabel id="filter-label">Filter</InputLabel>
                  <Select
                    labelId="filter-label"
                    value={filter}
                    onChange={this.handleFilterChange}
                  >
                    <MenuItem value={'all'}><em>None</em></MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>
                  </Select>
                </FormControl> */}
              </div>
              <Card>            
                <CardContent>
                  <List>
                    { vendorsMarkup }                
                  </List>
                </CardContent>
              </Card>
            </>
          )}
          <Fab color="primary" aria-label="add" className={classes.fab} onClick={this.handleAddClick}>
            <AddIcon />
          </Fab>
          <VendorAddModal open={showAddModal} onDismiss={this.handleDismiss} />
      </MainLayout>
    );
  }

  private handleFilterChange = (event: ChangeEvent<{name?: string, value: unknown}>) => {
    this.setState({filter: event.target.value});
  }

  private handleAddClick = () => {
    this.setState({showAddModal: true});
  }

  private handleDismiss = (shouldRefresh: boolean) => {
    this.setState({showAddModal: false});
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(Vendors)));