import React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  Card,
  CardContent,
  Divider,
  Fab,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  withTheme, 
  WithTheme, 
  IconButton
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Add as AddIcon, NavigateNext, Assignment } from '@material-ui/icons';
import '../../../styles/App.css';
import MainLayout from '../../../components/MainLayout';
import LoadIndicator from '../../../components/LoadIndicator';
// import UserCard from '../components/User/Card';
import RoleGate from '../../../components/RoleGate';
import AppointmentItem from '../../../components/appointment/Item';
import AppointmentAddModal from '../../../components/appointment/AddEditModal';
import { actions as appointmentActions, IReturn as Appointment } from '../../../api/resources/Appointment';
import { ApplicationState } from '../../../reducers';
import moment from 'moment';
import EmptyState from '../../../components/EmptyState';
import Moment from 'react-moment';
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import debounce from 'lodash/debounce';

const styles = (theme: Theme) =>
    createStyles({
        fab: {
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },      
        searchBar: {
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0',
        },
        filterBox: {
          minWidth: 120,
        },
        dateRange: {
          position: 'absolute',
        }
    });

interface IProps {
  appointments: Appointment[];
}

interface IState {
  isLoading: boolean;
  filter: string;
  showAddModal: boolean;
  showDateRange: boolean;
  after?: string;
  before?: string;
  nameSearch: string;
  debouncedNameSearch: string;
}

interface ISearch {
  after: string;
  before: string;
  name?: string;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
  return {
    appointments: Object.values(state.appointment.data).sort((a: Appointment, b: Appointment) => moment(a.date_time).toDate().getTime() - moment(b.date_time).toDate().getTime())
  };
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class SchedulerDashboard extends React.PureComponent<FullProps> {
  state = {
    isLoading: false,
    filter: 'all',
    showAddModal: false,
    showDateRange: false,
    after: undefined as string|undefined,
    before: undefined as string|undefined,
    nameSearch: '',
    debouncedNameSearch: '',
  }
  
  public async componentDidMount() {
    const after = moment().startOf('day').toISOString(),
          before = moment().add(7, 'days').endOf('day').toISOString();

    this.setState({after, before});
  }

  public async componentDidUpdate(prevProps: FullProps, prevState: IState) {
    const { dispatch } = this.props;
    const { after, before, debouncedNameSearch } = this.state;
    const { after: prevAfter, before: prevBefore, debouncedNameSearch: beforeDebouncedNameSearch } = prevState;

    if (after !== prevAfter || before !== prevBefore || debouncedNameSearch !== beforeDebouncedNameSearch) {
      let params = { after, before } as ISearch;

      if (debouncedNameSearch !== '') {
        params = {
          ...params,
          name: debouncedNameSearch
        };
      }

      dispatch(appointmentActions.index(undefined, undefined, params));
    }
  }
  
  render() {
    const { classes, appointments } = this.props;
    const { isLoading, filter, showAddModal, showDateRange, after, before, debouncedNameSearch, nameSearch } = this.state;

    const afterString = moment(after).format('l'),
          beforeString = moment(before).format('l');

    const afterDate = moment(after).toDate(),
          beforeDate = moment(before).toDate();

    const reportUrl = new URLSearchParams(this.getParams() as {}).toString();
      
    let filteredAppointments = appointments.filter((appt) => {
      const apptDate = moment(appt.date_time).toDate();
      const dateMatch = apptDate >= afterDate && apptDate <= beforeDate;
      
      if (debouncedNameSearch === '') {
        return dateMatch;
      }
      
      const nameMatch = appt.first_name.indexOf(debouncedNameSearch) >= 0 || appt.last_name.indexOf(debouncedNameSearch) >= 0;
      return dateMatch && nameMatch;
    });
    
    let appointmentDates = [] as {date: Date, appointments: Appointment[]}[];
    let currentDay = [] as Appointment[];
    let lastDate = null as Date|null;
    filteredAppointments.forEach((appointment) => {
      const newDate = moment(appointment.date_time).startOf('day').toDate();
      if (lastDate?.getTime() !== newDate.getTime()) {
        if (lastDate != null && currentDay.length > 0) {
          appointmentDates.push({date: lastDate, appointments: currentDay});
          currentDay = [] as Appointment[];
        }
        lastDate = newDate;
      }
      currentDay.push(appointment);
    });
    if (lastDate != null && currentDay.length > 0) {
      appointmentDates.push({date: lastDate, appointments: currentDay});
      currentDay = [] as Appointment[];
    }
    
    const appointmentMarkup = appointmentDates.map((appointmentDay, index) => (
      <>
        <Typography variant="h6"><Moment format="LL">{appointmentDay.date}</Moment></Typography>
        <Card>            
          <CardContent>
            <List>
              {appointmentDay.appointments.map((appointment, index) => {
                return (
                  <AppointmentItem index={index} appointment={appointment} key={index} />
                )
              })}
            </List>
          </CardContent>
        </Card>
      </>
    ));
    
    return (
      <MainLayout title="Today's Appointments" behavior='scheduler'>
          <RoleGate role='admin' />
          {isLoading ? (
            <LoadIndicator />
          ) : (
            <>
              <div className={classes.searchBar}>
                {/* <TextField label="Search" variant="filled" /> */}
                {/* <FormControl variant="filled" className={classes.filterBox}>
                  <InputLabel id="filter-label">Filter</InputLabel>
                  <Select
                    labelId="filter-label"
                    value={filter}
                    onChange={this.handleFilterChange}
                  >
                    <MenuItem value={'all'}><em>None</em></MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>
                  </Select>
                </FormControl> */}

                <TextField 
                  label="Date range" 
                  variant="filled"
                  value={`${afterString} - ${beforeString}`}
                  onClick={this.handleDateClick}
                />
                
                <TextField 
                  label="Applicant name" 
                  variant="filled"
                  placeholder="Start typing..."
                  value={nameSearch}
                  onChange={this.handleNameChange}
                />

                <IconButton
                  href={`/report?${reportUrl}`}
                  target="_blank"> 
                  <Assignment />
                </IconButton>

                {showDateRange && (
                  <div className={classes.dateRange}>
                    <DateRangePicker
                      open={showDateRange}
                      toggle={this.handleDateDismiss}
                      initialDateRange={{startDate: moment(after).toDate(), endDate: moment(before).toDate()}}
                      onChange={this.setDateRange}
                      // wrapperClassName={classes.dateRange}
                    />
                  </div>
                )}                
              </div>
              
              { appointmentMarkup.length > 0 ? appointmentMarkup : (
                <EmptyState icon='error' text='No appointments to display' />
              ) }                
            </>
          )}
          <Fab color="primary" aria-label="add" className={classes.fab} onClick={this.handleAddClick}>
            <AddIcon />
          </Fab>
          <AppointmentAddModal open={showAddModal} onDismiss={this.handleDismiss} />
      </MainLayout>
    );
  }

  private handleFilterChange = (event: ChangeEvent<{name?: string, value: unknown}>) => {
    this.setState({filter: event.target.value});
  }

  private handleAddClick = () => {
    this.setState({showAddModal: true});
  }

  private handleDismiss = (shouldRefresh: boolean) => {
    this.setState({showAddModal: false});
  }

  private handleDateClick = () => {
    this.setState({showDateRange: true});
  }

  private handleDateDismiss = () => {
    this.setState({showDateRange: false});
  }

  private setDateRange = (dateRange: DateRange) => {
    this.setState({
      after: moment(dateRange.startDate).toISOString(),
      before: moment(dateRange.endDate).endOf('day').toISOString()
    });
  }

  private handleNameChange = (evt: ChangeEvent<{name?: string, value: unknown}>) => {
    this.setState({nameSearch: evt.target.value});
    this.debouncedNameChange();
  }

  private debouncedNameChange = debounce(() => {
    const { nameSearch } = this.state;
    this.setState({ debouncedNameSearch: nameSearch });
  }, 500);

  private getParams = () => {
    const { after, before, debouncedNameSearch } = this.state;
    let params = { after, before } as ISearch;

    if (debouncedNameSearch !== '') {
      params = {
        ...params,
        name: debouncedNameSearch
      };
    }
    
    return params;
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(SchedulerDashboard)));