import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Card,
    CardActions,
    CardContent,
    Input,
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    InputProps
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as LivescanVendor, actions as lsvActions } from '../../../api/resources/LivescanVendor';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';


const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        metaData: {
            marginTop: 12,
        },  
    });

interface IProps {
    lsv: LivescanVendor;
};

interface IState {
    editing: boolean;
}

const FormSchema = Yup.object().shape({
	appointment_duration: Yup.number().min(5).max(90).required(),
});


type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class AvailabilityDurationCard extends React.PureComponent<FullProps, IState> {
    public state = {
        editing: false,
    }
    
    render() {
        const { lsv, classes } = this.props;
        const { editing } = this.state;

        return (
            <Card>
                {editing ? (
                    <Formik
                        initialValues={{
                            appointment_duration: lsv.appointment_duration,
                        }}
                        validationSchema={FormSchema}
                        onSubmit={(values, {setSubmitting}) => {
                            this.onSave(values);
                        }}
                    >
                    {({ submitForm, isSubmitting }) => (
                        <Form>
                            <CardContent>
                                <Grid container spacing={2} className={classes.metaData}>
                                    <Grid item sm={6} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Appointment Duration</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="appointment_duration" type="number" inputProps={{min:5}} fullWidth required autoFocus />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" onClick={submitForm}>Save</Button>
                                <Button onClick={this.onCancel}>Cancel</Button>
                            </CardActions>
                        </Form>
                    )}
                    </Formik>
                ) : (
                    <>
                        <CardContent>
                            <Grid container spacing={2} className={classes.metaData}>
                                <Grid item sm={6} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Appointment Duration</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.appointment_duration} minutes</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button color="primary" onClick={this.onModify}>Modify</Button>
                        </CardActions>
                    </>
                )}
                
            </Card>
        )
    }

    public onModify = () => {
        this.setState({editing: true});
    };

    public onSave = (values: Partial<LivescanVendor>) => {
        const { lsv, dispatch } = this.props;
        
        dispatch(lsvActions.update(lsv.id, values));

        this.setState({editing: false});
    }

    public onCancel = () => {
        this.setState({editing: false});
    }
}

export default connect()(withTheme(withStyles(styles)(AvailabilityDurationCard)));