import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Button,
    Collapse,
    Divider,
    ListItem,
    ListItemText,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import EntityDeleteModal from './DeleteModal';
import EntityEditModal from './AddEditModal';
import { IReturn as Location, actions as locationActions } from '../../api/resources/Location';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        itemSection: {
            flexBasis: '100%',
        }
    });

// export interface IEntity {
//     city: string;
//     contact_email: string;
//     contact_first_name: string;
//     contact_last_name: string;
//     contact_phone: string;
//     employer: number;
//     entity_type: string;
//     identifier: string;
//     is_active: boolean;
//     name: string;
//     state: string;
//     street1: string;
//     street2: string;
//     zip: string;
// }

interface IProps {
    index: number;
    entity: Location;
};

interface IState {
    expanded: boolean;
    showDeleteModal: boolean;
    showEditModal: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class EntityItem extends React.PureComponent<FullProps> {
    public state = {
        expanded: false,
        showDeleteModal: false,
        showEditModal: false,
    }
    
    render() {
        const { index, entity, classes } = this.props;
        const { expanded, showDeleteModal, showEditModal } = this.state;

        return (
            <React.Fragment>
                {index !== 0 && (
                <Divider component="li" />
                )}
                <ListItem button onClick={this.handleExpandClick}>
                    <ListItemText className={classes.itemSection} primary={entity.name} secondary={(
                        <>{entity.entity_type} - {entity.identifier}</>
                    )}/>
                    <ListItemText className={classes.itemSection} secondary={(
                        <>
                            {entity.street1}
                            {entity.street2 && (<><br />{entity.street2}</>)}
                            <br />{entity.city}, {entity.state} {entity.zip_code}
                        </>
                    )} />
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div className={classes.content}>
                        <Typography variant="subtitle2">
                            Point of contact
                        </Typography>
                        <Typography variant="body2">
                            {entity.contact_first_name} {entity.contact_last_name}<br />
                            {entity.contact_email}<br />
                            {entity.contact_phone}
                        </Typography>
                    </div>
                    <div className={classes.itemActions}>
                        <Button onClick={this.handleDeleteClick}>Delete</Button>
                        {/* <Button color="primary">Activate</Button> */}
                        <Button onClick={this.handleEditClick} color="primary">Edit</Button>
                    </div>
                </Collapse>
                <EntityDeleteModal open={showDeleteModal} onDismiss={this.handleDeleteDismiss} />
                <EntityEditModal location={entity} open={showEditModal} onDismiss={this.handleEditDismiss} />
            </React.Fragment>
        )
    }

    private handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded});
    }

    private handleDeleteClick = () => {
        this.setState({showDeleteModal: true});
    }

    private handleDeleteDismiss = (shouldDelete: boolean) => {
        if (shouldDelete) {
            const { dispatch, entity } = this.props;
            dispatch(locationActions.delete(entity.id, undefined, undefined));
        }
        this.setState({showDeleteModal: false});
    }

    private handleEditClick = () => {
        this.setState({showEditModal: true});
    }

    private handleEditDismiss = (shouldRefresh: boolean) => {
        this.setState({showEditModal: false});
    }
}

export default connect()(withTheme(withStyles(styles)(EntityItem)));