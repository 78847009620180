import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  createStyles,
  Grid,
  List,
  Paper,
  Theme,
  WithTheme,
  withTheme,
  WithStyles,
  withStyles,
  Typography,
} from '@material-ui/core';
import '../../../styles/App.css';
import MainLayout from '../../../components/MainLayout';
import LoadIndicator from '../../../components/LoadIndicator';
import RoleGate from '../../../components/RoleGate';
import ProfileSummaryCard from '../../../components/profile/summary/Card';
import ProfileContactCard from '../../../components/profile/contact/Card';
import ProfileLocationCard from '../../../components/profile/location/Card';
import { actions as lsvActions, IReturn as LivescanVendor } from '../../../api/resources/LivescanVendor';
import { ApplicationState } from '../../../reducers';
import { getSessionContextID } from '../../../selectors';

const styles = (theme: Theme) =>
    createStyles({
        paperActions: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
        paperTitle: {
          padding: theme.spacing(2),
          paddingBottom: 0,
        },
        centeredPaperContent: {
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        centeredAction: {
          margin: theme.spacing(1),
        },
        paperContent: {
          padding: theme.spacing(2),
        },
        item: {
          display: 'flex',
        },
        itemPaper: {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        spacer: {
          flex: 1,
        }
    });

interface IState {
  isLoading: boolean;
};

interface IProps {
  lsv?: LivescanVendor;
  lsvId: number;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
  const lsvId = getSessionContextID(state);

	return {
    lsv: lsvId ? state.livescanvendor.data[lsvId] : undefined,
    lsvId,
	};
};

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class NetworkProfile extends React.PureComponent<FullProps, IState> {
  state = {
    isLoading: false,
  }
  
  public async componentDidMount() {
    const { lsvId, dispatch } = this.props;
    dispatch(lsvActions.cacheread({}, lsvId)).catch(console.error);
  }

  render() {
    const { isLoading } = this.state;
    const { lsv, classes } = this.props;
    
    return (
      <>
        {lsv ? (
          <MainLayout title='Manage Profile' behavior='network'>
              <RoleGate role='admin' />
              {isLoading ? (
                <LoadIndicator />
              ) : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <ProfileSummaryCard lsv={lsv} />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6">Primary Contact</Typography>
                      <ProfileContactCard lsv={lsv} />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6">Location Information</Typography>
                      <ProfileLocationCard lsv={lsv} />
                    </Grid>

                  </Grid>
                </>
              )}
          </MainLayout>
          ) : (
            <LoadIndicator />
          )}        
      </>
    );
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(NetworkProfile)));