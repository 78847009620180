import { getQueryString, Id } from "restdux";

export interface IConfig {
	API_HOST: string;
	API_KEY: string;
}

export const Config: IConfig = {
	API_HOST: "/api",
	API_KEY: "",
};

export const getConfig = function get() {
	return Config;
};

export const setConfig = function set(config: Partial<IConfig>) {
	Object.assign(Config, config);
};

type IParameterBag = object;

const getAPIUrl = function get(relativeUrl: string) {
	return () => {
		let url = `${relativeUrl}`;
		if (Config.API_HOST) {
			return Config.API_HOST + url;
		}
		return url;
	};
};

const getCallAPIUrlFunction = function get<T extends IParameterBag>(relativeUrl: string, call: string) {
	return (id?: Id, urlParameters?: T) => {
		const rootUrl = getAPIUrl(relativeUrl)();
		return rootUrl
			+ "/"
			+ id
			+ "/"
			+ call
			+ getQueryString<T>(urlParameters)
		;
	};
};

const getCallListAPIUrlFunction = function get<T extends IParameterBag>(relativeUrl: string, call: string) {
	return (id?: Id, urlParameters?: T) => {
		const rootUrl = getAPIUrl(relativeUrl)();
		return rootUrl
			+ "/"
			+ call
			+ getQueryString<T>(urlParameters)
		;
	};
};

const getHeaders = function get() {
	return {
		"Accept": "application/json",
		"Content-Type": "application/json",
		"Authorization": Config.API_KEY ? `Bearer ${Config.API_KEY}` : ''
	};
};

const transformIndex = function transformIndexF(payload: any) {
	return payload;
};

export {
	getAPIUrl,
	getCallAPIUrlFunction,
	getCallListAPIUrlFunction,
	getHeaders,
	transformIndex,
};