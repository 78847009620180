import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	disclaimer_text: string;
	employer: number;
	flow: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "disclaimer",
	rootUrl: getAPIUrl("/disclaimers"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});