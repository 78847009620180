import { connect } from 'react-redux';
import { getSessionRole } from '../selectors';
import { ApplicationState } from '../reducers';

interface IProps {
    sessionRole: string,
    role: string,
};

const mapStateToProps = (state: ApplicationState) => ({
    sessionRole: getSessionRole(state),
});

function RoleGate(props: IProps) {
    // const { role, sessionRole } = props;
    return null;
    // return (
    //     (role === sessionRole) ? (
    //         null
    //     ) : (
    //         <Redirect to='/' />
    //     )
    // );
}

export default connect(mapStateToProps)(RoleGate);