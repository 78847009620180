import {
    Address,
    Applicant,
    ApplicantHistory,
    ApplicantType,
    Appointment,
    AppointmentHistory,
    AvailableTime,
    BlockedTime,
    Disclaimer,
    Employer,
    File,
    Identification,
    LivescanVendor,
    Location,
    Network,
    Note,
    ORI,
    PaymentToken,
    PaymentTransaction,
    SavedSearch,
    StatusCode,
    User,
    Vendor,
    Waiver,
    WaiverSignature
} from '../api';
import TokenHook from './token';
import { combineReducers } from 'redux'
import environment from './environment';
import session from './wrappedSession';
import illegibleWizard from './illegibleWizard';
import newWizard from './newWizard';

const bimsStore = combineReducers({
    address: Address.reducer,
    applicant: Applicant.reducer,
    applicanthistory: ApplicantHistory.reducer,
    applicanttype: ApplicantType.reducer,
    appointment: Appointment.reducer,
    appointmenthistory: AppointmentHistory.reducer,
    availabletime: AvailableTime.reducer,
    blockedtime: BlockedTime.reducer,
    disclaimer: Disclaimer.reducer,
    employer: Employer.reducer,
    environment,
    file: File.reducer,
    identification: Identification.reducer,
    illegibleWizard,
    livescanvendor: LivescanVendor.reducer,
    location: Location.reducer,
    network: Network.reducer,
    newWizard,
    note: Note.reducer,
    ori: ORI.reducer,
    paymenttoken: PaymentToken.reducer,
    paymenttransaction: PaymentTransaction.reducer,
    savedsearch: SavedSearch.reducer,
    session,
    statuscode: StatusCode.reducer,
    token: TokenHook,
    user: User.reducer,
    vendor: Vendor.reducer,
    waiver: Waiver.reducer,
    waiversignature: WaiverSignature.reducer
});

export type ApplicationState = ReturnType<typeof bimsStore>;
export default bimsStore;