export default [
    {value: "AA", name: "Albania"},
    {value: "AB", name: "Alberta"},
    {value: "AD", name: "Andorra"},
    {value: "AE", name: "Anguilla"},
    {value: "AF", name: "Afghanistan"},
    {value: "AG", name: "Aguascalientes"},
    {value: "AH", name: "Ashmore and Cartier Islands, Territory of"},
    {value: "AI", name: "Antigua and Barbuda"},
    {value: "AJ", name: "Aruba"},
    {value: "AK", name: "Alaska"},
    {value: "AL", name: "Alabama"},
    {value: "AM", name: "American Samoa"},
    {value: "AN", name: "Algeria"},
    {value: "AO", name: "Angola"},
    {value: "AP", name: "Armenia"},
    {value: "AQ", name: "Azores Islands"},
    {value: "AR", name: "Arkansas"},
    {value: "AS", name: "Australia"},
    {value: "AT", name: "Argentina"},
    {value: "AU", name: "Austria"},
    {value: "AV", name: "Azerbaijan"},
    {value: "AX", name: "Apache Tribe Indian Nation"},
    {value: "AZ", name: "Arizona"},
    {value: "BA", name: "Baja California (Northern Section)"},
    {value: "BB", name: "Barbados"},
    {value: "BC", name: "British Columbia"},
    {value: "BD", name: "Bahamas"},
    {value: "BE", name: "Bahrain/Bahrein"},
    {value: "BF", name: "Bassas Da India"},
    {value: "BG", name: "Belgium"},
    {value: "BH", name: "Belize"},
    {value: "BI", name: "Burundi"},
    {value: "BJ", name: "Baja California Sur (Southern Section)"},
    {value: "BK", name: "Baker Island"},
    {value: "BL", name: "Bangladesh"},
    {value: "BM", name: "Bermuda, Dependent Territory of"},
    {value: "BN", name: "Bhutan"},
    {value: "BO", name: "British Indian Ocean Territory"},
    {value: "BP", name: "Bosnia and Herzegovina"},
    {value: "BQ", name: "Bouvet Island"},
    {value: "BR", name: "Burma"},
    {value: "BS", name: "Solomon Islands"},
    {value: "BT", name: "Botswana"},
    {value: "BU", name: "Bulgaria"},
    {value: "BV", name: "Bolivia"},
    {value: "BW", name: "Balearic Islands"},
    {value: "BX", name: "Brunei"},
    {value: "BY", name: "Byelarus"},
    {value: "BZ", name: "Brazil"},
    {value: "CA", name: "California"},
    {value: "CB", name: "Colombia, Republic of"},
    {value: "CC", name: "Cuba, Republic of"},
    {value: "CD", name: "Canada"},
    {value: "CE", name: "Campeche"},
    {value: "CF", name: "Chad"},
    {value: "CG", name: "Caroline Islands"},
    {value: "CH", name: "Chihuahua"},
    {value: "CI", name: "Chiapas"},
    {value: "CJ", name: "Cambodia"},
    {value: "CK", name: "Caddo Tribe Indian Nation"},
    {value: "CL", name: "Colima"},
    {value: "CM", name: "Cameroon"},
    {value: "CO", name: "Colorado"},
    {value: "CP", name: "Cayman Islands"},
    {value: "CQ", name: "Chile, Republic of"},
    {value: "CR", name: "Costa Rica, Republic of"},
    {value: "CS", name: "Cyprus, Republic of"},
    {value: "CT", name: "Connecticut"},
    {value: "CU", name: "Coahuila"},
    {value: "CV", name: "Cape Verde Islands"},
    {value: "CW", name: "Central African Republic"},
    {value: "CY", name: "Sri Lanka"},
    {value: "CZ", name: "Canal Zone"},
    {value: "DA", name: "Cheyenne & Arapaho Tribes Indian Nation"},
    {value: "DB", name: "Clipperton Island"},
    {value: "DC", name: "District of Columbia"},
    {value: "DD", name: "Cocos (Keeling) Islands, Territory of"},
    {value: "DE", name: "Delaware"},
    {value: "DF", name: "Distrito Federal (Mexico, D. F.)"},
    {value: "DG", name: "Comoros, Federal Islamic Republic of"},
    {value: "DH", name: "Benin"},
    {value: "DI", name: "Cook Islands"},
    {value: "DJ", name: "Coral Sea Islands, Territory of"},
    {value: "DK", name: "Denmark, Kingdom of"},
    {value: "DL", name: "Devil’s Lake Sioux Tribe Indian Nation"},
    {value: "DM", name: "Dominica"},
    {value: "DN", name: "Djibouti, Republic of"},
    {value: "DO", name: "Durango"},
    {value: "DP", name: "Comanche Nation Indian Nation"},
    {value: "DR", name: "Dominican Republic"},
    {value: "DS", name: "Miami Tribe Indian Nation"},
    {value: "DT", name: "Muscogee (Creek) Tribe Indian Nation"},
    {value: "DV", name: "Seneca-Cayuga Tribes Indian Nation"},
    {value: "DW", name: "Citizen Band Pottawatomie Tribe Indian Nation"},
    {value: "EE", name: "Absentee Shawnee Indian Nation"},
    {value: "EK", name: "Equatorial Guinea"},
    {value: "EL", name: "El Salvador"},
    {value: "EN", name: "England"},
    {value: "EO", name: "Ethiopia"},
    {value: "ER", name: "Europa Island"},
    {value: "ES", name: "Estonia"},
    {value: "ET", name: "Eritrea"},
    {value: "EU", name: "Ecuador"},
    {value: "EY", name: "Egypt"},
    {value: "EZ", name: "Czech Republic"},
    {value: "FA", name: "Falkland Islands, Colony of the"},
    {value: "FC", name: "Fond du Lac Indian Nation"},
    {value: "FD", name: "Finland"},
    {value: "FG", name: "French Guiana"},
    {value: "FJ", name: "Fiji"},
    {value: "FL", name: "Florida"},
    {value: "FN", name: "France"},
    {value: "FO", name: "Faroe Islands"},
    {value: "FP", name: "French Polynesia, Territory of"},
    {value: "FR", name: "French Southern and Antarctic Lands, Territory of"},
    {value: "FS", name: "Federated States of Micronesia"},
    {value: "FX", name: "Sac & Fox Indian Nation"},
    {value: "GA", name: "Georgia (state)"},
    {value: "GB", name: "Gabon"},
    {value: "GC", name: "Greece"},
    {value: "GD", name: "Georgia (formerly Gruzinskaya)"},
    {value: "GE", name: "Germany"},
    {value: "GF", name: "Guernsey, Bailiwick of"},
    {value: "GG", name: "Ghana"},
    {value: "GI", name: "Guinea"},
    {value: "GJ", name: "Grenada"},
    {value: "GK", name: "Gambia, The"},
    {value: "GM", name: "Guam"},
    {value: "GN", name: "Greenland"},
    {value: "GO", name: "Glorioso Islands"},
    {value: "GP", name: "Guadeloupe, Department of"},
    {value: "GR", name: "Guerrero"},
    {value: "GS", name: "South Georgia and the South Sandwich Islands"},
    {value: "GT", name: "Guatemala"},
    {value: "GU", name: "Guanajuato"},
    {value: "GY", name: "Guyana"},
    {value: "GZ", name: "Gaza"},
    {value: "HD", name: "Honduras"},
    {value: "HE", name: "Heard Island and McDonald Islands, Territory of"},
    {value: "HI", name: "Hawaii"},
    {value: "HK", name: "Hong Kong"},
    {value: "HL", name: "Hidalgo"},
    {value: "HN", name: "Vanuatu, Republic of"},
    {value: "HO", name: "Howland Island"},
    {value: "HR", name: "Christmas Island, Territory of"},
    {value: "HS", name: "Saint Helena"},
    {value: "HT", name: "Haiti"},
    {value: "HU", name: "Hungary"},
    {value: "IA", name: "Iowa"},
    {value: "IB", name: "Isle of Man"},
    {value: "IC", name: "Iceland"},
    {value: "ID", name: "Idaho"},
    {value: "IE", name: "Ireland (does not include Northern Ireland)"},
    {value: "II", name: "India"},
    {value: "IL", name: "Illinois"},
    {value: "IM", name: "Madeira Islands"},
    {value: "IN", name: "Indiana"},
    {value: "IO", name: "Indonesia (now includes Portugese Timor)"},
    {value: "IQ", name: "Iraq"},
    {value: "IR", name: "Iran"},
    {value: "IS", name: "Israel"},
    {value: "IT", name: "Italy (includes Sicily and Sardinia)"},
    {value: "IU", name: "Niue"},
    {value: "IW", name: "Iowa Tribe"},
    {value: "IX", name: "Menominee"},
    {value: "IY", name: "Côte d’Ivoire (Ivory Coast)"},
    {value: "JA", name: "Japan"},
    {value: "JE", name: "Jersey, Bailiwick of"},
    {value: "JI", name: "Johnston Atoll"},
    {value: "JL", name: "Jalisco"},
    {value: "JM", name: "Jamaica"},
    {value: "JN", name: "Jan Mayen"},
    {value: "JO", name: "Jordan"},
    {value: "JR", name: "Jarvis Island"},
    {value: "JU", name: "Juan de Nova Island"},
    {value: "KB", name: "Kiribati"},
    {value: "KC", name: "Croatia"},
    {value: "KE", name: "Kenya"},
    {value: "KH", name: "Manahiki Island"},
    {value: "KI", name: "Kingman Reef"},
    {value: "KK", name: "Kickapoo Tribe"},
    {value: "KN", name: "North Korea"},
    {value: "KO", name: "South Korea"},
    {value: "KP", name: "Shakopee"},
    {value: "KS", name: "Kansas"},
    {value: "KT", name: "Kazakhstan"},
    {value: "KU", name: "Kuwait"},
    {value: "KW", name: "Kiowa"},
    {value: "KY", name: "Kentucky"},
    {value: "KZ", name: "Kyrgyzstan"},
    {value: "LA", name: "Louisiana"},
    {value: "LB", name: "Liberia"},
    {value: "LC", name: "Mille Lacs"},
    {value: "LD", name: "Moldova"},
    {value: "LE", name: "Lesotho"},
    {value: "LF", name: "Slovakia"},
    {value: "LH", name: "Lithuania"},
    {value: "LI", name: "Liechtenstein"},
    {value: "LL", name: "Leech Lake Band of Chippewa"},
    {value: "LN", name: "Lebanon"},
    {value: "LO", name: "Slovenia"},
    {value: "LP", name: "Lac du Flambeau - Band of Lake Superior Chippewa"},
    {value: "LS", name: "Laos"},
    {value: "LT", name: "Latvia"},
    {value: "LU", name: "Saint Lucia"},
    {value: "LX", name: "Luxembourg"},
    {value: "LY", name: "Libya"},
    {value: "MA", name: "Massachusetts"},
    {value: "MB", name: "Manitoba"},
    {value: "MC", name: "Michoacan"},
    {value: "MD", name: "Maryland"},
    {value: "ME", name: "Maine"},
    {value: "MF", name: "Malawi"},
    {value: "MG", name: "Mongolia"},
    {value: "MH", name: "Marshall Islands"},
    {value: "MI", name: "Michigan"},
    {value: "MJ", name: "Monaco"},
    {value: "MK", name: "Mariana Islands"},
    {value: "ML", name: "Mali"},
    {value: "MM", name: "Mexico, state unknown"},
    {value: "MN", name: "Minnesota"},
    {value: "MO", name: "Missouri"},
    {value: "MS", name: "Mississippi"},
    {value: "MT", name: "Montana"},
    {value: "MU", name: "Mauritania"},
    {value: "MV", name: "Maldives"},
    {value: "MW", name: "Midway Islands"},
    {value: "MX", name: "Mexico (State)"},
    {value: "MY", name: "Malta"},
    {value: "MZ", name: "Malaysia"},
    {value: "NA", name: "Nayarit"},
    {value: "NB", name: "Nebraska"},
    {value: "NC", name: "North Carolina"},
    {value: "ND", name: "North Dakota"},
    {value: "NE", name: "Holland"},
    {value: "NF", name: "Newfoundland (includes Labrador)"},
    {value: "NG", name: "Nigeria"},
    {value: "NH", name: "New Hampshire"},
    {value: "NI", name: "Northern Ireland"},
    {value: "NJ", name: "New Jersey"},
    {value: "NK", name: "New Brunswick"},
    {value: "NL", name: "Nuevo Leon"},
    {value: "NM", name: "New Mexico"},
    {value: "NN", name: "Niger"},
    {value: "NO", name: "Papua New Guinea"},
    {value: "NP", name: "Nepal"},
    {value: "NQ", name: "New Caledonia"},
    {value: "NR", name: "Nauru"},
    {value: "NS", name: "Nova Scotia"},
    {value: "NT", name: "Northwest Territories"},
    {value: "NU", name: "Nicaragua"},
    {value: "NV", name: "Nevada"},
    {value: "NW", name: "Norway"},
    {value: "NX", name: "Bonaire (Netherlands Antilles)"},
    {value: "NY", name: "New York"},
    {value: "NZ", name: "New Zealand"},
    {value: "OA", name: "Oaxaca"},
    {value: "OC", name: "Macau"},
    {value: "OF", name: "Norfolk Island, Territory of"},
    {value: "OG", name: "Osage Nation Indian Nation"},
    {value: "OH", name: "Ohio"},
    {value: "OI", name: "Okinawa"},
    {value: "OK", name: "Oklahoma"},
    {value: "OM", name: "Oman"},
    {value: "ON", name: "Ontario"},
    {value: "OO", name: "Otoe-Missouria Tribe Indian Nation"},
    {value: "OR", name: "Oregon"},
    {value: "OS", name: "Oglala Sioux Indian Nation"},
    {value: "OT", name: "Oneida Tribe of Indians of Wisconsin Indian Nation"},
    {value: "PA", name: "Pennsylvania"},
    {value: "PB", name: "Puebla"},
    {value: "PC", name: "Pitcairn, Henderson, Ducie, and Oeno Islands"},
    {value: "PD", name: "Palau, Republic of"},
    {value: "PE", name: "Prince Edward Island"},
    {value: "PF", name: "Paracel Islands"},
    {value: "PG", name: "Guinea-Bissau"},
    {value: "PI", name: "Philippines"},
    {value: "PK", name: "Pakistan"},
    {value: "PL", name: "Palmyra Atoll"},
    {value: "PM", name: "Panama"},
    {value: "PN", name: "Ponca Tribe Indian Nation"},
    {value: "PO", name: "Poland"},
    {value: "PQ", name: "Quebec"},
    {value: "PR", name: "Puerto Rico"},
    {value: "PS", name: "Saint Pierre and Miquelon, Territorial Collectivity of"},
    {value: "PT", name: "Portugal"},
    {value: "PU", name: "Peru"},
    {value: "PV", name: "Paraguay"},
    {value: "PW", name: "Pawnee Tribe Indian Nation"},
    {value: "QA", name: "Qatar"},
    {value: "QR", name: "Quintana Roo"},
    {value: "QU", name: "Queretaro"},
    {value: "RA", name: "Russia"},
    {value: "RB", name: "Republic of Congo, Brazzaville"},
    {value: "RC", name: "People’s Republic of China"},
    {value: "RE", name: "Reunion, Department of"},
    {value: "RF", name: "Russian Federation"},
    {value: "RG", name: "Gibraltar"},
    {value: "RH", name: "Zimbabwe, Republic of"},
    {value: "RI", name: "Rhode Island"},
    {value: "RL", name: "Red Lake Indian Nation"},
    {value: "RR", name: "Montserrat"},
    {value: "RS", name: "Western Sahara, Independent State of"},
    {value: "RU", name: "Romania/Rumania"},
    {value: "RV", name: "Socialist Republic of Vietnam"},
    {value: "RW", name: "Rwanda"},
    {value: "RY", name: "Republic of Yemen"},
    {value: "SA", name: "Sierre Leone"},
    {value: "SB", name: "Saudi Arabia"},
    {value: "SC", name: "South Carolina"},
    {value: "SD", name: "South Dakota"},
    {value: "SE", name: "Seychelles"},
    {value: "SF", name: "South Africa"},
    {value: "SG", name: "Senegal"},
    {value: "SH", name: "San Marino"},
    {value: "SI", name: "Sinaloa"},
    {value: "SJ", name: "Namibia (South-West Africa)"},
    {value: "SK", name: "Seminole Nation Indian Nation"},
    {value: "SL", name: "San Luis Potosi"},
    {value: "SM", name: "Somalia"},
    {value: "SN", name: "Saskatchewan"},
    {value: "SO", name: "Sonora"},
    {value: "SP", name: "Spain"},
    {value: "SQ", name: "Sweden"},
    {value: "SR", name: "Singapore"},
    {value: "SS", name: "Scotland"},
    {value: "SU", name: "Sudan"},
    {value: "SV", name: "Svalbard"},
    {value: "SW", name: "Swaziland"},
    {value: "SY", name: "Syria"},
    {value: "SZ", name: "Switzerland"},
    {value: "TA", name: "Tamaulipas"},
    {value: "TB", name: "Tabasco"},
    {value: "TC", name: "United Arab Emirates (formerly Trucial States)"},
    {value: "TD", name: "Trust Territory of the Pacific Islands"},
    {value: "TE", name: "Spratly Islands"},
    {value: "TF", name: "Tuamotu Archipelago"},
    {value: "TG", name: "Tonga"},
    {value: "TH", name: "Thailand"},
    {value: "TI", name: "Portugese Timor (now included in Indonesia)--"},
    {value: "TJ", name: "Tajikistan"},
    {value: "TK", name: "Tokelau"},
    {value: "TL", name: "Tlaxcala"},
    {value: "TM", name: "Tromelin Island (French possession)"},
    {value: "TN", name: "Tennessee"},
    {value: "TO", name: "Togo"},
    {value: "TP", name: "Sao Tome and Principe"},
    {value: "TQ", name: "Tongareva"},
    {value: "TR", name: "Turks and Caicos Islands"},
    {value: "TS", name: "Nevis and Saint Christopher (Saint Kitts)"},
    {value: "TT", name: "Trinidad and Tobago"},
    {value: "TU", name: "Tunisia"},
    {value: "TV", name: "Tuvalu"},
    {value: "TW", name: "Taiwan, Republic of China"},
    {value: "TX", name: "Texas"},
    {value: "TY", name: "Turkey"},
    {value: "TZ", name: "Tanzania, United Republic of"},
    {value: "UC", name: "Turtle Mountain Band of Chippewa Indian Nation"},
    {value: "UG", name: "Uganda"},
    {value: "UK", name: "Ukraine"},
    {value: "UM", name: "Mauritius"},
    {value: "UR", name: "Turkmenistan"},
    {value: "US", name: "United States of America, State Unknown"},
    {value: "UT", name: "Utah"},
    {value: "UV", name: "Burkina Faso"},
    {value: "UY", name: "Uruguay"},
    {value: "UZ", name: "Uzbekistan, Republic of"},
    {value: "VA", name: "Virginia"},
    {value: "VB", name: "British Virgin Islands"},
    {value: "VC", name: "Veracruz"},
    {value: "VI", name: "Virgin Islands (U.S.)"},
    {value: "VL", name: "Navassa Island"},
    {value: "VT", name: "Vermont"},
    {value: "VV", name: "Saint Vincent and the Grenadines"},
    {value: "VY", name: "Vatican City"},
    {value: "VZ", name: "Venezuela, Republic of"},
    {value: "WA", name: "Washington"},
    {value: "WB", name: "West Bank"},
    {value: "WD", name: "Wyandotte Tribe Indian Nation"},
    {value: "WE", name: "White Earth Indian Nation"},
    {value: "WF", name: "Wallis and Futuna, Territory of"},
    {value: "WI", name: "Wisconsin"},
    {value: "WK", name: "Wake Island"},
    {value: "WL", name: "Wales"},
    {value: "WN", name: "West Indies-for West Indies Islands not listed"},
    {value: "WS", name: "Western Samoa"},
    {value: "WT", name: "Wichita Tribe Indian Nation"},
    {value: "WV", name: "West Virginia"},
    {value: "WY", name: "Wyoming"},
    {value: "XX", name: "Unknown Place of Birth"},
    {value: "YG", name: "Yugoslavia"},
    {value: "YO", name: "Mayotte"},
    {value: "YT", name: "Yukon (Territory)"},
    {value: "YU", name: "Yucatan"},
    {value: "YY", name: "Any country/dependency/territory not listed"},
    {value: "ZA", name: "Zacatecas"},
    {value: "ZB", name: "Martinique"},
    {value: "ZC", name: "Surinam"},
    {value: "ZD", name: "Macedonia"},
    {value: "ZI", name: "Canary Islands"},
    {value: "ZM", name: "Zambia, Republic of"},
    {value: "ZO", name: "Mozambique"},
    {value: "ZR", name: "Zaire, Republic of"},
];
