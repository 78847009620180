import React, { PureComponent } from 'react';


class LoadIndicator extends PureComponent {
	render() {
		return (
			<div>Please wait, loading...</div>
		);
	}
}

export default LoadIndicator;
