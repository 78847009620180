import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Card,
    CardActions,
    CardContent,
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    TextField
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as Applicant, actions as applicantActions } from '../../../api/resources/Applicant';
import { IReturn as ApplicantType, actions as applicantTypeActions } from '../../../api/resources/ApplicantType';
import { IReturn as Vendor, actions as vendorActions } from '../../../api/resources/Vendor';
import { ApplicationState } from '../../../reducers';
import AppTypeSelection from './AppTypeSelection';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        formControl: {
            width: '100%',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        paperActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        paperContent: {
            padding: theme.spacing(2),
        },
        paperTitle: {
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        metaData: {
            marginTop: 12,
        },
        itemPaper: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        spacer: {
            flex: 1,
        },
    });

interface IProps {
    applicant: Applicant;
    applicantType?: ApplicantType;
    applicantTypes?: ApplicantType[];
    vendor?: Vendor;
    vendors?: Vendor[];
};

interface IState {
    editing: boolean;
    snapshot?: IPartialApplicant;
}

interface IPartialApplicant {
    applicant_type: number;
    category: string;
    position: string;
    vendor: number | null;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { applicant } = ownProps;
    
    return {
        applicantType: applicant ? state.applicanttype.data[applicant.applicant_type] : undefined,
        applicantTypes: Object.values(state.applicanttype.data),
        vendor: (applicant && applicant.vendor) ? state.vendor.data[applicant.vendor] : undefined,
        vendors: Object.values(state.vendor.data),
    };
};

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ApplicantPositionCard extends React.PureComponent<FullProps, IState> {
    public state = {
        editing: false,
        snapshot: {
            applicant_type: -1,
            category: "",
            position: "",
            vendor: null
        }
    }
    
    public componentDidMount() {
        this.loadDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { applicant } = this.props;
        if (prevProps.applicant != applicant)
            this.loadDependencies();
    }

    public async loadDependencies() {
        const { applicant, dispatch } = this.props;
        dispatch(applicantTypeActions.cacheread({}, applicant.applicant_type)).catch(console.error);
        if (applicant.vendor)
            dispatch(vendorActions.cacheread({}, applicant.vendor)).catch(console.error);
    }

    render() {
        const { applicant, applicantType, applicantTypes, classes, vendor, vendors } = this.props;
        const { editing, snapshot } = this.state;

        // const snapshotType = applicant && applicantTypes ? applicantTypes[applicant.applicant_type] : null;

        return (
            <Paper className={classes.itemPaper}>
                <Typography variant="subtitle1" className={classes.paperTitle}>
                    Position
                </Typography>
                <Grid container spacing={1} className={classes.paperContent}>
                    {editing ? (
                        <Grid item xs={12}>
                            <AppTypeSelection applicantType={snapshot.applicant_type} onChange={(evt) => {this.setState({snapshot:{...snapshot, applicant_type: evt.target.value as number}})}} />
                        </Grid>
                        // <>
                        //     <Grid item xs={6}>
                        //         <Typography variant="subtitle2">App Type</Typography>
                        //     </Grid>
                        //     <Grid item xs={6}>
                        //         <Typography variant="body2">{applicantType && applicantType.description}</Typography>
                        //     </Grid>
                        // </>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">App Type</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">{applicantType && applicantType.description}</Typography>
                            </Grid>
                        </>
                    )}
                    
                    {editing ? (
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <TextField label="Role" value={snapshot.position} onChange={(evt) => {this.setState({snapshot:{...snapshot, position: evt.target.value}})}} />
                            </FormControl>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">Role</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">{applicant.position}</Typography>
                            </Grid>
                        </>
                    )}
                    
                    {editing ? (
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="cat-select-label" shrink={true}>Category</InputLabel>
                                <Select
                                displayEmpty={true}
                                labelId="cat-select-label"
                                id="cat-select"
                                value={snapshot.category}
                                onChange={this.updateCategory}
                                >
                                    <MenuItem value={''}>None</MenuItem>
                                    <MenuItem value={'CO'}>Contact</MenuItem>
                                    <MenuItem value={'NC'}>Non-Contact</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">Category</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">
                                    {applicant.category ? applicant.category : (<em>N/A</em>)}                                    
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {editing ? (
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="vendor-select-label">Vendor</InputLabel>
                                <Select
                                labelId="vendor-select-label"
                                id="vendor-select"
                                value={snapshot.vendor}
                                onChange={this.updateVendor}
                                >
                                    {vendors?.sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                                        <MenuItem value={vendor.id} key={index}>{vendor.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ):(
                        <>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">Vendor</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">
                                    {vendor ? vendor.name : (<em>N/A</em>)}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    
                </Grid>
                <div className={classes.spacer}></div>
                <div className={classes.paperActions}>
                    {editing ? (
                        <>
                            <Button color="primary" onClick={this.onSave}>Save</Button>
                            <Button onClick={this.onCancel}>Cancel</Button>
                        </>
                    ) : (
                        <Button color="primary" onClick={this.onModify}>Edit</Button>
                    )}
                </div>
            </Paper>
        )
    }

    public onModify = () => {
        const { applicant, dispatch } = this.props;
        const { applicant_type, category, position, vendor } = applicant;
        const snapshot = {
            applicant_type,
            category,
            position,
            vendor
        };
        dispatch(vendorActions.index(undefined, undefined, {})).catch(console.error);
        this.setState({editing: true, snapshot});
    };

    public onSave = () => {
        const { applicant, dispatch } = this.props;
        const { snapshot } = this.state;
        
        dispatch(applicantActions.update(applicant.id, snapshot));

        this.setState({editing: false});
    }

    public onCancel = () => {
        this.setState({editing: false});
    }

    public updateCategory = (inputProps: React.ChangeEvent<{name?: string | undefined, value: unknown}>) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: {
                ...snapshot,
                category: inputProps.target.value as string
            }
        });
    }

    public updateVendor = (inputProps: React.ChangeEvent<{name?: string | undefined, value: unknown}>) => {
        const { snapshot } = this.state;
        const vendor = inputProps.target.value === null ? null : parseInt(inputProps.target.value as string);
        this.setState({
            snapshot: {
                ...snapshot,
                vendor
            }
        });
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(ApplicantPositionCard)));