import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import Alert from '@material-ui/lab/Alert';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles, 
} from '@material-ui/core';
import { actions as userActions } from '../../../api/resources/User';
import { getSessionUserID } from '../../../selectors';
import { ApplicationState } from '../../../reducers';

const styles = (theme: Theme) =>
    createStyles({
        
    });

const mapStateToProps = (state: ApplicationState) => {
    return {
        sessionID: getSessionUserID(state),
    };
};

interface IProps {
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
    sessionID: number;
};

interface IState {
    oldPassword: string;
    newPassword: string;
    newPassword2: string;
    error: string;
}

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class AccountPasswordModal extends React.PureComponent<FullProps, IState> {
    public state = {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
        error: '',
    };
    
    render() {
        const { open } = this.props;
        const { error, oldPassword, newPassword, newPassword2 } = this.state;

        const valid = oldPassword && newPassword && newPassword2 && (newPassword === newPassword2);
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To change your account password, please enter the existing password along with your desired new password below.
                    </DialogContentText>
                    {error && (
                        <Alert severity="error">This password doesn't match our records. Please try again.</Alert>
                    )}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="oldpassword"
                        label="Old password"
                        type="password"
                        fullWidth
                        value={oldPassword}
                        onChange={(evt) => this.setState({oldPassword: evt.target.value, error: ''})}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="newpassword"
                        label="New password"
                        type="password"
                        fullWidth
                        value={newPassword}
                        onChange={(evt) => this.setState({newPassword: evt.target.value})}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="newpassword2"
                        label="Confirm password"
                        type="password"
                        fullWidth
                        value={newPassword2}
                        onChange={(evt) => this.setState({newPassword2: evt.target.value})}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!valid} onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.setState({
            oldPassword: '',
            newPassword: '',
            newPassword2: ''
        });

        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.updatePassword();
    }

    private async updatePassword() {
        // TODO: process request
        const { dispatch, sessionID } = this.props;
        const { oldPassword, newPassword } = this.state;
        
        if (!sessionID) {
            return;
        }
        
        const result = await dispatch(userActions.set_password(sessionID, {
            old_password: oldPassword,
            password: newPassword
        })).catch((response) => {
            this.setState({error: response.result.error})
        });

        if (result) {
            this.setState({
                oldPassword: '',
                newPassword: '',
                newPassword2: ''
            });
    
            this.props.onDismiss(true);
        }
        else {

        }      
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AccountPasswordModal));