import { actionTypes } from 'redux-simple-auth';
import { setConfig } from '../api';

interface IPayload {
  token?: string;
}

interface IAction {
    type: string;
    payload?: IPayload;
    response?: any;
}

const reducer = (state: any, action: IAction) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE_SUCCEEDED:
    case actionTypes.RESTORE:
      // debugger;
      if (action.payload && action.payload.token) {
        setConfig({API_KEY: action.payload.token});
      }
      break;
  }
  return null;
}

export default reducer;