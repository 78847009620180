import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	applicant: number;
	created: string;
	id: number;
	modified: string;
	note_text: string;
	user: number;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "note",
	rootUrl: getAPIUrl("/notes"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});