import { Resource, CombineResource, } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	applicant: number;
	city: string;
	id: number;
	state: string;
	street1: string;
	street2: string;
	address_type: "HOME"|"WORK";
	zip_code: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "address",
	rootUrl: getAPIUrl("/addresses"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});