import React from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        
    });

interface IProps {
    onDismiss: (shouldRefresh: boolean, tcr?: string) => void;
    open: boolean;
};

interface IState {
    tcr: string;
}

type FullProps = IProps & WithStyles<typeof styles>;
class ApplicantIllegibleModal extends React.PureComponent<FullProps, IState> {
    public state = {
        tcr: ''
    };

    componentDidUpdate(oldProps: IProps) {
        const { open: newOpen } = this.props;
        const { open: oldOpen } = oldProps;

        if (newOpen !== oldOpen) {
            this.setState({tcr: ''});
        }
    }

    render() {
        const { open } = this.props;
        const { tcr } = this.state;

        const canSave = !!tcr;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Illegible Prints</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to notify applicant of illegible prints? Please enter the received TCR below.
                    </DialogContentText>
                    <TextField 
                        autoFocus
                        fullWidth
                        label='TCR'
                        onChange={(evt) => this.setState({tcr: evt.target.value})}
                        required
                        value={tcr}
                        variant='filled'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} disabled={!canSave} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        const { tcr } = this.state;
        this.props.onDismiss(true, tcr);
    }
}

export default connect()(withStyles(styles)(ApplicantIllegibleModal));