import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export type DayOfWeek = 0|1|2|3|4|5|6;

export interface IReturn {
	// day_of_week: DayOfWeek;
	day_of_week: number;
	end_time: string;
	id: number;
	lsv: number;
	start_time: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "availabletime",
	rootUrl: getAPIUrl("/availabletimes"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});