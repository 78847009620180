import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import { IReturn as Location, actions as locationActions } from '../../../api/resources/Location';
import { ApplicationState } from '../../../reducers';

const styles = (theme: Theme) =>
    createStyles({
        locationList: {

        }
    });

interface IProps {
    locations?: Location[];
    onDismiss: (disposition: number[]) => void;
    open: boolean;
};

interface IState {
    checked: number[];
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    return {
        locations: Object.values(state.location.data),
    };
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class ApplicantLocationAddModal extends React.PureComponent<FullProps, IState> {
    public state = {
        checked: [-1]
    };
    
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(locationActions.index(undefined, undefined, {})).catch(console.error);
    }
    
    render() {
        const { classes, locations, open } = this.props;
        const { checked } = this.state;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Work Location</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the desired work location(s) from the list below.
                    </DialogContentText>
                    <List className={classes.locationList}>
                        {locations?.sort((a, b) => a.name.localeCompare(b.name)).map((location, index) => {
                            const value = location.id;
                            const labelId = `checkbox-list-label-${value}`;

                            return (
                            <ListItem key={index} role={undefined} dense button onClick={this.handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={location.name} />
                            </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleToggle = (value: number) => {
        const ctx = this;
        return () => {
            const { checked } = ctx.state;
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
        
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
        
            ctx.setState({checked: newChecked});
        }
    };

    private handleCancel = () => {
        this.props.onDismiss([]);
    }

    private handleSave = () => {
        const { checked } = this.state;
        const locationsToAdd = checked.filter((id) => id !== -1);
        this.props.onDismiss(locationsToAdd);
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ApplicantLocationAddModal));