import React from 'react';
import { Location } from 'history';
import { Link as RouterLink } from 'react-router-dom';
import { fetch } from 'redux-simple-auth';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  createStyles,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Theme,
  WithTheme,
  withTheme,
  WithStyles,
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { ExpandMore, NavigateNext } from '@material-ui/icons';
import '../../styles/App.css';
import MainLayout from '../../components/MainLayout';
import LoadIndicator from '../../components/LoadIndicator';
// import UserCard from '../components/User/Card';
import RoleGate from '../../components/RoleGate';
import { default as ApplicantItem } from '../../components/applicant/Item';
import { ApplicationState } from '../../reducers';
import { actions as applicantActions, IReturn as Applicant } from '../../api/resources/Applicant';
import SearchPanel from '../../components/search/Panel';
import EmptyState from '../../components/EmptyState';

const mapStateToProps = (state: ApplicationState) => {
	return {
		// applicants: Object.values(state.applicant.data),
	};
};

const styles = (theme: Theme) =>
    createStyles({
        fab: {
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },      
        searchBar: {
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0',
        },
    });

interface IProps {
  // applicants: Applicant[];
  location: Location;
}

interface IState {
  isLoading: boolean;
  // results?: IApplicant[];
  applicants: Applicant[];
  query: any;
};

type FullProps = WithStyles<typeof styles> & WithTheme & IProps & IDispatchProp;
class Results extends React.PureComponent<FullProps, IState> {
  state = {
    applicants: [],
    isLoading: true,
    query: {}
  }
  
  public async componentDidMount() {
    const { location } = this.props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    this.loadQuery(query);
  }
  
  public async loadQuery(query: any) {
    const { dispatch } = this.props;
    
    this.setState({query});
		try {
			const results = await dispatch(applicantActions.index(undefined, undefined, query));
      this.setState({ isLoading: false, applicants: Object.values(results.result) });
		} catch (error) {
      // dispatch(addErrorNotification(error));
      console.error(error);
		}
  }

  render() {
    const { isLoading, applicants: results, query } = this.state;
    // const {  } = this.props;

    const resultsMarkup = results && results.map((result, index) => (
      <ApplicantItem applicant={result} index={index} key={index} />
    ));
    
    return (
      <MainLayout title='Results'>
          <RoleGate role='admin' />
          {isLoading ? (
            <LoadIndicator />
          ) : (
            <>
              <SearchPanel query={query} onChange={this.updateSearch} />
              <Card>            
                <CardContent>
                  <List>
                    {resultsMarkup && resultsMarkup.length > 0 ? resultsMarkup : (
                      <EmptyState icon='error' text='None to display' />
                    )}
                  </List>
                </CardContent>
              </Card>
            </>
          )}
      </MainLayout>
    );
  }

  public updateSearch = (query: any) => {
    this.loadQuery(query);
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(Results)));