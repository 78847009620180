import { IReturn as Employer } from '../api/resources/Employer';
import { IReturn as Network } from '../api/resources/Network';
import { actionTypes } from '../actions/environment';

type RecordType = 'EMPLOYER'|'NETWORK';

interface IEnvironmentAction {
  type: string;
  id: number;
  record: any;
  recordType: RecordType;
}

interface IEnvironmentState {
  record?: Employer|Network;
  recordType?: RecordType;
}

const reducer = (state: IEnvironmentState = {}, action: Partial<IEnvironmentAction>) => {
  switch (action.type) {
    case actionTypes.ENVIRONMENT_READ_INITIATE:
      break;
    case actionTypes.ENVIRONMENT_READ_SUCCESS:
      const newState = {
        ...state,
        record: action.record,
        recordType: action.recordType,
      }
      return newState;
  }
  return state;
}

export default reducer;
