import React, { PureComponent } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { getIsAuthenticated, getIsRestored, invalidateSession } from 'redux-simple-auth'

import { ApplicationState } from '../reducers';
import BimsActivation from './bims/Activation';
import BimsForgotPassword from './bims/ForgotPassword';
import BimsLogin from './bims/Login';
import BimsAccount from './bims/Account';
import BimsAdmin from './bims/Admin';
import BimsDashboard from './bims/Dashboard';
import BimsEntities from './bims/Entities';
import BimsRecord from './bims/Record';
import BimsResults from './bims/Results';
import BimsStatusCodes from './bims/StatusCodes';
import BimsUsers from './bims/Users';
import BimsVendors from './bims/Vendors';
import BimsDefault from './bims/Default';
import NetworkDefault from './network/Default';
import NetworkAccount from './network/secure/Account';
import NetworkForgotPassword from './network/anon/ForgotPassword';
import NetworkLogin from './network/anon/Login';
import NetworkAvailability from './network/secure/Availability';
import NetworkDashboard from './network/secure/Dashboard';
import NetworkLocations from './network/secure/LSVs';
import NetworkProfile from './network/secure/Profile';
import SchedulerDefault from './scheduler/Default';
import SchedulerAccount from './scheduler/secure/Account';
import SchedulerActivation from './scheduler/anon/Activation';
import SchedulerConfirmation from './scheduler/anon/Confirmation';
import SchedulerData from './scheduler/anon/Data';
import SchedulerDisclaimer from './scheduler/anon/Disclaimer';
import SchedulerForgotPassword from './scheduler/anon/ForgotPassword';
import SchedulerLanding from './scheduler/anon/Landing';
import SchedulerLocations from './scheduler/anon/Locations';
import SchedulerLogin from './scheduler/anon/Login';
import SchedulerLookup from './scheduler/anon/Lookup';
import SchedulerLookupResult from './scheduler/anon/LookupResult';
import SchedulerPayment from './scheduler/anon/Payment';
import SchedulerSchedule from './scheduler/anon/Schedule';
import SchedulerSelections from './scheduler/anon/Selections';
import SchedulerWaiver from './scheduler/anon/Waiver';
import SchedulerAvailability from './scheduler/secure/Availability';
import SchedulerDashboard from './scheduler/secure/Dashboard';
import SchedulerProfile from './scheduler/secure/Profile';
import SchedulerReport from './scheduler/secure/Report';

import { IReturn as Employer, actions as employerActions } from '../api/resources/Employer';
import { IReturn as Network, actions as networkActions } from '../api/resources/Network';
import { actions as environmentActions } from '../actions/environment';
import { IActionResult } from 'restdux';


interface IProps {
    dispatch: any,
    isAuthenticated: boolean,
    isRestored: boolean
};

const mapStateToProps = (state: ApplicationState) => ({
    isAuthenticated: getIsAuthenticated(state),
    isRestored: getIsRestored(state)
});

class Routes extends PureComponent<IProps> {
    componentDidMount() {
        const { isAuthenticated, isRestored } = this.props;
        const isNetwork = this.isNetwork();
        const isScheduler = this.isScheduler();

        if (isScheduler) {
            if (isRestored && isAuthenticated) {
                document.title = 'LSV Dashboard';
            }
            else {
                document.title = 'Applicant Portal';
            }            
        }
        else if (isNetwork) {
            document.title = 'Network Admin';
        }
        else {
            document.title = 'BIMS';
        }
    }
    
    render() {
        const { isAuthenticated, isRestored } = this.props;
        const isNetwork = this.isNetwork();
        const isScheduler = this.isScheduler();
        
        return (
            <BrowserRouter>
                {isNetwork ? (
                    (isRestored ?
                        (isAuthenticated ? (
                            <Switch>
                                <Route path="/account" component={NetworkAccount} />
                                <Route path="/availability" component={NetworkAvailability} />
                                <Route path="/dashboard" component={NetworkDashboard} />
                                <Route path="/locations" component={NetworkLocations} />
                                <Route path="/profile" component={NetworkProfile} />
                                <Route path="/logout" component={this.Logout} />
                                <Route component={NetworkDefault} />
                            </Switch>
                        ) : (
                            <Switch>
                                <Route path="/forgotpassword" component={NetworkForgotPassword} />
                                <Route path="/login" component={NetworkLogin} />
                                <Route component={NetworkDefault} />
                            </Switch>
                        )) : (
                            <div></div>
                        )
                    )
                ) : (
                isScheduler ? (
                    (isRestored ?
                        (isAuthenticated ? (
                            <Switch>
                                <Route path="/account" component={SchedulerAccount} />
                                <Route path="/availability" component={SchedulerAvailability} />
                                <Route path="/dashboard" component={SchedulerDashboard} />
                                <Route path="/profile" component={SchedulerProfile} />
                                <Route path="/logout" component={this.Logout} />
                                <Route path="/report" component={SchedulerReport} />
                                <Route component={SchedulerDefault} />
                            </Switch>
                        ) : (
                            <Switch>
                                <Route path="/activation" component={SchedulerActivation} />
                                <Route path="/confirmation" component={SchedulerConfirmation} />
                                <Route path="/data" component={SchedulerData} />
                                <Route path="/disclaimer/:mode" component={this.Disclaimer} />
                                <Route path="/forgotpassword" component={SchedulerForgotPassword} />
                                <Route path="/landing" component={SchedulerLanding} />
                                <Route path="/locations/:mode" component={this.Locations} />
                                <Route path="/login" component={SchedulerLogin} />
                                <Route path="/lookup" component={SchedulerLookup} />
                                <Route path="/result" component={SchedulerLookupResult} />
                                <Route path="/payment" component={SchedulerPayment} />
                                <Route path="/schedule/:lsv/:mode" component={this.Schedule} />
                                <Route path="/selections" component={SchedulerSelections} />
                                <Route path="/waiver/:id" component={this.Waiver} />
                                <Route path="/waiver" component={SchedulerWaiver} />
                                <Route component={SchedulerDefault} />
                            </Switch>
                        )) : (
                            <div></div>
                        )
                    )
                ) : (
                    (isRestored ?
                        (isAuthenticated ? (
                            <Switch>
                                <Route path="/account" component={BimsAccount} />
                                <Route path="/admin" component={BimsAdmin} />
                                <Route path="/dashboard" component={BimsDashboard} />
                                <Route path="/entities" component={BimsEntities} />
                                <Route path="/logout" component={this.Logout} />
                                <Route path="/record/:id" component={this.RecordDetail} />
                                <Route path="/results" component={BimsResults} />
                                <Route path="/statuscodes" component={BimsStatusCodes} />
                                <Route path="/users" component={BimsUsers} />
                                <Route path="/vendors" component={BimsVendors} />
                                <Route component={BimsDefault} />
                            </Switch>
                        ) : (
                            <Switch>
                                <Route path="/activation" component={BimsActivation} />
                                <Route path="/forgotpassword" component={BimsForgotPassword} />
                                <Route path="/login" component={BimsLogin} />
                                <Route component={BimsDefault} />
                            </Switch>
                        )) : (
                            <div></div>
                        )
                    )
                ))}                
            </BrowserRouter>
        )
    }

    RecordDetail = (props: any) => {
        return <BimsRecord applicantId={parseInt(props.match.params.id, 10)} {...props} />;
    }

    Disclaimer = (props: any) => {
        return <SchedulerDisclaimer isIllegible={props.match.params.mode==='illegible'} {...props} />;
    }
    
    Locations = (props: any) => {
        return <SchedulerLocations isIllegible={props.match.params.mode==='illegible'} {...props} />;
    }

    Schedule = (props: any) => {
        return <SchedulerSchedule id={props.match.params.lsv} isIllegible={props.match.params.mode==='illegible'} {...props} />;
    }

    Waiver = (props: any) => {
        return <SchedulerWaiver waiverIndex={parseInt(props.match.params.id, 10)} {...props} />;
    }
    
    Logout = () => {
        const { dispatch } = this.props;
        dispatch(invalidateSession())
        .catch(() => {
            alert('Login error');
        });
        return <Redirect to={'/'} />
    }

    private isScheduler() {
        const { dispatch } = this.props;
        const hostName = window.location.hostname;
        const isScheduler = hostName.indexOf('scheduler') >= 0;
        
        if (isScheduler) {
            const subdomain = hostName.split('.')[0];
            dispatch(employerActions.index(undefined, undefined, {applicant_subdomain: subdomain})).then((result: IActionResult<{}, {}, Employer[]>) => {
                if (result.result && result.result.length > 0) {
                    dispatch(environmentActions.environmentReadSuccess(result.result[0], 'EMPLOYER'));
                }                
            }).catch(console.error);
            dispatch(networkActions.index(undefined, undefined, {applicant_subdomain: subdomain})).then((result: IActionResult<{}, {}, Network[]>) => {
                if (result.result && result.result.length > 0) {
                    dispatch(environmentActions.environmentReadSuccess(result.result[0], 'NETWORK'));
                }                
            }).catch(console.error);
        }

        return isScheduler;
    }

    private isNetwork() {
        const { dispatch } = this.props;
        const hostName = window.location.hostname;
        const isNetwork = hostName.indexOf('network') >= 0;
        
        if (isNetwork) {
            const subdomain = hostName.split('.')[0];
            dispatch(networkActions.index(undefined, undefined, {dashboard_subdomain: subdomain})).then((result: IActionResult<{}, {}, Network[]>) => {
                if (result.result && result.result.length > 0) {
                    dispatch(environmentActions.environmentReadSuccess(result.result[0], 'NETWORK'));
                }                
            }).catch(console.error);
        }

        return isNetwork;
    }
}

export default connect(mapStateToProps)(Routes);