import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	count: number;
	description: string;
	group: number;
	id: number;
	query_string: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "savedsearch",
	rootUrl: getAPIUrl("/savedsearches"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});