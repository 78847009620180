import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../reducers';
import { getActiveWizard } from '../selectors/wizard';
import { Redirect } from 'react-router-dom';

interface IProps {
    activeWizard?: string,
};

const mapStateToProps = (state: ApplicationState) => ({
    activeWizard: getActiveWizard(state),
});

function RequiresActiveWizard(props: IProps) {
    const { activeWizard } = props;
    return (
        activeWizard ? (
            null
        ) : (
            <Redirect to='/' />
        )
    );
}

export default connect(mapStateToProps)(RequiresActiveWizard);