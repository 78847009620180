import { Resource, CombineResource, IStateBucket, Call } from "restdux";
import { getAPIUrl, getHeaders, transformIndex, getCallListAPIUrlFunction } from "../global/globals";
import { IDynamicFieldEntry } from './WaiverSignature';

export const AppointmentStatus = {
	SCHEDULED: 'SC',
	COMPLETED: 'CO',
	NO_SHOW: 'NS',
}

export const PaymentMethod = {
	ONLINE: 'OL',
	CASH: 'CA',
	CREDIT: 'CC',
	INVOICE: 'IN',
}

export interface IReturn {
	applicant: number;
	date_time: string;
	first_name: string;
	id: number;
	last_name: string;
	lsv: number;
	payment_method: string;
	notes: string;
	status: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

export interface IScheduleSend {
	mode: string;

	// shared params
	employer: number;
	lsv: number;
	dateTime: Date;
	
	// new wizard-only
	applicantType?: number;
	location?: number;
	notes?: string;
	vendor?: number;
	
	citizenship?: string;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	dob?: Date;
	ssn4?: string;
	phone?: string;
	email?: string;
	gender?: string;
	race?: string;
	height?: string;
	weight?: string;
	eye?: string;
	hair?: string;
	pob?: string;
	reason?: string;
	residence?: string;

	waiver_signatures?: { id: number, fields: IDynamicFieldEntry[], signature: string }[]

	paymentMethod?: string;
	cardNumber?: string;
	cardExpiry?: string;
	cardName?: string;
	cardCode?: string;
	cardZip?: string;

	// illegible wizard-only
	applicantId?: number;
}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "appointment",
	rootUrl: getAPIUrl("/appointments"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {
	availability: Call<{},{},{}, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "GET",
		url: getCallListAPIUrlFunction("/appointments", "availability"),
	}),
	schedule: Call<{}, IScheduleSend, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "POST",
		url: getCallListAPIUrlFunction("/appointments", "schedule"),
	})
});