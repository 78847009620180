import { Grid, Theme, Card, CardContent, List } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import TimeItem from '../../../components/time/Item';
import '../../../styles/App.css';
import EmptyState from '../../../components/EmptyState';
import { actions as appointmentActions } from '../../../api/resources/Appointment';
import { IReturn as LivescanVendor } from '../../../api/resources/LivescanVendor';
import moment from 'moment';
import { ApplicationState } from '../../../reducers';
import { getLSV, getNewWizard } from '../../../selectors/wizard';
import { IWizardState as NewWizard } from '../../../reducers/newWizard';
import { IActionResult } from 'restdux';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { actions as illWizardActions } from '../../../actions/illegibleWizard';


interface IProps {
	dispatch: any;
	id: number;
	isIllegible: boolean;
	lsv?: LivescanVendor;
	newWizard: NewWizard;
}

interface IState {
	appointmentDate: Date;
	times: Date[];
}

const mapStateToProps = (state: ApplicationState) => ({
	lsv: getLSV(state),
	newWizard: getNewWizard(state),
});

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			// alignItems: 'center',
			// display: 'flex',
			// flexDirection: 'column',
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		},
		timeSelection: {
			flex: 1,
		},
		timesList: {
            // maxHeight: '80vh',
            maxHeight: '300px',
            overflowY: 'scroll',
        }
	});

type FullProps = WithStyles<typeof styles> & IProps & RouteComponentProps;
class SchedulerSchedule extends React.PureComponent<FullProps, IState> {
  
  state = {
	appointmentDate: new Date(),
	times: [] as Date[]
  };

  public componentDidMount() {
	this.loadDependencies();
  }

  public componentDidUpdate(prevProps: FullProps) {
	const { id } = this.props;

	if (prevProps.id != id)
	  this.loadDependencies();
  }

  private async loadDependencies() {
	const { dispatch, id } = this.props;
	if (!id) {
		return;
	}
	this.handleDateSelection(new Date());
  }
 
  render() {
    const { classes, isIllegible, lsv } = this.props;
	const { appointmentDate, times } = this.state;

	const timesMarkup = times && times.map((result, index) => (
		<TimeItem time={result} zone={lsv?.timezone} index={index} key={index} onClick={this.onClick} />
	));

    return (
      <AnonLayout title={isIllegible ? 'Illegible Prints' : 'New Application'} subtitle='Please select a date and time below'>
        <Card>            
			<CardContent>
				<Grid container spacing={2}>
					<Grid item>
						<DatePicker
							autoOk
							orientation="landscape"
							variant="static"
							openTo="date"
							value={appointmentDate}
							onChange={(value) => this.handleDateSelection(value)}
							minDate={Date()}
                            minDateMessage='Must select date today or later'
						/>
					</Grid>
					<Grid item className={classes.timeSelection}>
						<List className={classes.timesList}>
							{timesMarkup && timesMarkup.length > 0 ? timesMarkup : (
								<EmptyState icon='error' text='No times to display' />
							)}
						</List>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
      </AnonLayout>
    );
  }

  private handleDateSelection = (selection: Date|null) => {
	const { dispatch, id, newWizard } = this.props;

	if (!selection) {
		return;
	}

	this.setState({appointmentDate: selection});
	dispatch(appointmentActions.availability(undefined, undefined, {
		lsv: id,
		date: moment(selection).format("YYYY-MM-DD"),
	})).then((result: IActionResult<{}, {}, string[]>) => {
		if (!result.result) {
			return;
		}
		const times = result.result as string[];
		this.setState({
			times: times.map((time) => moment(time).toDate())
		})
	}).catch(console.error);
  }

  public onClick = (time: string|Date) => {
	const { dispatch, isIllegible, history, newWizard } = this.props;
	
	if (isIllegible) {
		dispatch(illWizardActions.selectDateTime(new Date(time)));
		history.push(`/confirmation`);
	}
	else {
		dispatch(newWizardActions.selectDateTime(new Date(time)));
		
		// ADDED 3/18/24 by DE
		// Check if the user has already completed the next step in the wizard, in case
		// they were directed to select an alternate time. If so, jump straight to confirmation.
		if (newWizard.step && newWizard.step >= 5) {
			history.push(`/confirmation`);
		}
		else {
			history.push(`/selections`);
		}		
	}	
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerSchedule)));