import { Button, Grid, Theme, Paper, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';
import { ApplicationState } from '../../../reducers';
import { getEnvironmentID, isEnvironmentEmployer } from '../../../selectors/environment';
import { IReturn as Disclaimer, actions as disclaimerActions } from '../../../api/resources/Disclaimer';
import ReactMarkdown from 'react-markdown';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { actions as illWizardActions } from '../../../actions/illegibleWizard';

interface IProps {
	disclaimers: Disclaimer[];
	environmentID: string;
	isEnvironmentEmployer: boolean;
	isIllegible: boolean;	
}

const mapStateToProps = (state: ApplicationState) => ({
	disclaimers: Object.values(state.disclaimer.data),
	environmentID: getEnvironmentID(state),
	isEnvironmentEmployer: isEnvironmentEmployer(state),
});

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			// alignItems: 'center',
			// display: 'flex',
			// flexDirection: 'column',
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		}
	});

type FullProps = WithStyles<typeof styles> & IProps & RouteComponentProps & IDispatchProp;
class SchedulerDisclaimer extends React.PureComponent<FullProps> {
  
	state = {
		agreed: false,
		loading: true
	};

	public componentDidMount() {
		this.loadDependencies();
	}

	public componentDidUpdate(prevProps: FullProps) {
        const { environmentID } = this.props;

        if (prevProps.environmentID != environmentID)
            this.loadDependencies();
    }

	private async loadDependencies() {
		const { dispatch, environmentID, isEnvironmentEmployer } = this.props;
		if (environmentID) {
			await dispatch(disclaimerActions.index(undefined, undefined, isEnvironmentEmployer ? {employer: environmentID} : {network: environmentID})).catch(console.error);
			
			const { disclaimers, isIllegible } = this.props;
			const flow = isIllegible ? 'illegible' : 'new';
			const md = disclaimers.filter((disclaimer) => disclaimer.flow == flow);
			if (md.length <= 0) {
				this.onProceed();
			}
			this.setState({loading: false});
		}		
	}

	render() {
		const { classes, disclaimers, isIllegible } = this.props;
		const { agreed } = this.state;

		const handleCheck = () =>
			this.setState({agreed: !agreed});
		
		const flow = isIllegible ? 'illegible' : 'new';
		const md = disclaimers.filter((disclaimer) => disclaimer.flow == flow);

		return (
			<AnonLayout title={isIllegible ? 'Illegible Prints' : 'New Application'}>
				<Paper className={classes.paper}>
					{md.map((disclaimer) => (
						<ReactMarkdown>{disclaimer.disclaimer_text}</ReactMarkdown>
					))}					
					<FormGroup row>
						<FormControlLabel
							control={<Checkbox checked={agreed} onChange={handleCheck} name="checkAgreed" />}
							label="I have read the instructions and agree to the terms"
						/>
					</FormGroup>
					<Grid container={true} className={classes.centeredButton} justify="center" spacing={2}>
						<Button disabled={!agreed} onClick={this.onProceed} variant="contained" color="primary">
							Continue
						</Button>
					</Grid>
				</Paper>
			</AnonLayout>
		);
	}

	onProceed = () => {
		const { dispatch, history, isIllegible } = this.props;
		
		if (isIllegible) {
			dispatch(illWizardActions.acceptDisclaimer());
			history.push("/lookup");
		}
		else {
			dispatch(newWizardActions.acceptDisclaimer());
			history.push("/locations/new");
		}		
	}
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerDisclaimer)));