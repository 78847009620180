import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

// export type IdentificationType = 'DL'|'PP';

export interface IReturn {
	applicant: number;
	id: number;
	identifier: string;
	state: string;
	id_type: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "identification",
	rootUrl: getAPIUrl("/identifications"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});