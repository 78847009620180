import { Button, Grid, Theme, Paper } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';
import { actions as illWizardActions } from '../../../actions/illegibleWizard';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

interface IProps {
	dispatch: any;
}

interface IForm {
	regNumber: string;
	email: string;
	lastName: string;
}

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
			'&>div': {
				marginTop: theme.spacing(2),
			},
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		}
	});

const FormSchema = Yup.object().shape({
	lastName: Yup.string().required(),
	email: Yup.string().email().required(),
	regNumber: Yup.string().required(),
});

type FullProps = WithStyles<typeof styles> & IProps & RouteComponentProps;
class SchedulerLookup extends React.PureComponent<FullProps> {
  
  	state = {
		regNumber: '',
		email: '',
		lastName: '',
	};	

  render() {
    const { classes } = this.props;
	const { regNumber, email, lastName } = this.state;

	const valid = (regNumber && email && lastName);

	return (
      <AnonLayout title='Illegible Prints' subtitle='Please provide the information below'>
        <Paper className={classes.paper}>
			<Formik
			  initialValues={{
				regNumber: '',
				email: '',
				lastName: '',
			}}
			  validationSchema={FormSchema}
			  onSubmit={(values, {setSubmitting}) => {
				this.handleProceed(values);
			  }}
			>
				{({ submitForm, isSubmitting }) => (
					<Form className={classes.form}>
						<Field component={TextField} name='regNumber' variant='filled' label='Enter your original registration number' fullWidth autoFocus />
						<Field component={TextField} name='email' type='email' variant='filled' label='Enter your email address' fullWidth />
						<Field component={TextField} name='lastName' variant='filled' label='Last name' fullWidth />
						<Grid container={true} className={classes.centeredButton} justify="center" spacing={2}>
							<Button disabled={isSubmitting} onClick={submitForm} variant="contained" color="primary">
								Continue
							</Button>
						</Grid>
					</Form>					
				)}
			</Formik>
		</Paper>
      </AnonLayout>
    );
  }

  private handleProceed = (values: IForm) => {
	const { dispatch, history } = this.props;
	const { regNumber, email, lastName } = values;

	if (!email || !lastName || !regNumber) {
	  return;
	}
	
	dispatch(illWizardActions.setData(email, lastName, regNumber));

	history.push("/result");
  }
}

export default connect()(withRouter(withStyles(styles)(SchedulerLookup)));