import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as Location, actions as locationActions } from '../../api/resources/Location';

const styles = (theme: Theme) =>
    createStyles({
        
    });

type PartialLocation = Partial<Location>;

interface IState {
    location?: PartialLocation;
}

interface IProps {
    location?: Location;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class EntityAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        location: {} as PartialLocation,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            const { location } = this.props;
        
            this.setState({
                location: location || {} as PartialLocation
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open } = this.props;
        const { location } = this.state;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{location?.id ? (<>Edit Entity</>) : (<>Add Entity</>)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {location?.id ? (
                            <>
                                Edit entity/location details below.
                            </>
                        ) : (
                            <>
                                Enter new entity/location details below.
                            </>
                        )}                        
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Location Name"
                        fullWidth
                        value={location.name}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                name: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="identifier"
                        label="Identifier"
                        fullWidth
                        value={location.identifier}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                identifier: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="street1"
                        label="Street Address"
                        fullWidth
                        value={location.street1}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                street1: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="street2"
                        label="Suite / PO"
                        fullWidth
                        value={location.street2}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                street2: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="city"
                        label="City"
                        fullWidth
                        value={location.city}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                city: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="state"
                        label="State"
                        fullWidth
                        value={location.state}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                state: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="zip_code"
                        label="Zip"
                        fullWidth
                        value={location.zip_code}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                zip_code: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="contact_email"
                        label="Email"
                        fullWidth
                        type="email"
                        value={location.contact_email}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                contact_email: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="contact_first"
                        label="Contact First Name"
                        fullWidth
                        value={location.contact_first_name}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                contact_first_name: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="contact_last"
                        label="Contact Last Name"
                        fullWidth
                        value={location.contact_last_name}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                contact_last_name: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="contact_phone"
                        label="Contact Phone"
                        fullWidth
                        type="phone"
                        value={location.contact_phone}
                        onChange={(evt) => {
                            const location = {
                                ...this.state.location,
                                contact_phone: evt.target.value
                            };
                            this.setState({location});
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { dispatch } = this.props;
        const { location } = this.state;
        if (location?.id) {
            await dispatch(locationActions.update(location.id, location)).catch(console.error);
        } 
        else {
            await dispatch(locationActions.create(undefined, location)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(EntityAddEditModal));