import React from 'react';
import {
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import NoteDeleteModal from './DeleteModal';
import NoteEditModal from './AddEditModal';
import { IReturn as Note } from '../../api/resources/Note';
import Moment from 'react-moment';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    });

interface IProps {
    index: number;
    note: Note;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
    showDeleteModal: boolean;
    showEditModal: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class NoteItem extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
        showDeleteModal: false,
        showEditModal: false,
    }
    
    render() {
        const { index, note, classes } = this.props;
        const { anchorEl, expanded, showDeleteModal, showEditModal } = this.state;

        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem>
                    <ListItemText primary={(
                        <>{note.note_text}</>
                    )} secondary={(
                        <><Moment format="ll">{note.created}</Moment></>
                    )}/>
                    <ListItemSecondaryAction>
                        <IconButton onClick={this.handleExpandMenu}>
                            <MoreVert />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={expanded}
                    onClose={this.handleCloseMenu}
                >
                    <MenuItem onClick={this.handleEditClick}>Edit</MenuItem>
                    <Divider />
                    <MenuItem onClick={this.handleDeleteClick}>Delete</MenuItem>
                </Menu>
                <NoteDeleteModal open={showDeleteModal} note={note} onDismiss={this.handleDeleteDismiss} />
                <NoteEditModal open={showEditModal} note={note} onDismiss={this.handleEditDismiss} />
            </React.Fragment>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }

    private handleDeleteClick = () => {
        this.setState({
            expanded: false, 
            showDeleteModal: true
        });
    }

    private handleDeleteDismiss = (shouldRefresh: boolean) => {
        this.setState({showDeleteModal: false});
    }

    private handleEditClick = () => {
        this.setState({
            expanded: false,
            showEditModal: true
        });
    }

    private handleEditDismiss = (shouldRefresh: boolean) => {
        this.setState({showEditModal: false});
    }
}

export default withTheme(withStyles(styles)(NoteItem));