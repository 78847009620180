import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Card,
    CardActions,
    CardContent,
    Input,
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    InputProps,
    TextField,
    IconButton
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import {
    Delete,
} from '@material-ui/icons';
// import { IReturn as LivescanVendor, actions as lsvActions } from '../../../api/resources/LivescanVendor';
import { IReturn as AvailableTime, actions as availableTimeActions } from '../../../api/resources/AvailableTime';
import Moment from 'react-moment';
import moment from 'moment';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        metaData: {
            marginTop: 12,
        },
        timeField: {
            marginRight: theme.spacing(1),
            flexGrow: 1,
        },
        timeRow: {
            display: 'flex',
        },
        spacer: {
            flex: 1,
        }
    });

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface IProps {
    // lsv: LivescanVendor;
    times: AvailableTime[];
};

interface IDeletable {
    deleted: boolean;
}
type DeletableTime = Partial<AvailableTime> & IDeletable;

interface IState {
    editing: boolean;
    // snapshot?: Partial<LivescanVendor>;
    snapshot: DeletableTime[];
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class AvailabilityHoursCard extends React.PureComponent<FullProps, IState> {
    public state = {
        editing: false,
        snapshot: [{
            day_of_week: 0,
            start_time: '0:00',
            end_time: '23:59',
            id: 0,
            lsv: 0,
            deleted: false,
        }]
    }
    
    render() {
        const { times, classes } = this.props;
        const { editing, snapshot } = this.state;

        const bucketedTimes = daysOfWeek.map((day, index) => ({
            day,
            times: times.filter((time) => time.day_of_week == index)
        }));
        const bucketedSnapshot = daysOfWeek.map((day, index) => ({
            day,
            times: snapshot.filter((time) => time.day_of_week == index)
        }));

        return (
            <Card>
                <CardContent>
                    <Grid container spacing={2} className={classes.metaData}>
                        <Grid item sm={6} xs={12}>
                            {editing ? (
                                bucketedSnapshot.map((dayTimes) => (
                                    <>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} className={classes.timeRow} >
                                                <Typography variant="subtitle1">{dayTimes.day}</Typography>
                                                <div className={classes.spacer} />
                                                <Button variant='outlined' onClick={() => this.onAddTime(dayTimes.day)}>Add Time</Button>
                                            </Grid>
                                            {dayTimes.times.filter((time) => !time.deleted).map((time) => (
                                                <Grid item xs={12} className={classes.timeRow}>
                                                    <TextField label="Start time" variant="filled" required value={time.start_time} type="time" className={classes.timeField} onChange={(evt) => this.onUpdateStartTime(time, evt.target.value)} />
                                                    <TextField label="End time" variant="filled" required value={time.end_time} type="time" className={classes.timeField} onChange={(evt) => this.onUpdateEndTime(time, evt.target.value)} />
                                                    <IconButton onClick={() => this.onDeleteTime(time)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Grid>
                                            ))}
                                            
                                        </Grid>
                                    </>
                                ))
                            ) : (
                                bucketedTimes.map((dayTimes) => (
                                    <>
                                        {dayTimes.times.length > 0 && (
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">{dayTimes.day}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {dayTimes.times.map((time) => (
                                                        <Typography variant="body2"><Moment format='LT'>{moment(time.start_time, "HH:mm:ss")}</Moment> - <Moment format='LT'>{moment(time.end_time, "HH:mm:ss")}</Moment></Typography>
                                                    ))}                                        
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                ))
                            )}                        
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    {editing ? (
                        <>
                            <Button color="primary" onClick={this.onSave}>Save</Button>
                            <Button onClick={this.onCancel}>Cancel</Button>
                        </>
                    ) : (
                        <Button color="primary" onClick={this.onModify}>Modify</Button>
                    )}
                </CardActions>
            </Card>
        )
    }

    public onDeleteTime = (time: DeletableTime) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: snapshot.map((snapTime) => {
                if (time !== snapTime) {
                    return snapTime;
                }
                return {
                    ...snapTime,
                    deleted: true
                };
            })
        });
    }

    public onUpdateStartTime = (time: DeletableTime, value: string) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: snapshot.map((snapTime) => {
                if (time !== snapTime) {
                    return snapTime;
                }
                return {
                    ...snapTime,
                    start_time: value
                };
            })
        });
    }
    
    public onUpdateEndTime = (time: DeletableTime, value: string) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: snapshot.map((snapTime) => {
                if (time !== snapTime) {
                    return snapTime;
                }
                return {
                    ...snapTime,
                    end_time: value
                };
            })
        });
    }

    public onAddTime = (day: string) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: [
                ...snapshot,
                {
                    day_of_week: daysOfWeek.indexOf(day),
                    start_time: '09:00',
                    end_time: '17:00',
                    deleted: false
                }
            ]
        });
    }
    
    public onModify = () => {
        const { times } = this.props;
        
        const snapshot = times.map((time) => ({
            ...time,
            start_time: moment(time.start_time, "HH:mm:ss").format('HH:mm'),
            end_time: moment(time.end_time, "HH:mm:ss").format('HH:mm'),
            deleted: false
        } as DeletableTime));

        this.setState({editing: true, snapshot});
    };

    public onSave = () => {
        const { dispatch } = this.props;
        const { snapshot } = this.state;
        
        const utcSnapshot = snapshot.map((time) => ({
            ...time,
            start_time: moment(time.start_time, "HH:mm:ss").format('HH:mm'),
            end_time: moment(time.end_time, "HH:mm:ss").format('HH:mm'),
        } as DeletableTime));
        
        // dispatch(lsvActions.update(lsv.id, snapshot));
        utcSnapshot.forEach((time) => {
            if (time.id) {
                if (time.deleted) {
                    dispatch(availableTimeActions.delete(time.id)).catch(console.error);
                }
                else {
                    dispatch(availableTimeActions.update(time.id, time as AvailableTime)).catch(console.error);
                }
            }
            else {
                dispatch(availableTimeActions.create(undefined, time as AvailableTime)).catch(console.error);
            }
        });

        this.setState({editing: false});
    }

    public onCancel = () => {
        this.setState({editing: false});
    }
}

export default connect()(withTheme(withStyles(styles)(AvailabilityHoursCard)));