import { reducer as session } from 'redux-simple-auth'

const initialState = {
    authenticator: null,
    hasFailedAuth: false,
    isAuthenticated: false,
    isRestored: false,
    lastError: null,
    data: {}
}

const reducer = (state = initialState, action: any) => {
    const newState = session(state, action);
    // Catch unauthorized calls and log out
    if (action.response && action.response.status === 401) {
        return {
            ...newState,
            authenticator: null,
            isAuthenticated: false,
            isRestored: true,
            lastError: null,
            data: {}
        }
    }
    return newState;
};

export default reducer