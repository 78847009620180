import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import FileField from '../file/Field';
import { IReturn as StatusCode, actions as statusCodeActions, RecipientOptions } from '../../api/resources/StatusCode';

const styles = (theme: Theme) =>
    createStyles({
        formControl: {
            // margin: theme.spacing(1),
            width: '100%',
        } 
    });

type PartialStatusCode = Partial<StatusCode>;

interface IState {
    statusCode?: PartialStatusCode;
}

interface IProps {
    statusCode?: StatusCode;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class StatusCodeAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        statusCode: {} as PartialStatusCode,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            const { statusCode } = this.props;
        
            this.setState({
                statusCode: statusCode || {} as PartialStatusCode
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open, classes } = this.props;
        const { statusCode } = this.state;

        const saveable = statusCode.description && statusCode.slug && statusCode.template;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{statusCode?.id ? (<>Edit Status Code</>) : (<>Add Status Code</>)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {statusCode?.id ? (
                            <>
                                Edit status code details below.
                            </>
                        ) : (
                            <>
                                Enter new status code details below.
                            </>
                        )}                        
                    </DialogContentText>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="recipient-label">Recipient</InputLabel>
                        <Select
                            labelId="recipient-label"
                            id="recipient-select"
                            value={statusCode.recipient}
                            onChange={(evt) => {
                                const statusCode = {
                                    ...this.state.statusCode,
                                    recipient: evt.target.value as "LOC"|"VEN"
                                };
                                this.setState({statusCode});
                            }}
                        >
                            <MenuItem value={RecipientOptions.Location}>Work Location</MenuItem>
                            <MenuItem value={RecipientOptions.Vendor}>Vendor</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Code Description"
                        fullWidth
                        value={statusCode.description}
                        onChange={(evt) => {
                            const statusCode = {
                                ...this.state.statusCode,
                                description: evt.target.value
                            };
                            this.setState({statusCode});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="slug"
                        label="Code Slug"
                        fullWidth
                        value={statusCode.slug}
                        onChange={(evt) => {
                            const statusCode = {
                                ...this.state.statusCode,
                                slug: evt.target.value
                            };
                            this.setState({statusCode});
                        }}
                    />
                    
                    {/* <TextField
                        margin="dense"
                        id="file"
                        label="Template File"
                        fullWidth
                        value={statusCode.template_url}
                        onChange={(evt) => {
                            const statusCode = {
                                ...this.state.statusCode,
                                template_file: evt.target.value
                            };
                            this.setState({statusCode});
                        }}
                    /> */}
                    <FileField
                        label='Template'
                        value={statusCode.template}
                        onChange={(value: string) => {
                            const statusCode = {
                                ...this.state.statusCode,
                                template: value
                            };
                            this.setState({statusCode});
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!saveable} onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { dispatch } = this.props;
        const { statusCode } = this.state;
        if (statusCode?.id) {
            await dispatch(statusCodeActions.update(statusCode.id, statusCode)).catch(console.error);
        } 
        else {
            await dispatch(statusCodeActions.create(undefined, statusCode)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(StatusCodeAddEditModal));