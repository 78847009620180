import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    Paper,
    Chip
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { actions as applicantActions, IReturn as Applicant } from '../../../api/resources/Applicant';
import { actions as statusCodeActions, IReturn as StatusCode } from '../../../api/resources/StatusCode';
import { ApplicationState } from '../../../reducers';
import UpdateModal from './UpdateModal';
import ResendModal from './ResendModal';
import IllegibleModal from './IllegibleModal';

const styles = (theme: Theme) =>
    createStyles({
        centeredPaperContent: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        centeredAction: {
            margin: theme.spacing(1),
        },
        paperTitle: {
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        itemPaper: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
    });

interface IProps {
    applicant: Applicant;
    statusCode?: StatusCode;
};

interface IState {
    showUpdateModal: boolean;
    showResendModal: boolean;
    showIllegibleModal: boolean;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { applicant } = ownProps;
    
    return {
        statusCode: applicant ? state.statuscode.data[applicant.status_code] : undefined,
    };
};

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ApplicantStatusCard extends React.PureComponent<FullProps, IState> {
    public state = {
        showUpdateModal: false,
        showResendModal: false,
        showIllegibleModal: false,
    }
    
    public componentDidMount() {
        this.loadDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { applicant } = this.props;
        if (prevProps.applicant != applicant)
            this.loadDependencies();
    }

    public async loadDependencies() {
        const { applicant, dispatch } = this.props;
        dispatch(statusCodeActions.cacheread({}, applicant.status_code)).catch(console.error);
    }

    render() {
        const { applicant, classes, statusCode } = this.props;
        const { showUpdateModal, showIllegibleModal, showResendModal } = this.state;

        return (
            <Paper className={classes.itemPaper}>
                <Typography variant="subtitle1" className={classes.paperTitle}>
                    Hire Status
                </Typography>
                <div className={classes.centeredPaperContent}>
                    <Chip color={applicant.status_code ? 'secondary' : 'default'} label={statusCode ? statusCode.description : 'none'} />
                    <Button onClick={this.onUpdateClick} color="primary" fullWidth={true} variant="contained" className={classes.centeredAction}>Update</Button>
                    <Button onClick={this.onIllegibleClick} fullWidth={true} variant="outlined" className={classes.centeredAction}>Illegible</Button>
                    <Button onClick={this.onResendClick} fullWidth={true} variant="outlined" className={classes.centeredAction}>Re-send</Button>
                </div>
                <UpdateModal open={showUpdateModal} onDismiss={this.onUpdateDismiss} />
                <IllegibleModal open={showIllegibleModal} onDismiss={this.onIllegibleDismiss} />
                <ResendModal open={showResendModal} onDismiss={this.onResendDismiss} />
            </Paper>
        )
    }

    onUpdateClick = () => {
        this.setState({showUpdateModal: true});
    }

    onUpdateDismiss = (code: number | null) => {
        if (code !== null) {
            const { applicant, dispatch } = this.props;
            dispatch(applicantActions.update(applicant.id, {status_code: code})).catch(console.error);
        }

        this.setState({showUpdateModal: false});
    }

    onIllegibleClick = () => {
        this.setState({showIllegibleModal: true});
    }

    onIllegibleDismiss = (disposition: boolean, tcr?: string) => {
        if (disposition) {
            const { applicant, dispatch } = this.props;
            dispatch(applicantActions.illegible(applicant.id, {tcr})).catch(console.error);
        }
        
        this.setState({showIllegibleModal: false});
    }

    onResendClick = () => {
        this.setState({showResendModal: true});
    }

    onResendDismiss = (disposition: boolean) => {
        if (disposition) {
            const { applicant, dispatch } = this.props;
            dispatch(applicantActions.resend(applicant.id, {})).catch(console.error);
        }
        
        this.setState({showResendModal: false});
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(ApplicantStatusCard)));