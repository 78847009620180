import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
	palette: {
		primary: {
			main: '#1A478F',
		},
		secondary: {
			main: '#B21F33',
		},
		text: {
			primary: '#47596F',
		},
		// background: {
		// 	default: '#1A478F',
		// }
	},
});

export const anonymousTheme = createMuiTheme({
	palette: {
		// type: 'dark',
		background: {
			default: '#1A478F',
			// paper: '#fff',
		}
	}
});