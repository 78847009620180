import { ApplicationState } from '../reducers';

export const getSessionRole = (state: ApplicationState) => {
    try {
        if (!state.session || 
            !state.session.data || 
            !state.session.data.token)
            return null;
        const components = state.session.data.token.split('.');
        const sessionData = JSON.parse(atob(components[1]));
        return sessionData.user_role;
    }
    catch {
        return null;
    }
};

export const isUserAdmin = (state: ApplicationState) => {
    return getSessionRole(state) === 'admin';
}

export const isUserOwner = (state: ApplicationState) => {
    return getSessionRole(state) === 'owner';
}

export const isUserNormal = (state: ApplicationState) => {
    return getSessionRole(state) === 'user';
}

export const getSessionContextType = (state: ApplicationState) => {
    try {
        if (!state.session || 
            !state.session.data || 
            !state.session.data.token)
            return null;
        const components = state.session.data.token.split('.');
        const sessionData = JSON.parse(atob(components[1]));
        return sessionData.context_type;
    }
    catch {
        return null;
    }
};

export const isContextLSV = (state: ApplicationState) => {
    return getSessionContextType(state) === 'livescanvendor';
}

export const isContextEmployer = (state: ApplicationState) => {
    return getSessionContextType(state) === 'employer';
}

export const isContextNetwork = (state: ApplicationState) => {
    return getSessionContextType(state) === 'network';
}

export const getSessionContextID = (state: ApplicationState) => {
    try {
        if (!state.session || 
            !state.session.data || 
            !state.session.data.token)
            return null;
        const components = state.session.data.token.split('.');
        const sessionData = JSON.parse(atob(components[1]));
        return sessionData.context_id;
    }
    catch {
        return null;
    }
};

export const getLSVContextID = (state: ApplicationState) => {
    return isContextLSV(state) ? getSessionContextID(state) : null;
}

export const getNetworkContextID = (state: ApplicationState) => {
    return isContextNetwork(state) ? getSessionContextID(state) : null;
}

export const getSessionUserID = (state: ApplicationState) => {
    try {
        if (!state.session || 
            !state.session.data || 
            !state.session.data.token)
            return null;
        const components = state.session.data.token.split('.');
        const sessionData = JSON.parse(atob(components[1]));
        return sessionData.user_id;
    }
    catch {
        return null;
    }
};

export const getSessionEmail = (state: ApplicationState) => {
    try {
        if (!state.session || 
            !state.session.data || 
            !state.session.data.token)
            return null;
        const components = state.session.data.token.split('.');
        const sessionData = JSON.parse(atob(components[1]));
        return sessionData.user_email;
    }
    catch {
        return null;
    }
};