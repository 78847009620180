import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import { IReturn as StatusCode, actions as statusCodeActions } from '../../../api/resources/StatusCode';
import { ApplicationState } from '../../../reducers';

const styles = (theme: Theme) =>
    createStyles({
        locationList: {

        }
    });

interface IProps {
    statusCodes?: StatusCode[];
    onDismiss: (disposition: number | null) => void;
    open: boolean;
};

interface IState {
    status: number | null;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    return {
        locations: Object.values(state.location.data),
        statusCodes: Object.values(state.statuscode.data),
    };
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class ApplicantLocationAddModal extends React.PureComponent<FullProps, IState> {
    public state = {
        status: null,
    };
    
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(statusCodeActions.index(undefined, undefined, {})).catch(console.error);
    }
    
    render() {
        const { classes, statusCodes, open } = this.props;
        const { status } = this.state;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Applicant Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the desired status from the list below.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        {/* <FormLabel component="legend">Status</FormLabel> */}
                        <RadioGroup aria-label="status" name="status1" value={status} onChange={this.handleChange}>
                            {statusCodes?.map(code => (
                                <FormControlLabel value={code.id} control={<Radio />} label={code.description} />
                            ))} 
                        </RadioGroup>
                    </FormControl>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({status: parseInt(event.target.value)});
    };

    private handleCancel = () => {
        this.props.onDismiss(null);
    }

    private handleSave = () => {
        const { status } = this.state;
        this.props.onDismiss(status);
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ApplicantLocationAddModal));