import { CssBaseline, Theme, Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles, ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { anonymousTheme } from '../theme';
import SubtleFooter from './SubtleFooter';

const styles = (theme: Theme) =>
	createStyles({
		main: {
			display: 'block', // Fix IE 11 issue.
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			[theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
				marginLeft: 'auto',
				marginRight: 'auto',
				width: 800,
			},
			width: 'auto',
			minHeight: 'calc(100vh - 350px)',
		},
		logo: {
			display: 'block',
			height: '150px',
			marginBottom: theme.spacing(2),
			marginLeft: 'auto',
			marginRight: 'auto',
            marginTop: theme.spacing(8),
			textAlign: 'center',
			color: '#f8f8f8',
		},
		subtitle: {
			display: 'block',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginBottom: theme.spacing(2),
            textAlign: 'center',
			color: '#f8f8f8',
		}
	});

interface IProps {
	topContent?: React.ReactNode;
	title?: string;
	subtitle?: string;
	logo?: string;
}

class AnonLayout extends React.PureComponent<IProps & WithStyles<typeof styles>> {
    render() {
        const { children, classes, title, logo, subtitle, topContent } = this.props;

        return (
            <ThemeProvider theme={anonymousTheme}>
				{topContent}
				<main className={classes.main}>
					<CssBaseline />
					{logo ? (
						<>
							<img src={logo} className={classes.logo} />
							{title && (
								<Typography className={classes.subtitle} variant="h4">{title}</Typography>
							)}
						</>
					) : (
						<>
							{title && (
								<Typography className={classes.logo} component="h1" variant="h2">{title}</Typography>
							)}
							{subtitle && (
								<Typography className={classes.subtitle} variant="h4">{subtitle}</Typography>
							)}
						</>
					)}
					{children}
				</main>
				<SubtleFooter title='Cryms' description='Streamlined employment application background check workflows' />
			</ThemeProvider>
        )
    }
}

export default withStyles(styles)(AnonLayout);