import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    Paper,
    List
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as Applicant, actions as applicantActions } from '../../../api/resources/Applicant';
import { IReturn as Location, actions as locationActions } from '../../../api/resources/Location';
import ApplicantLocationItem from './Item';
import { ApplicationState } from '../../../reducers';
import LocationAddModal from './AddModal';
import EmptyState from '../../EmptyState';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        itemPaper: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        metaData: {
            marginTop: 12,
        },  
        paperActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        paperTitle: {
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        spacer: {
            flex: 1,
        }
    });

interface IProps {
    applicant: Applicant;
    locations?: Location[];
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
    showAddModal: boolean;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { applicant } = ownProps;
  
    return {
        locations: applicant ? Object.values(state.location.data).filter((item)=>applicant.locations.indexOf(item.id)>=0) : []
    };
  };

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ApplicantLocationsCard extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
        showAddModal: false,
    }

    public componentDidMount() {
        this.loadLocations();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { applicant } = this.props;

        if (prevProps.applicant != applicant)
            this.loadLocations();
    }

    public async loadLocations() {
        const { applicant, dispatch } = this.props;

        if (applicant)
            applicant.locations.forEach((id) => dispatch(locationActions.cacheread({}, id)).catch(console.error));
    }
    
    render() {
        const { applicant, classes, locations } = this.props;
        const { anchorEl, expanded, showAddModal } = this.state;

        const locationsMarkup = locations ? locations.map((entity, index) => (
            <ApplicantLocationItem index={index} entity={entity} key={index} onDelete={this.deleteLocation} />
        )) : null;      

        return (
            <>
                <Paper className={classes.itemPaper}>
                    <Typography variant="subtitle1" className={classes.paperTitle}>
                        Locations
                    </Typography>
                    
                    {locationsMarkup && locationsMarkup.length > 0 ? (
                        <List>{locationsMarkup}</List>
                    ) : (
                        <EmptyState icon='error' text='None to display' />
                    )}
                    <div className={classes.spacer}></div>
                    <div className={classes.paperActions}>
                        <Button color="primary" onClick={this.onAddClick}>Add</Button>
                    </div>
                </Paper>
                <LocationAddModal open={showAddModal} onDismiss={this.onAddDismiss} />
            </>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }

    private onAddClick = () => {
        this.setState({showAddModal: true});
    }
    
    private onAddDismiss = (locations: number[]) => {
        if (locations.length > 0) {
            const { applicant, dispatch } = this.props;
            const combinedIDs = [...applicant.locations, ...locations];
            dispatch(applicantActions.update(applicant.id, {locations: combinedIDs}));
        }
        this.setState({showAddModal: false});
    }

    private deleteLocation = (location: Location) => {
        const { applicant, dispatch, locations } = this.props;
        if (!locations) {
            return;
        }
        const filteredLocations = locations.filter((locationItem) => locationItem.id !== location.id);
        const locationIDs = filteredLocations.map((locationItem) => locationItem.id);
        dispatch(applicantActions.update(applicant.id, {locations: locationIDs}))
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(ApplicantLocationsCard)));