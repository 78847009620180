import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import WaiverPreviewModal from './PreviewModal';
import { IReturn as WaiverSignature } from '../../api/resources/WaiverSignature';
import Moment from 'react-moment';

const styles = (theme: Theme) => createStyles({});

interface IProps {
    signature: WaiverSignature;
};

interface IState {
    showPreviewModal: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class WaiverRow extends React.PureComponent<FullProps, IState> {
    public state = {
        showPreviewModal: false,
    }
    
    render() {
        const { signature } = this.props;
        const { showPreviewModal } = this.state;

        return (
            <React.Fragment>
                <ListItem button onClick={this.handleItemClick}>
                    <ListItemIcon>
                        <AssignmentTurnedInIcon />
                    </ListItemIcon>
                    <ListItemText primary={(
                        <>Waiver signed <Moment format="ll">{signature.created}</Moment></>
                    )} />
                </ListItem>
                <WaiverPreviewModal open={showPreviewModal} signature={signature} onDismiss={this.handlePreviewDismiss} />
            </React.Fragment>
        )
    }

    private handleItemClick = () => {
        this.setState({
            showPreviewModal: true
        });
    }

    private handlePreviewDismiss = (shouldRefresh: boolean) => {
        this.setState({showPreviewModal: false});
    }
}

export default withTheme(withStyles(styles)(WaiverRow));