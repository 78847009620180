import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as LivescanVendor, actions as livescanVendorActions } from '../../api/resources/LivescanVendor';

const styles = (theme: Theme) =>
    createStyles({
        
    });

type PartialLivescanVendor = Partial<LivescanVendor>;

interface IState {
    lsv?: PartialLivescanVendor;
}

interface IProps {
    lsv?: LivescanVendor;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class LSVAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        lsv: {} as PartialLivescanVendor,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            const { lsv } = this.props;
        
            this.setState({
                lsv: lsv || {} as PartialLivescanVendor
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open } = this.props;
        const { lsv } = this.state;

        const canSave = (
            lsv.contact_email && 
            lsv.contact_first_name && 
            lsv.contact_last_name && 
            lsv.contact_phone &&
            lsv.location_city &&
            lsv.location_postal &&
            lsv.location_state &&
            lsv.location_street1 &&
            lsv.name &&
            lsv.url
        );
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{lsv?.id ? (<>Edit Location</>) : (<>Add Location</>)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {lsv?.id ? (
                            <>
                                Edit location details below.
                            </>
                        ) : (
                            <>
                                Enter new location details below.
                            </>
                        )}                        
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Location Name"
                        fullWidth
                        value={lsv.name}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                name: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="street1"
                        label="Street Address"
                        fullWidth
                        value={lsv.location_street1}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                location_street1: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="street2"
                        label="Suite / PO"
                        fullWidth
                        value={lsv.location_street2}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                location_street2: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="city"
                        label="City"
                        fullWidth
                        value={lsv.location_city}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                location_city: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="state"
                        label="State"
                        fullWidth
                        value={lsv.location_state}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                location_state: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="zip_code"
                        label="Zip"
                        fullWidth
                        value={lsv.location_postal}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                location_postal: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="contact_email"
                        label="Email"
                        fullWidth
                        type="email"
                        value={lsv.contact_email}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                contact_email: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="contact_first"
                        label="Contact First Name"
                        fullWidth
                        value={lsv.contact_first_name}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                contact_first_name: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="contact_last"
                        label="Contact Last Name"
                        fullWidth
                        value={lsv.contact_last_name}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                contact_last_name: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="contact_phone"
                        label="Contact Phone"
                        fullWidth
                        type="phone"
                        value={lsv.contact_phone}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                contact_phone: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="url"
                        label="URL"
                        fullWidth
                        value={lsv.url}
                        onChange={(evt) => {
                            const lsv = {
                                ...this.state.lsv,
                                url: evt.target.value
                            };
                            this.setState({lsv});
                        }}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!canSave} onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { dispatch } = this.props;
        const { lsv } = this.state;
        if (lsv?.id) {
            await dispatch(livescanVendorActions.update(lsv.id, lsv)).catch(console.error);
        } 
        else {
            const hydrated = {
                ...lsv,
                location_email: lsv.contact_email,
                location_name: lsv.name,
                location_phone: lsv.contact_phone,
                // TODO: Fix this hack
                logo: "/",
                office_city: lsv.location_city,
                office_email: lsv.contact_email,
                office_postal: lsv.location_postal,
                office_phone: lsv.contact_phone,
                office_state: lsv.location_state,
                office_street1: lsv.location_street1,
                // TODO: Fix this hack
                timezone: "America/New_York",
            };
            await dispatch(livescanVendorActions.create(undefined, hydrated)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(LSVAddEditModal));