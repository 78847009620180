import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles
} from '@material-ui/core';
import { IReturn as Applicant } from '../../api/resources/Applicant';
import { IReturn as WaiverSignature } from '../../api/resources/WaiverSignature';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';


const styles = (theme: Theme) =>
    createStyles({});

interface IProps {
    applicant?: Applicant;
    signature: WaiverSignature;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class WaiverPreviewModal extends React.Component<FullProps> {
    render() {
        const { signature, open } = this.props;
    
        return (
            <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogTitle id="form-dialog-title">Waiver Preview</DialogTitle>
                
                <DialogContent>
                    <Document file={signature.signature_url} >
                        <Page pageIndex={0} />
                    </Document>
                </DialogContent>
                <DialogActions>
                    <Button href={signature.signature_url} target="_blank">Download</Button>
                    <Button onClick={this.handleClose} color="primary">Close</Button>
                </DialogActions>
            
            </Dialog>
        )
    }

    private handleClose = () => {
        this.props.onDismiss(false);
    }
}

export default connect()(withStyles(styles)(WaiverPreviewModal));