import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as User, actions as userActions } from '../../api/resources/User';

const styles = (theme: Theme) =>
    createStyles({
        
    });

interface IState {
    password: string;
}

interface IProps {
    user: User;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class UserSetPasswordModal extends React.Component<FullProps, IState> {
    public state = {
        password: ''
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            this.setState({
                password: ''
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open } = this.props;
        const { password } = this.state;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter a new password for this user below.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="User Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(evt) => {
                            this.setState({password: evt.target.value});
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { dispatch, user } = this.props;
        const { password } = this.state;
        await dispatch(userActions.set_password(user.id, {password})).catch(console.error);
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(UserSetPasswordModal));