import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles, 
} from '@material-ui/core';
import { IReturn as Applicant } from '../../api/resources/Applicant';
import { IReturn as Address, actions as addressActions } from '../../api/resources/Address';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';


const styles = (theme: Theme) =>
    createStyles({});

interface IProps {
    addressType?: "HOME"|"WORK";
    applicant?: Applicant;
    address?: Address;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

const FormSchema = Yup.object().shape({
    city: Yup.string().required(),
    state: Yup.string().required(),
    street1: Yup.string().required(),
    zip_code: Yup.string().required(),
});

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class AddressAddEditModal extends React.Component<FullProps> {
    render() {
        const { address, addressType, open } = this.props;
        const typeDescription = addressType === 'WORK' ? 'work' : 'home';
    
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{address?.id ? (<>Edit {typeDescription} address</>) : (<>Add {typeDescription} address</>)}</DialogTitle>
                <Formik
                    initialValues={{
                        city: address ? address.city : '',
                        state: address ? address.state : '',
                        street1: address ? address.street1 : '',
                        street2: address ? address.street2 : '',
                        zip_code: address ? address.zip_code : '',
                    }}
                    validationSchema={FormSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        this.handleSave(values);
                    }}
                >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            <DialogContentText>
                                {address?.id ? (
                                    <>Edit {typeDescription} address below.</>
                                ) : (
                                    <>Add new {typeDescription} address below.</>
                                )}                        
                            </DialogContentText>
                            <Field component={TextField} label="Street Address" name="street1" fullWidth required autofocus />
                            <Field component={TextField} label="Street Address 2" name="street2" fullWidth />
                            <Field component={TextField} label="City" name="city" fullWidth required />
                            <Field component={TextField} label="State" name="state" fullWidth required />
                            <Field component={TextField} label="Zip code" name="zip_code" fullWidth required />
                            
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancel} color="primary">Cancel</Button>
                            <Button onClick={submitForm} color="primary">Save</Button>
                        </DialogActions>
                    </Form>
                )}
                </Formik>
                
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = (values: Partial<Address>) => {
        this.saveAndDismiss(values);
    }

    private async saveAndDismiss(values: Partial<Address>) {
        const { dispatch } = this.props;
        const { address } = this.props;
        if (address?.id) {
            const hydratedModel = {
                ...address,
                ...values
            };
            await dispatch(addressActions.update(address.id, hydratedModel)).catch(console.error);
        } 
        else {
            const { addressType, applicant } = this.props;
            const hydratedModel = {
                address_type: addressType,
                applicant: applicant?.id,
                ...values
            };
            await dispatch(addressActions.create(undefined, hydratedModel)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(AddressAddEditModal));