import { Resource, CombineResource, IStateBucket, Call } from "restdux";
import { getAPIUrl, getHeaders, transformIndex, getCallAPIUrlFunction } from "../global/globals";

export interface IReturn {
	applicant_instructions: string;
	appointment_duration: string;
	brand_primary_color: string;
	brand_secondary_color: string;
	contact_email: string;
	contact_first_name: string;
	contact_last_name: string;
	contact_phone: string;
	location_city: string;
	location_email: string;
	location_name: string;
	location_postal: string;
	location_phone: string;
	location_state: string;
	location_street1: string;
	location_street2: string;
	id: number;
	is_archived: boolean;
	logo: string;
	name: string;
	office_city: string;
	office_email: string;
	office_postal: string;
	office_phone: string;
	office_state: string;
	office_street1: string;
	office_street2: string;
	public: boolean;
	timezone: string;
	url: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

export interface IUserReturn {
	id: number;
	email: string;
	password?: string;
};

export type IUserSend = Partial<IUserReturn>;

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "livescanvendor",
	rootUrl: getAPIUrl("/livescanvendors"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {
	addUser: Call<{}, IUserSend, IUserReturn[], IStateBucket<IUserReturn[]>>({
		headers: getHeaders,
		method: "PATCH",
		url: getCallAPIUrlFunction("/livescanvendors", "add_user"),
	}),
	removeUser: Call<{}, IUserSend, IUserReturn[], IStateBucket<IUserReturn[]>>({
		headers: getHeaders,
		method: "PATCH",
		url: getCallAPIUrlFunction("/livescanvendors", "remove_user"),
	}),
	users: Call<{},{}, IUserReturn[], IStateBucket<IUserReturn[]>>({
		headers: getHeaders,
		method: "GET",
		url: getCallAPIUrlFunction("/livescanvendors", "users"),
	}),
});