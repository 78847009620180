import React from 'react';
import { connect } from 'react-redux';
import { 
  Button,
  Card, 
  CardContent, 
  List, 
  ListItem, 
  ListItemSecondaryAction,
  ListItemText, 
  Typography 
} from '@material-ui/core';
import '../../../styles/App.css';
import MainLayout from '../../../components/MainLayout';
import LoadIndicator from '../../../components/LoadIndicator';
import RoleGate from '../../../components/RoleGate';
import AccountPasswordModal from '../../../components/account/password/Modal';
import AccountUsernameModal from '../../../components/account/username/Modal';
import { ApplicationState } from '../../../reducers';
import { getSessionEmail } from '../../../selectors';

interface IProps {
	sessionEmail: string;
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		sessionEmail: getSessionEmail(state),
	};
};

class NetworkAccount extends React.PureComponent<IProps> {
  state = {
    isLoading: false,
    showModifyUsername: false,
    showModifyPassword: false,
  }
  
  render() {
    const { sessionEmail } = this.props;
    const { isLoading, showModifyPassword, showModifyUsername } = this.state;
    
    return (
      <MainLayout title='Manage Account' behavior='network' waiting={isLoading}>
        <RoleGate role='admin' />
        {isLoading ? (
          <LoadIndicator />
        ) : (
          <Card>            
            <CardContent>
              <Typography variant="h5" component="h2">
                {'Your Account'}
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Login Email" secondary={sessionEmail} />
                  {/* <ListItemSecondaryAction>
                    <Button aria-label="modify" onClick={this.handleModifyUsername}>
                      {'Modify'}
                    </Button>
                  </ListItemSecondaryAction> */}
                </ListItem>
                <ListItem>
                  <ListItemText primary="Password" secondary="********" />
                  <ListItemSecondaryAction>
                    <Button aria-label="modify" onClick={this.handleModifyPassword}>
                      {'Modify'}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>                  
              </List>
            </CardContent>
          </Card>
        )}
        <AccountUsernameModal open={showModifyUsername} onDismiss={this.handleDismissUsername} />
        <AccountPasswordModal open={showModifyPassword} onDismiss={this.handleDismissPassword} />
      </MainLayout>
    );
  }

  private handleModifyUsername = () => {
    this.setState({showModifyUsername: true});
  }

  private handleDismissUsername = (shouldRefresh: boolean) => {
    this.setState({showModifyUsername: false});
  }
  
  private handleModifyPassword = () => {
    this.setState({showModifyPassword: true});
  }

  private handleDismissPassword = (shouldRefresh: boolean) => {
    this.setState({showModifyPassword: false});
  }
}

export default connect(mapStateToProps)(NetworkAccount);