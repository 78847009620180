import React from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        
    });

interface IProps {
    notes: string;
    onDismiss: (notes: string|undefined) => void;
    open: boolean;
};

interface IState {
    notes: string;
}

type FullProps = IProps & WithStyles<typeof styles>;
class AppointmentNotesModal extends React.PureComponent<FullProps, IState> {
    state = {
        notes: ''
    };
    
    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            const { notes } = this.props;
        
            this.setState({
                notes
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open } = this.props;
        const { notes } = this.state;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth>
                <DialogTitle id="form-dialog-title">Appointment Notes</DialogTitle>
                <DialogContent>
                    <TextField id="notes" multiline rows={4} label="Notes" variant="filled" autoFocus fullWidth value={notes} onChange={(evt) => this.setState({notes: evt.target.value})} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(undefined);
    }

    private handleSave = () => {
        const { notes } = this.state;
        this.props.onDismiss(notes);
    }
}

export default connect()(withStyles(styles)(AppointmentNotesModal));