import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

type FooterProps = {
  description: string,
  title: string
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      &copy; {new Date().getFullYear() } {' '} Biometric Information Management <br />
      <Link color="inherit" href="https://www.bioinfomgt.com/" target="_blank">
        www.bioinfomgt.com
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(6, 0),
  },
  logo: {
    display: 'block',
    height: '75px',
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
}));

export default function Footer(props: FooterProps) {
  const classes = useStyles();
  const { description, title } = props;

  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <img src={"/icon-color.svg"} className={classes.logo} alt={'BIM Mark'} />
        <Copyright />
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};