import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import { 
    createAuthMiddleware, 
    createCredentialsAuthenticator,
    createLocalStorageStore,
    getInitialAuthState,
//    oauth2BearerAuthorizer,
//    refresher,
    storage // or custom storage creator 
} from 'redux-simple-auth';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { Config } from './api/global/globals';

const credentialsAuthenticator = createCredentialsAuthenticator({
    endpoint: Config.API_HOST ? `${Config.API_HOST}/auth/token/` : '/auth/token/'
});

const localStorageStore = createLocalStorageStore();

const bearerAuthorizer = (data: any, block: any) => {
    if (data.token) {
        block('Authorization', `Bearer ${data.token}`)
    }
}

// Authenticators and configuration options are discussed below
const authMiddleware = createAuthMiddleware({ 
    authenticator: credentialsAuthenticator,
    authorize: bearerAuthorizer,
    storage: localStorageStore,
    // refresh: refresher
});

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducer, /* preloadedState, */ composeEnhancers(

const store = createStore(
    rootReducer,
    /* initialState, */
    composeEnhancers(
        applyMiddleware(authMiddleware, thunk),
        getInitialAuthState({ storage })
    )
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
