import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    Paper,
    Chip,
    IconButton,
    InputLabel,
    Link
} from '@material-ui/core';
import {
    Delete
} from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { actions as fileActions, IReturn as File, IUploadable } from '../../api/resources/File';
import { ApplicationState } from '../../reducers';
import ReactS3Uploader from 'react-s3-uploader';

const styles = (theme: Theme) =>
    createStyles({
        centeredPaperContent: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        centeredAction: {
            margin: theme.spacing(1),
        },
        paperTitle: {
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        fieldContainer: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        fileName: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1
        }
    });

interface IProps {
    disabled?: boolean;
    label?: string;
    value?: string;
    onChange: (value: string) => void;
};

interface IState {
    file?: File;
    started: boolean;
    finished: boolean;
    progress: number;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class FileField extends React.PureComponent<FullProps, IState> {
    public state = {
        file: undefined as File|undefined,
        started: false,
        finished: false,
        progress: 0,
    }
    
    public componentDidMount() {
        this.loadDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { value } = this.props;
        if (prevProps.value != value)
            this.loadDependencies();
    }

    public async loadDependencies() {
        const { value, dispatch } = this.props;
        if (value) {
            const response = await dispatch(fileActions.cacheread({}, value)).catch(console.error);
            if (!response)
                return;
            this.setState({file: response.result});
        }            
    }

    render() {
        const { classes, disabled, label, value, onChange } = this.props;
        const { file, started, finished, progress } = this.state;

        return (
            <div className={classes.fieldContainer}>
                {value ? (
                    <>
                        <div className={classes.fileName}>
                            {label && (
                                <InputLabel htmlFor="filename" shrink>{label}</InputLabel>
                            )}                                
                            {file ? (
                                <Typography id="filename">
                                    <Link href={file.url} target="_blank">
                                        {file.original_name}
                                    </Link>                                    
                                </Typography>
                                
                            ) : (
                                <em>Loading...</em>
                            )}
                        </div>
                        <IconButton onClick={() => onChange('')}>
                            <Delete />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <div className={classes.fileName}>
                            {label && (
                                <InputLabel htmlFor="filename">{label}</InputLabel>
                            )}                                
                            {/* {file ? (
                                <Typography id="filename">{file.original_name}</Typography>
                            ) : (
                                <em>Loading...</em>
                            )} */}
                        </div>
                        <ReactS3Uploader
                            autoUpload={true}
                            // className={uploaderClassName}
                            getSignedUrl={this.generateUploadUrl}
                            accept="docx/*"
                            // preprocess={this.onUploadStart}
                            // onProgress={this.onProgress}
                            // onError={this.onError}
                            onFinish={this.onFinish}
                            scrubFilename={this.scrubFilename}
                            uploadRequestHeaders={{
                                // 'x-amz-acl': 'public-read'
                            }}
                            contentDisposition="auto"
                        />
                    </>
                )}
            </div>
        )
    }

    private generateUploadUrl = async (file: globalThis.File, callback: (params: {signedUrl: string;}) => any) => {
        const { dispatch } = this.props;

        const response = await dispatch(fileActions.upload(undefined, {
            original_name: file.name,
            mime_type: file.type
        })).catch(console.error);

        if (!response)
            return;
        
        this.setState({file: (response.result as unknown) as File});
        
        callback({signedUrl: ((response.result as unknown) as IUploadable).upload_url})
    }

    private scrubFilename = (filename: string) => {
        const { file } = this.state;
        if (!file)
            return filename;
        return file?.id;
    };

    private onFinish = () => {
        const { onChange } = this.props;
        const { file } = this.state;
        if (!file)
            return;
        onChange(file.id);
    }
}

export default connect()(withTheme(withStyles(styles)(FileField)));