import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getIsAuthenticated } from 'redux-simple-auth'
import { ApplicationState } from '../../reducers';
import '../../styles/App.css';

interface IProps {
  isAuthenticated: boolean,
};

const mapStateToProps = (state: ApplicationState) => ({
  isAuthenticated: getIsAuthenticated(state)
});

function NetworkDefault(props: IProps) {
  const { isAuthenticated } = props;
  return isAuthenticated ? (
    <Redirect to={'/dashboard'} />
  ) : (
    <Redirect to={'/login'} />
  );

  // return (
  //   <Redirect to={'/login'} />
  // );
}

export default connect(mapStateToProps)(NetworkDefault);