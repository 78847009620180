import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { IReturn as User, actions as userActions } from '../../../api/resources/User';
import { IReturn as LivescanVendor, IUserReturn as LivescanVendorUser, actions as livescanVendorActions } from '../../../api/resources/LivescanVendor';
import LivescanVendorUserItem from './Item';
import EmptyState from '../../EmptyState';
import { Add } from '@material-ui/icons';

const styles = (theme: Theme) =>
    createStyles({
        
    });

type PartialUser = Partial<LivescanVendorUser>;

interface IState {
    error: boolean;
    users: LivescanVendorUser[];
    showAddUser: boolean;
    newUser: PartialUser;
}

interface IProps {
    lsv?: LivescanVendor;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class UserAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        error: false,
        users: [] as LivescanVendorUser[],
        showAddUser: false,
        newUser: {} as PartialUser,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            this.loadDependencies();
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    private loadDependencies = async () => {
        const { dispatch, lsv } = this.props;

        if (!lsv) {
            return;
        }

        const results = await dispatch(livescanVendorActions.users(lsv.id, undefined, undefined)).catch(console.error);

        if (!results) {
            return;
        }

        const users = results.result as LivescanVendorUser[];

        this.setState({users});
    }

    render() {
        const { lsv, open } = this.props;
        const { error, newUser, users, showAddUser } = this.state;

        const canAddUser = newUser.email && newUser.password;

        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Manage Users for {lsv?.name}</DialogTitle>
                <DialogContent>
                    {showAddUser ? (
                        <>
                            {error && (
                                <Alert severity="error">
                                    We couldn't add this user. Are you sure they're not already affiliated with another entity?
                                </Alert>
                            )}
                            <DialogContentText>
                                Add a new user by providing details below.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="email"
                                label="User email"
                                fullWidth
                                value={newUser.email}
                                onChange={(evt) => {
                                    const newUser = {
                                        ...this.state.newUser,
                                        email: evt.target.value
                                    };
                                    this.setState({newUser});
                                }}
                                required
                            />
                            <TextField
                                margin="dense"
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                value={newUser.password}
                                onChange={(evt) => {
                                    const newUser = {
                                        ...this.state.newUser,
                                        password: evt.target.value
                                    };
                                    this.setState({newUser});
                                }}
                                required
                            />
                        </>
                    ) : (
                        <>
                            <DialogContentText>
                                {users && users.length ? (
                                    <>The users below have access to this LSV.</>
                                ) : (
                                    <>Begin adding users to this LSV below.</>
                                )}
                            </DialogContentText>
                            <List>
                                {users && users.length > 0 && users.map((user, index) => (
                                    <LivescanVendorUserItem userPerm={user} index={index} onDelete={this.handleRemoveClick} />
                                ))}
                                
                                <ListItem button onClick={this.handleAddClick}>
                                    <Add />
                                    <ListItemText primary={'Add new user'} />
                                </ListItem>
                            </List>
                        </>
                    )}
                    
                </DialogContent>
                <DialogActions>
                    {showAddUser ? (
                        <>
                            <Button onClick={this.handleAddDismiss} color="primary">
                                Cancel
                            </Button>
                            <Button disabled={!canAddUser} onClick={this.handleAddSave} color="primary">
                                Save
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button onClick={this.handleCancel} color="primary">
                                Close
                            </Button>
                            {/* <Button onClick={this.handleSave} color="primary">
                                Save
                            </Button> */}
                        </>
                    )}
                    
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleRemoveClick = async (user: PartialUser) => {
        const { dispatch, lsv } = this.props;
        
        const result = await dispatch(livescanVendorActions.removeUser(lsv?.id, user, {})).catch(console.error);

        if (!result) {
            return;
        }

        this.setState({users: result.result});
    }
    
    private handleAddClick = () => {
        this.setState({
            showAddUser: true,
            newUser: {} as PartialUser
        });
    }

    private handleAddDismiss = () => {
        this.setState({error: false, showAddUser: false});
    }
    
    private handleAddSave = async () => {
        const { dispatch, lsv } = this.props;
        const { newUser } = this.state;

        const result = await dispatch(livescanVendorActions.addUser(lsv?.id, newUser, {})).catch(console.error);

        if (!result) {
            this.setState({error: true});
            return;
        }

        this.setState({error: false, showAddUser: false, users: result.result});
    }
}

export default connect()(withStyles(styles)(UserAddEditModal));