import { Button, Grid, Theme, Paper, LinearProgress } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { ApplicationState } from '../../../reducers';
import { getAlreadyConfirmed } from '../../../selectors/wizard';
import { Formik, Form, Field } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';

interface IProps {
	dispatch: any,
	alreadyConfirmed?: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		alreadyConfirmed: getAlreadyConfirmed(state),
	}
}

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			// alignItems: 'center',
			// display: 'flex',
			// flexDirection: 'column',
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
			'&>div': {
				marginTop: theme.spacing(2),
			},
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		},
		formControl: {
			minWidth: 120,
			display: 'flex',
			flex: 1,
		},
	});

interface IForm {
	cardNumber?: string;
	cardExpiry?: string;
	cardName?: string;
	cardCode?: string;
	cardZip?: string;
};

const FormSchema = Yup.object().shape({
	cardNumber: Yup.string().min(16).max(16).required(),
	cardExpiry: Yup.string().min(4).max(4).required(),
	cardName: Yup.string().required(),
	cardCode: Yup.string().min(3).max(4).required(),
	cardZip: Yup.string().min(5).max(5).required(),
});

class SchedulerPayment extends React.PureComponent<WithStyles<typeof styles> & IProps & RouteComponentProps> {
  public componentDidMount() {
	const { alreadyConfirmed, history } = this.props;
	if (alreadyConfirmed) {
		history.push("/");
		return;
	}
  }
	
  render() {
    const { classes } = this.props;
	
	return (
      <AnonLayout title='New Application' subtitle='Please provide your payment info below'>
        <Paper className={classes.paper}>
			<Formik 
			  initialValues={{
				  cardNumber: '',
				  cardExpiry: '',
				  cardName: '',
				  cardCode: '',
				  cardZip: ''
			  }}
			  validationSchema={FormSchema}
			  onSubmit={(values, {setSubmitting}) => {
				this.onProceed(values);
			  }}
			>
				{({ submitForm, isSubmitting }) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item sm={6} xs={12}>
								<Field component={TextField} name="cardNumber" label="Card number" variant="filled" fullWidth required autoFocus />
							</Grid>
							<Grid item sm={6} xs={12}>
								<Field component={TextField} name="cardExpiry" label="Card expiration (MMYY)" variant="filled" fullWidth required />
							</Grid>
		
							<Grid item sm={6} xs={12}>
								<Field component={TextField} name="cardName" label="Cardholder name" variant="filled" fullWidth required />
							</Grid>
							<Grid item sm={6} xs={12}>
								<Field component={TextField} name="cardCode" label="Security code" variant="filled" fullWidth required />
							</Grid>

							<Grid item sm={6} xs={12}>
								<Field component={TextField} name="cardZip" label="Billing zip" variant="filled" fullWidth required />
							</Grid>
		
						</Grid>
						{isSubmitting && <LinearProgress />}
						<Grid container={true} className={classes.centeredButton} justify="center" spacing={2}>
							<Button disabled={isSubmitting} onClick={submitForm} variant="contained" color="primary">
								Continue
							</Button>
						</Grid>
					</Form>
					
				)}
			</Formik>
			
		</Paper>
      </AnonLayout>
    );
  }

  onProceed = (values: IForm) => {
	const { dispatch, history } = this.props;
	const {
		cardNumber,
		cardExpiry,
		cardName,
		cardCode,
		cardZip
	} = values;

	if (!(
		cardNumber &&
		cardExpiry &&
		cardName &&
		cardCode &&
		cardZip
	)) {
		return;
	}

	// TODO: reformat card expiry?

	dispatch(newWizardActions.setPayment(
		cardNumber,
		cardExpiry,
		cardName,
		cardCode,
		cardZip
	));

	history.push("/confirmation");
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerPayment)));