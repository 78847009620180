import { Button, Grid, Theme, Paper, FormControl, InputLabel, MenuItem, LinearProgress } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';
import PlacesOfBirth from '../../../constants/pob';
import Citizenship from '../../../constants/citizenship.json';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { ApplicationState } from '../../../reducers';
import { shouldDisplayWaiver, selectExtraFields } from '../../../selectors/environment';
import { shouldTakePayment } from '../../../selectors/wizard';
import RequiresActiveWizard from '../../../components/RequiresActiveWizard';


interface IProps {
	displayWaiver: boolean;
	shouldTakePayment?: boolean;
	extraFields: [string];
}

const mapStateToProps = (state: ApplicationState) => ({
	displayWaiver: shouldDisplayWaiver(state),
	shouldTakePayment: shouldTakePayment(state),
	extraFields: selectExtraFields(state),
});

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
			'&>div': {
				marginTop: theme.spacing(2),
			},
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		},
		formControl: {
			minWidth: 120,
			display: 'flex',
			flex: 1,
		},
		errorText: {
			color: "#f44336",
			marginLeft: 14,
    		marginRight: 14,
			marginTop: 3,
			fontSize: "0.75rem",
    		textAlign: "left",
    		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    		fontWeight: 400,
    		lineHeight: 1.66,
    		letterSpacing: "0.03333em",
		}
	});

interface IForm {
	firstName?: string;
	middleName?: string;
	lastName?: string;
	email?: string;
	phone?: string;
	dob?: string;
	ssn4?: string;
	gender?: string;
	race?: string;
	heightFeet?: string;
	heightInches?: string;
	weight?: string;
	eyeColor?: string;
	hairColor?: string;
	pob?: string;
	citizenship?: string;
	reason?: string;
	// residence?: string;
	street?: string;
	city?: string;
	state?: string;
	zip?: string;
};



class SchedulerData extends React.PureComponent<WithStyles<typeof styles> & IProps & RouteComponentProps & IDispatchProp> {
  
  render() {
    const { classes, extraFields } = this.props;

	const extraFieldList = Object.fromEntries(extraFields.map((field) => ([field, Yup.string().required()])));

	const FormSchema = Yup.object().shape({
		firstName: Yup.string().required(),
		lastName: Yup.string().required(),
		email: Yup.string().email().required(),
		phone: Yup.string().min(10).required(),
		dob: Yup.string().required(),
		ssn4: Yup.string().min(4).max(4).required(),
		gender: Yup.string().required(),
		race: Yup.string().required(),
		heightFeet: Yup.number().required(),
		heightInches: Yup.number().required(),
		weight: Yup.number().min(1).required(),
		eyeColor: Yup.string().required(),
		hairColor: Yup.string().required(),
		pob: Yup.string().required(),
		reason: Yup.string().required(),
		// residence: Yup.string().min(4).required(),
		street: Yup.string().min(4).required(),
		city: Yup.string().min(2).required(),
		state: Yup.string().min(2).required(),
		zip: Yup.string().min(5).required(),
		...extraFieldList
	});

	const pobList = PlacesOfBirth.sort((a,b) => a.name.localeCompare(b.name)).map((place) => (
		<MenuItem value={place.value}>{place.name}</MenuItem>
	));

	const ctzList = Citizenship.sort((a,b) => a.description.localeCompare(b.description)).map((ctz) => (
		<MenuItem value={ctz.value}>{ctz.description}</MenuItem>
	));

	return (
      <AnonLayout title='New Application' subtitle='Please provide the information below'>
		<RequiresActiveWizard/>
        <Paper className={classes.paper}>
			<Formik
			  initialValues={{
				firstName: '',
				middleName: '',
				lastName: '',
				email: '',
				phone: '',
				dob: '',
				ssn4: '',
				gender: '',
				race: '',
				heightFeet: '',
				heightInches: '',
				weight: '',
				eyeColor: '',
				hairColor: '',
				pob: '',
				citizenship: '',
				reason: 'bgcheck',
				//residence: '',
				street: '',
				city: '',
				state: '',
				zip: ''
			  }}
			  validationSchema={FormSchema}
			  onSubmit={(values, {setSubmitting}) => {
				this.onProceed(values);
			  }}
			>
				{({ submitForm, isSubmitting }) => (
					<Form>
						<Grid container spacing={2}>
							{extraFields.includes("middleName") ? (
								<>
									<Grid item sm={4} xs={12}>
										<Field component={TextField} name="firstName" label="First name" variant="filled" fullWidth required autoFocus />
									</Grid>
									<Grid item sm={4} xs={12}>
										<Field component={TextField} name="middleName" label="Middle name" variant="filled" fullWidth required />
									</Grid>
									<Grid item sm={4} xs={12}>
										<Field component={TextField} name="lastName" label="Last name" variant="filled" fullWidth required />
									</Grid>
								</>
							) : (
								<>
									<Grid item sm={6} xs={12}>
										<Field component={TextField} name="firstName" label="First name" variant="filled" fullWidth required autoFocus />
									</Grid>
									<Grid item sm={6} xs={12}>
										<Field component={TextField} name="lastName" label="Last name" variant="filled" fullWidth required />
									</Grid>
								</>
							)}

							<Grid item sm={6} xs={12}>
								<Field component={TextField} name="dob" type="date" InputLabelProps={{shrink: true}} label="Date of Birth" variant="filled" fullWidth required />
							</Grid>
							<Grid item sm={6} xs={12}>
								<Field component={TextField} name="ssn4" label="Last 4 SSN" variant="filled" fullWidth required />
							</Grid>

							<Grid item sm={6} xs={12}>
								<Field component={TextField} type="phone" name="phone" label="Phone number" variant="filled" fullWidth required />
							</Grid>
							<Grid item sm={6} xs={12}>
								<Field component={TextField} type="email" name="email" label="Email" variant="filled" fullWidth required />
							</Grid>

							<Grid item sm={6} xs={12}>
								<FormControl variant="filled" className={classes.formControl}>
									<InputLabel htmlFor="select-gender">Gender</InputLabel>
									<Field component={Select} name="gender" inputProps={{id:'select-gender'}} required >
										<MenuItem value='F'>Female</MenuItem>
										<MenuItem value='M'>Male</MenuItem>
										<MenuItem value='X'>Unknown</MenuItem>
									</Field>
									<ErrorMessage name="gender" className={classes.errorText} component={'p'} />
								</FormControl>
							</Grid>
							<Grid item sm={6} xs={12}>
								<FormControl variant="filled" className={classes.formControl}>
									<InputLabel htmlFor="select-race">Race</InputLabel>
									<Field component={Select} name="race" inputProps={{id:'select-race'}} required >
										<MenuItem value='I'>American Indian, Eskimo or Alaska Native</MenuItem>
										<MenuItem value='B'>Black, or a person having origins in any of the black racial groups of Africa</MenuItem>
										<MenuItem value='W'>Caucasian, Mexican, Puerto Rican, Cuban, Central or South American, or other Spanish culture or origin, regardless of race</MenuItem>
										<MenuItem value='A'>Chinese, Japanese, Filipino, Korean, Polynesian, Indian, Indonesian, Asian Indian, Somoan, or any other Pacific Islander</MenuItem>
										<MenuItem value='U'>Unknown or of indeterminable race</MenuItem>
									</Field>
									<ErrorMessage name="race" className={classes.errorText} component={'p'} />
								</FormControl>
							</Grid>

							<Grid item sm={3} xs={6}>
								<FormControl variant="filled" className={classes.formControl}>
									<InputLabel htmlFor="select-height-feet">Height (feet)</InputLabel>
									<Field component={Select} name="heightFeet" inputProps={{id:'select-height-feet'}} required >
										<MenuItem value='1'>1'</MenuItem>
										<MenuItem value='2'>2'</MenuItem>
										<MenuItem value='3'>3'</MenuItem>
										<MenuItem value='4'>4'</MenuItem>
										<MenuItem value='5'>5'</MenuItem>
										<MenuItem value='6'>6'</MenuItem>
										<MenuItem value='7'>7'</MenuItem>
										<MenuItem value='8'>8'</MenuItem>
									</Field>
									<ErrorMessage name="heightFeet" className={classes.errorText} component={'p'} />
								</FormControl>
							</Grid>
							<Grid item sm={3} xs={6}>
								<FormControl variant="filled" className={classes.formControl}>
									<InputLabel htmlFor="select-height-inches">Height (inches)</InputLabel>
									<Field component={Select} name="heightInches" inputProps={{id:'select-height-inches'}} required >
										<MenuItem value='0'>0"</MenuItem>
										<MenuItem value='1'>1"</MenuItem>
										<MenuItem value='2'>2"</MenuItem>
										<MenuItem value='3'>3"</MenuItem>
										<MenuItem value='4'>4"</MenuItem>
										<MenuItem value='5'>5"</MenuItem>
										<MenuItem value='6'>6"</MenuItem>
										<MenuItem value='7'>7"</MenuItem>
										<MenuItem value='8'>8"</MenuItem>
										<MenuItem value='9'>9"</MenuItem>
										<MenuItem value='10'>10"</MenuItem>
										<MenuItem value='11'>11"</MenuItem>
									</Field>
									<ErrorMessage name="heightInches" className={classes.errorText} component={'p'} />
								</FormControl>
							</Grid>
							<Grid item sm={6} xs={12}>
								<Field component={TextField} type="number" name="weight" inputProps={{min:"0"}} label="Weight (lbs)" variant="filled" fullWidth required />
							</Grid>

							<Grid item sm={6} xs={12}>
								<FormControl variant="filled" className={classes.formControl}>
									<InputLabel htmlFor="select-eye-color">Eye Color</InputLabel>
									<Field component={Select} name="eyeColor" inputProps={{id:'select-eye-color'}} required >
										<MenuItem value='BLK'>Black</MenuItem>
										<MenuItem value='BLU'>Blue</MenuItem>
										<MenuItem value='BRO'>Brown</MenuItem>
										<MenuItem value='GRY'>Gray</MenuItem>
										<MenuItem value='GRN'>Green</MenuItem>
										<MenuItem value='HAZ'>Hazel</MenuItem>
										<MenuItem value='MAR'>Maroon</MenuItem>
									</Field>
									<ErrorMessage name="eyeColor" className={classes.errorText} component={'p'} />
								</FormControl>
							</Grid>
							<Grid item sm={6} xs={12}>
								<FormControl variant="filled" className={classes.formControl}>
									<InputLabel htmlFor="select-hair-color">Hair Color</InputLabel>
									<Field component={Select} name="hairColor" inputProps={{id:'select-hair-color'}} required >
										<MenuItem value='BLD'>Bald</MenuItem>
										<MenuItem value='BLK'>Black</MenuItem>
										<MenuItem value='BLN'>Blonde (or strawberry)</MenuItem>
										<MenuItem value='BLU'>Blue</MenuItem>
										<MenuItem value='BRO'>Brown</MenuItem>
										<MenuItem value='GRN'>Green</MenuItem>
										<MenuItem value='GRY'>Gray</MenuItem>
										<MenuItem value='ONG'>Orange</MenuItem>
										<MenuItem value='PLE'>Purple</MenuItem>
										<MenuItem value='PNK'>Pink</MenuItem>
										<MenuItem value='RED'>Red (or auburn)</MenuItem>
										<MenuItem value='SDY'>Sandy</MenuItem>
										<MenuItem value='WHI'>White</MenuItem>
										<MenuItem value='XXX'>Unknown</MenuItem>
									</Field>
									<ErrorMessage name="hairColor" className={classes.errorText} component={'p'} />
								</FormControl>
							</Grid>

							{extraFields.includes("citizenship") && (
								<Grid item sm={12} xs={12}>
									<FormControl variant="filled" className={classes.formControl}>
										<InputLabel htmlFor="select-citizenship">Citizenship</InputLabel>
										<Field component={Select} name="citizenship" inputProps={{id:'select-citizenship'}} required >
											{ctzList}
										</Field>
										<ErrorMessage name="citizenship" className={classes.errorText} component={'p'} />
									</FormControl>
								</Grid>
							)}

							<Grid item sm={6} xs={12}>
								<FormControl variant="filled" className={classes.formControl}>
									<InputLabel htmlFor="select-pob">Place of Birth</InputLabel>
									<Field component={Select} name="pob" inputProps={{id:'select-pob'}} required >
										{pobList}
									</Field>
									<ErrorMessage name="pob" className={classes.errorText} component={'p'} />
								</FormControl>
							</Grid>

							<Grid item sm={6} xs={12}>
								<FormControl variant="filled" className={classes.formControl}>
									<InputLabel htmlFor="select-reason">Reason fingerprinted</InputLabel>
									<Field component={Select} name="reason" inputProps={{id:'select-reason'}} disabled>
										<MenuItem value='bgcheck'>Background Check</MenuItem>
									</Field>
								</FormControl>
							</Grid>

							<Grid item sm={12}>
								<Field component={TextField} name="street" label="Street address (residence)" variant="filled" fullWidth required />
							</Grid>

							<Grid item sm={4}>
								<Field component={TextField} name="city" label="City (residence)" variant="filled" fullWidth required />
							</Grid>
							<Grid item sm={4}>
								<Field component={TextField} name="state" label="State (residence)" variant="filled" fullWidth required />
							</Grid>
							<Grid item sm={4}>
								<Field component={TextField} name="zip" label="Zip (residence)" variant="filled" fullWidth required />
							</Grid>
						</Grid>
						{isSubmitting && <LinearProgress />}
						<Grid container={true} className={classes.centeredButton} justify="center" spacing={2}>
							<Button disabled={isSubmitting} onClick={submitForm} variant="contained" color="primary">
								Continue
							</Button>
						</Grid>
					</Form>
				)}
			</Formik>
			
		</Paper>
      </AnonLayout>
    );
  }

  safeString = (value: string) => {
	return value.replace(',', ' ').trim()
  }
 
  onProceed = (values: IForm) => {
	const { dispatch, displayWaiver, history, shouldTakePayment, extraFields } = this.props;
	const { 
		citizenship,
		firstName, 
		middleName,
		lastName,
		email, 
		phone, 
		dob, 
		ssn4, 
		gender, 
		race, 
		heightFeet, 
		heightInches,
		weight, 
		eyeColor, 
		hairColor, 
		pob, 
		reason, 
		// residence 
		street,
		city,
		state,
		zip
	} = values;

	if (!(
		firstName && 
		(middleName || !extraFields.includes("middleName")) &&
		lastName && 
		email && 
		phone && 
		dob && 
		ssn4 && 
		gender && 
		race && 
		heightFeet && 
		heightInches &&
		weight && 
		eyeColor && 
		hairColor && 
		pob && 
		(citizenship || !extraFields.includes("citizenship")) &&
		reason && 
		// residence
		street &&
		city &&
		state &&
		zip
		)) {
		return;
	}

	const residence = `${this.safeString(street)}, ${this.safeString(city)}, ${this.safeString(state)} ${this.safeString(zip)}`;
	
	dispatch(newWizardActions.setData(
		citizenship || '',
		firstName, 
		middleName || '',
		lastName,
		new Date(dob), 
		ssn4, 
		phone, 
		email, 
		gender, 
		race, 
		`${heightFeet}'${heightInches}"`,
		weight, 
		eyeColor, 
		hairColor, 
		pob, 
		reason, 
		residence
	));
	
	if (displayWaiver) {
		history.push("/waiver");
	}
	else {
		if (shouldTakePayment) {
			history.push("/payment");
		}
		else {
			history.push("/confirmation");
		}
	}	
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerData)));