import React, { PureComponent } from 'react';
import { createStyles, WithTheme, WithStyles, withStyles, withTheme, Typography, Theme } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';


const styles = (theme: Theme) =>
    createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			flexGrow: 1,
			padding: theme.spacing(2),
		}
	});

interface IProps {
	icon: string;
	text: string;
}

type FullProps = IProps & WithStyles & WithTheme;
class EmptyState extends PureComponent<FullProps> {
	render() {
		const { classes, text } = this.props;
	
		return (
			<div className={classes.container}>
				<ErrorOutline />
				<Typography variant="subtitle2">
					{text}
				</Typography>
			</div>
		);
	}
}

export default withStyles(styles)(withTheme(EmptyState));
