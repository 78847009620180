import { Resource, CombineResource, IStateBucket, Call } from "restdux";
import { getAPIUrl, getHeaders, transformIndex, getCallListAPIUrlFunction } from "../global/globals";

export interface IReturn {
	agency: string;
	description: string;
	id: number;
	lsv: number;
	note: string;
	ori: string;
	prompt_location: boolean;
	prompt_vendor: boolean;
	slug: string;
}

export type ISend = Partial<IReturn>;

export interface IImportSend {
	data: Array<any>;
	lsv: number;
}

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "ori",
	rootUrl: getAPIUrl("/oris"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {
	bulk_import: Call<{},IImportSend,{}, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "PUT",
		url: getCallListAPIUrlFunction("/oris", "bulk_import"),
	}),
});