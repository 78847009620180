import { ApplicationState } from '../reducers';
import { IWizardState as NewWizardState } from '../reducers/newWizard';
import { IWizardState as IllegibleWizardState } from '../reducers/illegibleWizard';

export const getActiveWizard = (state: ApplicationState) => {
    const newWizard = state.newWizard as NewWizardState;
    const illWizard = state.illegibleWizard as IllegibleWizardState;
    if (newWizard.step) {
        return 'new';
    }
    if (illWizard.step) {
        return 'illegible';
    }
    return undefined;
}

export const getLSV = (state: ApplicationState) => {
    try {
        const { newWizard } = state;
        const { lsv } = newWizard as NewWizardState;
        return lsv;
    }
    catch {
        return undefined;
    }
};

export const getNewWizard = (state: ApplicationState) => state.newWizard;
export const getIllegibleWizard = (state: ApplicationState) => state.illegibleWizard;

export const getLookupData = (state: ApplicationState) => {
    const { illegibleWizard } = state;
    const { regNumber, lastName, email } = illegibleWizard;
    return {
        regNumber,
        lastName,
        email
    }
}

export const shouldTakePayment = (state: ApplicationState) => {
    const { newWizard } = state;
    const { paymentMethod } = newWizard;
    return paymentMethod === 'OL';
};

export const getAlreadyConfirmed = (state: ApplicationState) => {
    const newWizard = state.newWizard as NewWizardState;
    const illWizard = state.illegibleWizard as IllegibleWizardState;
    const { step: newStep } = newWizard;
    const { step: illStep } = illWizard;
    
    return !!((newStep && newStep == 9) || (illStep && illStep == 7));
}

export const getActiveStep = (state: ApplicationState) => {
    const newWizard = state.newWizard as NewWizardState;
    const illWizard = state.illegibleWizard as IllegibleWizardState;
    const { step: newStep } = newWizard;
    const { step: illStep } = illWizard;
    
    return newStep || illStep;
}

// export const getLSVID = (state: ApplicationState) => {
//     try {
//         const { id } = getLSV(state);
//         return lsv;
//     }
//     catch {
//         return null;
//     }
// };
