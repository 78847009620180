import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import FileField from '../file/Field';
import { ApplicationState } from '../../reducers';
import { IReturn as WaiverSignature, actions as waiverSignatureActions } from '../../api/resources/WaiverSignature';
import { IReturn as Waiver, actions as waiverActions } from '../../api/resources/Waiver';
import { IReturn as Applicant } from '../../api/resources/Applicant';

const styles = (theme: Theme) => createStyles({});

type PartialWaiverSignature = Partial<WaiverSignature>;

interface IState {
    signature?: PartialWaiverSignature;
}

interface IProps {
    applicant: Applicant;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
    waivers?: Waiver[];
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        waivers: Object.values(state.waiver.data),    
    };
};


type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class WaiverUploadModal extends React.Component<FullProps, IState> {
    public state = {
        signature: {} as PartialWaiverSignature,
    };

    public componentDidMount() {
        this.loadDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { applicant } = this.props;

        if (prevProps.applicant !== applicant)
            this.loadDependencies();
    }

    public async loadDependencies() {
        const { applicant, dispatch } = this.props;

        if (applicant)
            dispatch(waiverActions.index(undefined, undefined, {}));
    }
    
    render() {
        const { open } = this.props;
        const { signature } = this.state;

        const saveable = !!signature.waiver_file;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload Waiver Signature</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select a signed waiver file for upload below.</DialogContentText>
                                        
                    <FileField
                        label='Signed waiver'
                        value={signature.waiver_file}
                        onChange={(value: string) => {
                            const signature = {
                                ...this.state.signature,
                                waiver_file: value
                            };
                            this.setState({signature});
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!saveable} onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { applicant, dispatch, waivers } = this.props;
        const { signature } = this.state;
        if (!applicant || !waivers || waivers.length <= 0) {
            return;
        }
        const hydratedSignature = {
            ...signature,
            applicant: applicant.id,
            waiver: waivers[0].id,
        };
        await dispatch(waiverSignatureActions.create(undefined, hydratedSignature)).catch(console.error);
        this.props.onDismiss(true);
    }
}

export default connect(mapStateToProps)(withStyles(styles)(WaiverUploadModal));