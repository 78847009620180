import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { Delete, MoreVert } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
// import UserChangePasswordModal from './ChangePasswordModal';
// import UserDeleteModal from './DeleteModal';
// import UserEditModal from './AddEditModal';
import { IUserReturn as UserPerm, actions as livescanVendorActions } from '../../../api/resources/LivescanVendor';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    });

interface IProps {
    index: number;
    userPerm: UserPerm;
    onDelete: (user: UserPerm) => void;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
    showChangeModal: boolean;
    showDeleteModal: boolean;
    showEditModal: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class UserItem extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
        showChangeModal: false,
        showDeleteModal: false,
        showEditModal: false,
    }
    
    render() {
        const { index, userPerm, classes } = this.props;
        const { anchorEl, expanded, showChangeModal, showDeleteModal, showEditModal } = this.state;

        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem>
                    <ListItemText primary={(
                        <>{userPerm.email}</>
                    )} />
                    
                    <ListItemSecondaryAction>
                        <IconButton onClick={this.handleDeleteClick}>
                            <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {/* <UserChangePasswordModal user={user} open={showChangeModal} onDismiss={this.handleChangePasswordDismiss} />
                <UserDeleteModal open={showDeleteModal} onDismiss={this.handleDeleteDismiss} />
                <UserEditModal user={user} open={showEditModal} onDismiss={this.handleEditDismiss} /> */}
            </React.Fragment>
        )
    }

    private handleDeleteClick = () => {
        const { userPerm, onDelete } = this.props;
        onDelete(userPerm);
    }

    private handleEditClick = () => {
        this.setState({
            expanded: false,
            showEditModal: true
        });
    }

    private handleEditDismiss = (shouldRefresh: boolean) => {
        this.setState({showEditModal: false});
    }
}

export default connect()(withTheme(withStyles(styles)(UserItem)));