import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Button,
    Collapse,
    Divider,
    ListItem,
    ListItemText,
    Theme,
    Typography,
    withTheme, 
    WithTheme, 
    ListItemAvatar,
    Grid,
    Link,
    List,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { ExpandLess, ExpandMore, CreditCard, LocalAtm, Cancel, CheckCircle, Receipt, ShoppingCart } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import AppointmentCompletedModal from './CompletedModal';
import AppointmentNoShowedModal from './NoShowedModal';
import AppointmentCancelModal from './CancelModal';
import AppointmentEditModal from './AddEditModal';
import AppointmentNotesModal from './NotesModal';
import WaiverRow from '../waiver/Row';
import Moment from 'react-moment';
import { IReturn as Appointment, actions as appointmentActions, AppointmentStatus, PaymentMethod } from '../../api/resources/Appointment';
import { IReturn as Location, actions as locationActions } from '../../api/resources/Location';
import { IReturn as Applicant, actions as applicantActions } from '../../api/resources/Applicant';
import { IReturn as ApplicantType, actions as applicantTypeActions } from '../../api/resources/ApplicantType';
import { IReturn as LivescanVendor, actions as livescanVendorActions } from '../../api/resources/LivescanVendor';
import { IReturn as WaiverSignature, actions as waiverSignatureActions } from '../../api/resources/WaiverSignature';
import { ApplicationState } from '../../reducers';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        itemSection: {
            flexBasis: '100%',
        },
        faded: {
            color: theme.palette.text.hint,
        }
    });

interface IProps {
    index: number;
    appointment: Appointment;
    applicant?: Applicant;
    applicantLocation?: Location;
    applicantType?: ApplicantType;
    lsv?: LivescanVendor;
    signatures?: WaiverSignature[];
};

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { appointment } = ownProps;
    const applicant = appointment ? Object.values(state.applicant.data).find((applicant: Applicant)=>applicant.id === appointment.applicant) : undefined;
  
    return {
        applicant,
        applicantLocation: applicant && applicant.locations && applicant.locations.length > 0 ? Object.values(state.location.data).find((location: Location)=>location.id === applicant.locations[0]) : undefined,
        applicantType: applicant ? Object.values(state.applicanttype.data).find((applicantType: ApplicantType)=>applicantType.id === applicant.applicant_type) : undefined,
        lsv: appointment ? Object.values(state.livescanvendor.data).find((lsv: LivescanVendor)=>lsv.id === appointment.lsv) : undefined,
        signatures: applicant ? Object.values(state.waiversignature.data).filter((item)=>item.applicant===applicant.id) : [],    
    };
  };


type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class AppointmentItem extends React.PureComponent<FullProps> {
    public state = {
        expanded: false,
        showCancelModal: false,
        showCompletedModal: false,
        showEditModal: 0,
        showNoShowModal: false,
        showNotesModal: false,
        showMoreMenu: false,
        anchorEl: undefined as HTMLElement|undefined,
    }

    public componentDidMount() {
        this.loadDependencies();
        this.loadApplicantDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { appointment, applicant } = this.props;

        if (prevProps.appointment !== appointment)
            this.loadDependencies();
        
        if (prevProps.applicant !== applicant)
            this.loadApplicantDependencies();
    }

    public async loadDependencies() {
        const { appointment, dispatch } = this.props;

        if (appointment)
            // dispatch(applicantActions.cacheread(undefined, undefined, {applicant:applicant.id}));
            dispatch(applicantActions.cacheread({}, appointment.applicant)).catch(console.error)
            dispatch(livescanVendorActions.cacheread({}, appointment.lsv)).catch(console.error)
    }

    public async loadApplicantDependencies() {
        const { applicant, dispatch } = this.props;
        const { expanded } = this.state;

        if (applicant) {
            // dispatch(applicantActions.cacheread(undefined, undefined, {applicant:applicant.id}));
            dispatch(applicantTypeActions.cacheread({}, applicant.applicant_type)).catch(console.error)
            if (applicant.locations.length > 0) {
                dispatch(locationActions.cacheread({}, applicant.locations[0])).catch(console.error)
            }
            if (expanded) {
                dispatch(waiverSignatureActions.index(undefined, undefined, {applicant:applicant.id}));
            }
        }
    }
    
    render() {
        const { index, appointment, applicant, applicantLocation, applicantType, classes, lsv, signatures } = this.props;
        const { anchorEl, expanded, showCancelModal, showCompletedModal, showEditModal, showNoShowModal, showNotesModal, showMoreMenu } = this.state;
        const isNetwork = this.isNetwork();

        const signaturesMarkup = signatures ? signatures.map((signature, index) => (
            <WaiverRow signature={signature} key={index} />
        )) : [];

        return (
            <React.Fragment>
                {index !== 0 && (
                <Divider component="li" />
                )}
                <ListItem button onClick={this.handleExpandClick} className={appointment.status !== AppointmentStatus.SCHEDULED ? classes.faded : undefined}>
                    <ListItemText className={classes.itemSection} primary={(
                        <Moment format="LT" tz={lsv?.timezone}>{appointment.date_time}</Moment>
                    )} secondary={isNetwork && lsv && (
                        <>
                            {lsv.name}
                        </>
                    )}/>
                    <ListItemText className={classes.itemSection} secondary={(
                        <>
                            {applicant?.last_name}, {applicant?.first_name}
                            <br />{applicantType?.description}
                        </>
                    )} />
                    <ListItemText className={classes.itemSection} secondary={(
                        <>
                            {applicant?.phone}
                            <br />{applicant?.email}
                        </>
                    )} />

                    {appointment.status === AppointmentStatus.SCHEDULED ? (
                        <ListItemAvatar>
                            <>
                                {/* Payment method indication */}
                                {appointment.payment_method === PaymentMethod.CASH && <LocalAtm />}
                                {appointment.payment_method === PaymentMethod.CREDIT && <CreditCard />}
                                {appointment.payment_method === PaymentMethod.INVOICE && <Receipt />}
                                {appointment.payment_method === PaymentMethod.ONLINE && <ShoppingCart />}
                            </>
                        </ListItemAvatar>
                    ) : (
                        <ListItemAvatar>
                            <>
                                {/* Status indication */}
                                {appointment.status === AppointmentStatus.NO_SHOW && (
                                    <Cancel />
                                )}
                                {appointment.status === AppointmentStatus.COMPLETED && (
                                    <CheckCircle />
                                )}
                            </>
                        </ListItemAvatar>
                    )}      
                    
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div className={classes.content}>
                        
                        <Grid container spacing={2}>
                            <Grid item md={3} sm={6} xs={12}>
                                <Typography variant="subtitle2">
                                    <strong>Appointment ID</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {appointment.id}
                                </Typography>
                            </Grid>
                            
                            <Grid item md={3} sm={6} xs={12}>
                                <Typography variant="subtitle2">
                                    <strong>Status</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {appointment.status === AppointmentStatus.SCHEDULED && 'Scheduled'}
                                    {appointment.status === AppointmentStatus.COMPLETED && 'Completed'}
                                    {appointment.status === AppointmentStatus.NO_SHOW && 'No-Show'}
                                </Typography>
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                                <Typography variant="subtitle2">
                                    <strong>Applicant Type/Location</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {applicantType ? applicantType.description : 'Unknown'}
                                    {applicantLocation && `, ${applicantLocation.name}`}
                                </Typography>
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                                <Typography variant="subtitle2">
                                    <strong>Payment Method</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {appointment.payment_method === PaymentMethod.CASH && 'Cash'}
                                    {appointment.payment_method === PaymentMethod.CREDIT && 'Credit'}
                                    {appointment.payment_method === PaymentMethod.INVOICE && 'Invoice'}
                                    {appointment.payment_method === PaymentMethod.ONLINE && 'Online'}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12}>
                                <Typography variant="subtitle2">
                                    <strong>LSV Notes [<Link onClick={this.showNotesModal}>Edit</Link>]</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {appointment.notes ? appointment.notes : 'No notes'}
                                </Typography>
                            </Grid>

                            <Grid item md={6} sm={12}>
                                <Typography variant="subtitle2">
                                    <strong>Applicant Notes</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {applicant && applicant.notes ? applicant.notes : 'No notes'}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    <strong>Waivers</strong>
                                </Typography>
                                {signaturesMarkup && signaturesMarkup.length > 0 ? (
                                    <List dense>{signaturesMarkup}</List>
                                ) : (
                                    <Typography variant="body2">
                                        None
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        
                    </div>
                    {appointment.status !== AppointmentStatus.COMPLETED && (
                        <div className={classes.itemActions}>
                            <Button onClick={this.handleEditClick}>Reschedule</Button>
                            {appointment.status === AppointmentStatus.SCHEDULED && (
                                <>
                                    <Button onClick={this.handleNoShowClick} color="secondary">No Show</Button>
                                    <Button onClick={this.handleCompletedClick} color="primary">Completed</Button>
                                </>
                            )}
                            <IconButton
                                aria-label="more"
                                aria-haspopup="true"
                                onClick={(evt) => this.setState({showMoreMenu: true, anchorEl: evt.currentTarget})}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="more-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={showMoreMenu}
                                onClose={() => this.setState({showMoreMenu: false})}
                            >
                                <MenuItem onClick={this.handleModifyClick}>Modify</MenuItem>
                                <MenuItem onClick={this.handleCancelClick}>Cancel</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Collapse>
                <AppointmentCompletedModal open={showCompletedModal} onDismiss={this.handleCompletedDismiss} />
                <AppointmentEditModal appointment={appointment} open={showEditModal != 0} step={showEditModal} onDismiss={this.handleEditDismiss} />
                <AppointmentNoShowedModal open={showNoShowModal} onDismiss={this.handleNoShowDismiss} />
                <AppointmentCancelModal open={showCancelModal} onDismiss={this.handleCancelDismiss} />
                <AppointmentNotesModal notes={appointment.notes} open={showNotesModal} onDismiss={this.handleNotesDismiss} />
            </React.Fragment>
        )
    }

    private handleModifyClick = () => {
        this.setState({
            showEditModal: 2,
            showMoreMenu: false
        });
    }

    private handleCancelClick = () => {
        this.setState({
            showCancelModal: true,
            showMoreMenu: false
        });
    }

    private handleCancelDismiss = (shouldModify: boolean) => {
        if (shouldModify) {
            const { dispatch, appointment } = this.props;
            // dispatch(appointmentActions.update(appointment.id, {status: AppointmentStatus.NO_SHOW}, undefined));
            dispatch(appointmentActions.delete(appointment.id, {}, undefined));
        }
        this.setState({showCancelModal: false});
    }
    
    private handleExpandClick = () => {
        const { applicant, dispatch } = this.props;
        if (!this.state.expanded && applicant) {
            dispatch(waiverSignatureActions.index(undefined, undefined, {applicant:applicant.id}));
        }
        this.setState({expanded: !this.state.expanded});
    }

    private handleEditClick = () => {
        this.setState({showEditModal: 1});
    }

    private handleEditDismiss = (shouldRefresh: boolean) => {
        this.setState({showEditModal: 0});
    }

    private handleNoShowClick = () => {
        this.setState({showNoShowModal: true});
    }

    private handleNoShowDismiss = (shouldModify: boolean) => {
        if (shouldModify) {
            const { dispatch, appointment } = this.props;
            dispatch(appointmentActions.update(appointment.id, {status: AppointmentStatus.NO_SHOW}, undefined));
        }
        this.setState({showNoShowModal: false});
    }

    private handleCompletedClick = () => {
        this.setState({showCompletedModal: true});
    }

    private handleCompletedDismiss = (shouldModify: boolean) => {
        if (shouldModify) {
            const { dispatch, appointment } = this.props;
            dispatch(appointmentActions.update(appointment.id, {status: AppointmentStatus.COMPLETED}, undefined));
        }
        this.setState({showCompletedModal: false});
    }

    private showNotesModal = () => {
        this.setState({showNotesModal: true});
    }

    private handleNotesDismiss = (notes: string|undefined) => {
        if (notes) {
            const { dispatch, appointment } = this.props;
            dispatch(appointmentActions.update(appointment.id, {notes}, undefined));
        }
        
        this.setState({showNotesModal: false});
    }

    private isNetwork() {
        const hostName = window.location.hostname;
        return hostName.indexOf('network') >= 0;
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(AppointmentItem)));