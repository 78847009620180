import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export const RecipientOptions = {
	Location: 'LOC',
	Vendor: 'VEN',
}

export interface IReturn {
	description: string;
	employer: number;
	id: number;
	is_positive: boolean;
	recipient: 'LOC'|'VEN';
	slug: string;
	template: string;
	template_url: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "statuscode",
	rootUrl: getAPIUrl("/statuscodes"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});