import React from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as Location } from '../../../api/resources/Location';

const styles = (theme: Theme) =>
    createStyles({
        
    });

interface IProps {
    location: Location;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles>;
class ApplicantLocationDeleteModal extends React.PureComponent<FullProps> {
    render() {
        const { location, open } = this.props;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Remove Work Location?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Remove {location.name} from locations? 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(ApplicantLocationDeleteModal));