import { Button, Grid, Theme, Paper, Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { actions as waiverActions, IReturn as Waiver, IFieldData } from '../../../api/resources/Waiver';
import { IDynamicFieldEntry } from '../../../api/resources/WaiverSignature';
import { ApplicationState } from '../../../reducers';
import { getEnvironmentID, isEnvironmentEmployer } from '../../../selectors/environment';
import { shouldTakePayment, getAlreadyConfirmed, getActiveStep } from '../../../selectors/wizard';
import ReactMarkdown from 'react-markdown/with-html';
import Moment from 'react-moment';
import SignatureCanvas from 'react-signature-canvas';
import RequiresActiveWizard from '../../../components/RequiresActiveWizard';

interface IProps {
	dispatch: any;
	environmentID?: number;
	waiverIndex?: number;
	isEnvironmentEmployer?: boolean;
	shouldTakePayment?: boolean;
	alreadyConfirmed?: boolean;
	activeStep?: number;
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		environmentID: getEnvironmentID(state),
		isEnvironmentEmployer: isEnvironmentEmployer(state),
		shouldTakePayment: shouldTakePayment(state),
		alreadyConfirmed: getAlreadyConfirmed(state),
		activeStep: getActiveStep(state),
	}
}

interface IState {
	formValues: IDynamicFieldEntry[];
	sigImage?: string;
	totalWaivers?: number;
	waiver?: Waiver;
}

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			// alignItems: 'center',
			// display: 'flex',
			// flexDirection: 'column',
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		},
		waiver: {
			'& input': {
				backgroundColor: '#afa',
			},
			'& textarea': {
				backgroundColor: '#afa',
			},
			'& label': {
				backgroundColor: '#afa',
			},
			'& .printonly': {
				display: 'none',
			}
		},
		signaturePad: {
			border: '2px solid black',
			backgroundColor: '#afa',
			width: '100%',
			cursor: 'pointer',
			// height: 
		}
	});

type FullProps = WithStyles<typeof styles> & IProps & RouteComponentProps;
class SchedulerWaiver extends React.PureComponent<FullProps, IState> {
  
  state = {
		formValues: [] as IDynamicFieldEntry[],
		sigImage: undefined as string|undefined,
		totalWaivers: undefined as number|undefined,
		waiver: undefined as Waiver|undefined
	};

  sigPad = null as SignatureCanvas|null;

  public componentDidMount() {
	this.loadDependencies()
	console.log("componentDidMount index", this.props.waiverIndex);
  }

  public componentDidUpdate(prevProps: FullProps) {
	const { environmentID, waiverIndex } = this.props;

	console.log("componentDidUpdate index", this.props.waiverIndex);

	if (prevProps.environmentID != environmentID || prevProps.waiverIndex != waiverIndex)
	  this.loadDependencies();
  }

  private async loadDependencies() {
	const { dispatch, environmentID, isEnvironmentEmployer, alreadyConfirmed, history, activeStep, waiverIndex } = this.props;
	if (!environmentID) {
	  return;
	}
	if (alreadyConfirmed) {
		history.push("/");
		return;
	}
	const result = await dispatch(waiverActions.index(undefined, undefined, isEnvironmentEmployer ? {employer: environmentID} : {network: environmentID})).catch(console.error);
	const waivers = result.result as Waiver[];
	if (waivers.length > 0) {
		const definedWaiverIndex = waiverIndex ? waiverIndex : 0;
		const safeWaiverIndex = definedWaiverIndex < waivers.length ? definedWaiverIndex : waivers.length - 1;
		this.setState({
			totalWaivers: waivers.length,
			waiver: waivers[safeWaiverIndex]
		});
	}
	
  }
  
  render() {
    const { classes } = this.props;
	const { sigImage, waiver } = this.state;

	const isValid = (
		!!sigImage
	);

    return (
      <AnonLayout title='New Application' subtitle='Please review and sign the following waiver'>
        <RequiresActiveWizard/>
		<Paper className={classes.paper}>
			{waiver ? (
				<ReactMarkdown escapeHtml={false} className={classes.waiver}>{waiver.waiver_data}</ReactMarkdown>
			) : (
				<Typography variant="body1">Please wait...</Typography>
			)}
			
			<Grid container spacing={2}>
				<Grid item xs={9}>
					<Typography variant="body1">Signature:</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="body1">Date: <Moment format="L" /></Typography>
				</Grid>
			</Grid>

			<Typography variant="subtitle2"><em>Sign below inside of the box using your touchpad or mouse.</em></Typography>

			<SignatureCanvas penColor='black' canvasProps={{ className: classes.signaturePad }} onEnd={this.handleSigUpdate} ref={(ref) => {this.sigPad = ref}} />

			<Grid container={true} className={classes.centeredButton} justify="center" spacing={2}>
				<Button disabled={!isValid} onClick={this.handleProceed} variant="contained" color="primary">
					Continue
				</Button>
			</Grid>
		</Paper>
      </AnonLayout>
    );
  }

  handleSigUpdate = () => {
	const { sigPad } = this;
	this.setState({
		sigImage: sigPad?.toDataURL()
	});
  }

  private captureFormData() {
	const { waiver } = this.state;
	
	if (!waiver) {
		return [] as IDynamicFieldEntry[];
	}

	const { field_data: field_data_raw } = waiver;
	const field_data = (typeof field_data_raw === 'string' ? JSON.parse(field_data_raw) : field_data_raw) as IFieldData;

	if (!field_data.capture) {
		return [] as IDynamicFieldEntry[];
	}

	const capturedFields = field_data.capture.map((fieldName) => {
		const fields = document.getElementsByName(fieldName);
		if (fields.length <= 0) {
			return {
				name: fieldName,
				value: ''
			} as IDynamicFieldEntry;
		}
		else if (fields.length === 1) {
			return {
				name: fieldName,
				value: (fields[0] as HTMLInputElement).value
			} as IDynamicFieldEntry;
		}
		else {
			const fieldsArray = Array.from(fields);
			const value = fieldsArray.map((field: HTMLElement) => {
				const castedElement = field as HTMLInputElement;
				return castedElement.checked && castedElement.value;
			}).reduce((prev: string|false, current: string|false) => (prev || current));
			
			return {
				name: fieldName,
				value: value ? value : ''
			} as IDynamicFieldEntry;
		}
	});

	this.setState({
		formValues: capturedFields
	});

	return capturedFields;
  }

  private validateCaptureData(formValues: IDynamicFieldEntry[]) {
	const { waiver } = this.state;
	
	if (!waiver || !formValues) {
		return false;
	}

	const { field_data: field_data_raw } = waiver;
	const field_data = (typeof field_data_raw === 'string' ? JSON.parse(field_data_raw) : field_data_raw) as IFieldData;

	if (!field_data.required) {
		return true;
	}

	const valuesDict = Object.assign({}, ...formValues.map((x) => ({[x.name]: x.value})));
	const met = field_data.required.map((fieldName) => {
		return (!!valuesDict[fieldName]);
	});

	return met.every((value) => !!value);
  }

  private handleProceed = () => {
	const { dispatch, history, waiverIndex, shouldTakePayment } = this.props;
	const { sigImage, totalWaivers, waiver } = this.state;

	if (!sigImage || !waiver || !totalWaivers) {
		return;
	}
	
	const fields = this.captureFormData();

	if (!this.validateCaptureData(fields)) {
		alert("Please fill all required fields first");
		return;
	}
	
	dispatch(newWizardActions.signWaiver(waiver?.id, fields, sigImage));
	
	// TODO: TEST THIS OUT
	const realIndex = waiverIndex ? waiverIndex : 0;
	if (realIndex < totalWaivers - 1) {
		history.push(`/waiver/${realIndex + 1}`); 
	}
	else {
		if (shouldTakePayment) {
			history.push("/payment");
		}
		else {
			history.push("/confirmation");
		}
	}	
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerWaiver)));