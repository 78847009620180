import { Button, Grid, Theme, Typography, Paper } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';
import { ApplicationState } from '../../../reducers';
import { getEnvironmentLogo, getEnvironmentName, shouldDisplayDisclaimer } from '../../../selectors/environment';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { actions as illWizardActions } from '../../../actions/illegibleWizard'; 

interface IProps {
	dispatch: any;
	displayDisclaimer: boolean;
	environmentLogo: string;
	environmentName: string;
}

const mapStateToProps = (state: ApplicationState) => ({
	environmentLogo: getEnvironmentLogo(state),
	environmentName: getEnvironmentName(state),
	displayDisclaimer: shouldDisplayDisclaimer(state),
});

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		topNav: {
			display: 'flex',
			padding: theme.spacing(2),
		},
		filler: {
			flex: 1,
		}
	});

class SchedulerLanding extends React.PureComponent<WithStyles<typeof styles> & IProps> {
  componentDidMount() {
	this.flushState();	
  }

  private async flushState() {
	const { dispatch } = this.props;
	
	await dispatch(newWizardActions.clear());
	await dispatch(illWizardActions.clear());
  }
	
  render() {
	const { classes, displayDisclaimer, environmentLogo, environmentName } = this.props;
	
	return (
      <AnonLayout topContent={(
		  <div className={classes.topNav}>
		  	<div className={classes.filler} />
			<Button component={RouterLink} to="/login" variant='contained'>LSV Login</Button>
		  </div>
	  )} 
		logo={environmentLogo}
	  	title={
		  environmentName ? `Welcome ${environmentName} Applicants!` : 'Welcome Applicants!'
		}>
        <Grid container spacing={1}>
			<Grid item sm={6} xs={12}>
				<Paper className={classes.paper}>
					<Grid container={true} justify="center" spacing={2}>
						<Grid item>
							<Typography component="h2" variant="h5">
								New applicant?
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2">
								Start here to begin your application process.
							</Typography>
						</Grid>
						<Grid item>
							<Button component={RouterLink} to={displayDisclaimer ? "/disclaimer/new" : "/locations/new"} variant="contained" color="primary">
								Start New Application
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item sm={6} xs={12}>
				<Paper className={classes.paper}>
					<Grid container={true} justify="center" spacing={2}>
						<Grid item>
							<Typography component="h2" variant="h5">
								Illegible prints?
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2">
								Start here to begin the resubmission process.
							</Typography>
						</Grid>
						<Grid item>
							<Button component={RouterLink} to={displayDisclaimer ? "/disclaimer/illegible" : "/lookup"} variant="contained" color="primary">
								Start Resubmission
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
		
      </AnonLayout>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(SchedulerLanding));