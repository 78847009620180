import { IReturn as ApplicantType } from '../api/resources/ApplicantType';
import { IReturn as LivescanVendor } from '../api/resources/LivescanVendor';
import { IReturn as Location } from '../api/resources/Location';
import { IReturn as Vendor } from '../api/resources/Vendor';
import { IDynamicFieldEntry } from '../api/resources/WaiverSignature';

import { actions, actionTypes } from '../actions/newWizard';

interface IBaseAction {
  type: string;
}

interface IAllPossiblePayloads {
  lsv: LivescanVendor;
  date: Date;
  applicantType: ApplicantType;
  location: Location;
  notes: string;
  vendor: Vendor;
  firstName: string, 
  middleName: string,
  lastName: string;
  dob: Date;
  ssn4: string;
  phone: string;
  email: string;
  gender: string;
  race: string;
  height: string;
  weight: string;
  eye: string;
  hair: string;
  pob: string;
  citizenship: string;
  reason: string;
  residence: string;
  id: number;
  fields: IDynamicFieldEntry[];
  signature: string;
  paymentMethod: string;
  cardNumber: string;
  cardExpiry: string;
  cardholderName: string;
  cardCode: string;
  cardZip: string;
};

type WizardAction = IBaseAction & Partial<IAllPossiblePayloads>;

export interface IWizardState {
  disclaimerAccepted?: boolean;

  lsv?: LivescanVendor;

  appointmentDateTime?: Date;

  applicantType?: number;
  location?: number;
  notes?: string;
  vendor?: number;

  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: Date;
  ssn4?: string;
  phone?: string;
  email?: string;
  gender?: string;
  race?: string;
  height?: string;
  weight?: string;
  eye?: string;
  hair?: string;
  pob?: string;
  citizenship?: string;
  reason?: string;
  residence?: string;

  waiverSignatures?: { id: number, fields: IDynamicFieldEntry[], signature: string }[];

  paymentMethod?: string;
  cardNumber?: string;
  cardExpiry?: string;
  cardholderName?: string;
  cardCode?: string;
  cardZip?: string;
  
  step?: number;
}

const reducer = (state: IWizardState = {}, action: WizardAction) => {
  switch (action.type) {
    case actionTypes.CLEAR:
      return {};
    case actionTypes.START:
      return {
        step: 1
      };
    case actionTypes.ACCEPT_DISCLAIMER:
      return {
        ...state,
        step: 2
      };
    case actionTypes.SELECT_LSV:
      return {
        ...state,
        step: 3,
        lsv: action.lsv
      };
    case actionTypes.SELECT_DATETIME:
      return {
        ...state,
        step: 4,
        appointmentDateTime: action.date
      };
    case actionTypes.SELECT_TYPE:
      return {
        ...state,
        step: 5,
        applicantType: action.applicantType,
        location: action.location,
        notes: action.notes,
        vendor: action.vendor,
        paymentMethod: action.paymentMethod,
      }
    case actionTypes.SET_DATA:
      return {
        ...state,
        step: 6,
        firstName: action.firstName, 
        middleName: action.middleName,
        lastName: action.lastName, 
        dob: action.dob,
        ssn4: action.ssn4,
        phone: action.phone,
        email: action.email,
        gender: action.gender,
        race: action.race,
        height: action.height,
        weight: action.weight,
        eye: action.eye,
        hair: action.hair,
        pob: action.pob,
        citizenship: action.citizenship,
        reason: action.reason,
        residence: action.residence
      };
    case actionTypes.SIGN_WAIVER:
      const newSig = {
        id: action.id,
        fields: action.fields,
        signature: action.signature
      };
      const waiverSignatures = (
        state.waiverSignatures ? 
        [...state.waiverSignatures, newSig] : 
        [newSig]
      );
      return {
        ...state,
        step: 7,
        waiverSignatures
      };
    case actionTypes.SET_PAYMENT:
      return {
        ...state,
        step: 8,
        cardNumber: action.cardNumber,
        cardExpiry: action.cardExpiry,
        cardholderName: action.cardholderName,
        cardCode: action.cardCode,
        cardZip: action.cardZip
      };
    case actionTypes.CONFIRMED:
      return {
        ...state,
        step: 9
      }
  }
  return state;
}

export default reducer;
