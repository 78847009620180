import React from 'react';
import {
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import WaiverDeleteModal from './DeleteModal';
import WaiverPreviewModal from './PreviewModal';
import { IReturn as WaiverSignature } from '../../api/resources/WaiverSignature';
import Moment from 'react-moment';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    });

interface IProps {
    index: number;
    signature: WaiverSignature;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
    showDeleteModal: boolean;
    showPreviewModal: boolean;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class WaiverItem extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
        showDeleteModal: false,
        showPreviewModal: false,
    }
    
    render() {
        const { index, signature, classes } = this.props;
        const { anchorEl, expanded, showDeleteModal, showPreviewModal } = this.state;

        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem button onClick={this.handleItemClick}>
                    <ListItemText primary={(
                        <>Signed waiver</>
                    )} secondary={(
                        <>{signature.signature_type === 'GENERATED' ? 'Generated on ' : 'Uploaded on '}<Moment format="ll">{signature.created}</Moment></>
                    )}/>
                    <ListItemSecondaryAction>
                        <IconButton onClick={this.handleExpandMenu}>
                            <MoreVert />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={expanded}
                    onClose={this.handleCloseMenu}
                >
                    <MenuItem onClick={this.handleDeleteClick}>Delete</MenuItem>
                </Menu>
                <WaiverDeleteModal open={showDeleteModal} signature={signature} onDismiss={this.handleDeleteDismiss} />
                <WaiverPreviewModal open={showPreviewModal} signature={signature} onDismiss={this.handlePreviewDismiss} />
            </React.Fragment>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }

    private handleDeleteClick = () => {
        this.setState({
            expanded: false, 
            showDeleteModal: true
        });
    }

    private handleItemClick = () => {
        this.setState({
            showPreviewModal: true
        });
    }

    private handleDeleteDismiss = (shouldRefresh: boolean) => {
        this.setState({showDeleteModal: false});
    }

    private handlePreviewDismiss = (shouldRefresh: boolean) => {
        this.setState({showPreviewModal: false});
    }
}

export default withTheme(withStyles(styles)(WaiverItem));