import { ApplicationState } from '../reducers';

export const getEnvironment = (state: ApplicationState) => {
    try {
        const { environment } = state;
        const { record } = environment;
        return record;
    }
    catch {
        return null;
    }
};

export const getEnvironmentType = (state: ApplicationState) => {
    try {
        const { environment } = state;
        const { recordType } = environment;
        return recordType;
    }
    catch {
        return null;
    }
}

export const isEnvironmentEmployer = (state: ApplicationState) => {
    try {
        const environmentType = getEnvironmentType(state);
        return environmentType === 'EMPLOYER';
    }
    catch {
        return false;
    }
}

export const isEnvironmentNetwork = (state: ApplicationState) => {
    try {
        const environmentType = getEnvironmentType(state);
        return environmentType === 'NETWORK';
    }
    catch {
        return false;
    }
}

export const shouldDisplayDisclaimer = (state: ApplicationState) => {
    try {
        const { display_disclaimer } = getEnvironment(state);
        return display_disclaimer;
    }
    catch {
        return null;
    }
}

export const shouldDisplayWaiver = (state: ApplicationState) => {
    try {
        const { display_waiver } = getEnvironment(state);
        return display_waiver;
    }
    catch {
        return null;
    }
}

export const selectExtraFields = (state: ApplicationState) => {
    try {
        const { extra_fields } = getEnvironment(state);
        return extra_fields ? extra_fields.split(',') : [];
    }
    catch {
        return [];
    }
}

export const shouldPromptPayment = (state: ApplicationState) => {
    try {
        const { prompt_payment } = getEnvironment(state);
        return prompt_payment;
    }
    catch {
        return null;
    }
}

export const defaultPaymentMethod = (state: ApplicationState) => {
    try {
        const { default_payment } = getEnvironment(state);
        return default_payment;
    }
    catch {
        return null;
    }
}

export const supportedPaymentOptions = (state: ApplicationState) => {
    try {
        const { payment_options } = getEnvironment(state);
        return payment_options;
    }
    catch {
        return null;
    }
}

export const getEnvironmentID = (state: ApplicationState) => {
    try {
        const { id } = getEnvironment(state);
        return id;
    }
    catch {
        return null;
    }
};

export const getEnvironmentName = (state: ApplicationState) => {
    try {
        const { name } = getEnvironment(state);
        return name;
    }
    catch {
        return null;
    }    
};

export const getEnvironmentLogo = (state: ApplicationState) => {
    try {
        const { logo_url } = getEnvironment(state);
        return logo_url;
    }
    catch {
        return null;
    }
};