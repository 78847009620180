import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    Paper,
    List
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as Applicant } from '../../../api/resources/Applicant';
import { actions as noteActions, IReturn as Note } from '../../../api/resources/Note';
import NoteItem from '../../../components/note/Item';
import { ApplicationState } from '../../../reducers';
import EmptyState from '../../EmptyState';
import NoteAddModal from '../../note/AddEditModal';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        itemPaper: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        metaData: {
            marginTop: 12,
        },  
        paperActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        paperTitle: {
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        spacer: {
            flex: 1,
        }
    });

interface IProps {
    notes?: Note[];
    applicant: Applicant;
};

interface IState {
    showAddModal: boolean;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { applicant } = ownProps;
  
    return {
        notes: applicant ? Object.values(state.note.data).filter((item)=>item.applicant===applicant.id) : [],    
    };
  };

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ApplicantNotesCard extends React.PureComponent<FullProps, IState> {
    public state = {
        showAddModal: false,
    }

    public componentDidMount() {
        this.loadDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { applicant } = this.props;

        if (prevProps.applicant != applicant)
            this.loadDependencies();
    }

    public async loadDependencies() {
        const { applicant, dispatch } = this.props;

        if (applicant)
            dispatch(noteActions.index(undefined, undefined, {applicant:applicant.id}));
    }
    
    render() {
        const { applicant, classes, notes } = this.props;
        const { showAddModal } = this.state;

        const notesMarkup = notes ? notes.map((note, index) => (
            <NoteItem index={index} note={note} key={index} />
        )) : [];
        
        return (
            <Paper className={classes.itemPaper}>
                <Typography variant="subtitle1" className={classes.paperTitle}>
                    Notes
                </Typography>
                {notesMarkup && notesMarkup.length > 0 ? (
                    <List>{notesMarkup}</List>
                ) : (
                    <EmptyState icon='error' text='None to display' />
                )}

                <div className={classes.spacer}></div>
                <div className={classes.paperActions}>
                    <Button color="primary" onClick={this.onAdd}>Add</Button>
                </div>
                <NoteAddModal applicant={applicant} open={showAddModal} onDismiss={this.handleAddDismiss} />
            </Paper>
        )
    }

    private onAdd = () => {
        this.setState({showAddModal: true});
    }

    private handleAddDismiss = () => {
        this.setState({showAddModal: false});
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(ApplicantNotesCard)));