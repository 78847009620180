import React from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        
    });

interface IProps {
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles>;
class UserDeleteModal extends React.PureComponent<FullProps> {
    render() {
        const { open } = this.props;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete User?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(UserDeleteModal));