import { CssBaseline, Grid, Paper, Typography, Theme, Link } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import Footer from './Footer';

interface IProps {
	title?: string;
	name: string;
	logo?: string;
}

const styles = (theme: Theme) =>
	createStyles({
		logo: {
			display: 'block',
			height: '75px',
			marginBottom: theme.spacing(4),
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: theme.spacing(8),
			textAlign: 'center',
		},
		logoText: {
			height: '100px',
			marginBottom: theme.spacing(2),
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: theme.spacing(8),
			textAlign: 'center',
		},
		main: {
			display: 'block', // Fix IE 11 issue.
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			[theme.breakpoints.up(400 + theme.spacing(2) * 2)]: {
				marginLeft: 'auto',
				marginRight: 'auto',
				width: 400,
			},
			width: 'auto',
		},
		paper: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		footer: {
			marginTop: theme.spacing(2),
			padding: theme.spacing(2),
			backgroundColor: theme.palette.background.default,
		},
	});

class LoginLayout extends React.PureComponent<IProps & WithStyles<typeof styles>> {
    render() {
        const { children, classes, title, name, logo } = this.props;

        return (
            <main className={classes.main}>
                <CssBaseline />
                {logo && (
					<img src={logo} className={classes.logo} alt={`${name} logo`} />
				)}
				
				{!logo && (
					<Typography className={classes.logoText} component="h1" variant="h2">{name}</Typography>
				)}
				
				<Paper className={classes.paper}>
					{title && (
						<Grid container={true} justify="center">
							<Typography component="h2" variant="h5">
								{title}
							</Typography>
						</Grid>
					)}
					{children}
				</Paper>

				<Footer title='Cryms' description='Streamlined employment application background check workflows' />
            </main>
        )
    }
}

export default withStyles(styles)(LoginLayout);