import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    Paper,
    List
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as Applicant } from '../../../api/resources/Applicant';
import { actions as waiverSignatureActions, IReturn as WaiverSignature } from '../../../api/resources/WaiverSignature';
import WaiverItem from '../../../components/waiver/Item';
import { ApplicationState } from '../../../reducers';
import EmptyState from '../../EmptyState';
import WaiverUploadModal from '../../waiver/UploadModal';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        itemPaper: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        metaData: {
            marginTop: 12,
        },  
        paperActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        paperTitle: {
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        spacer: {
            flex: 1,
        }
    });

interface IProps {
    signatures?: WaiverSignature[];
    applicant: Applicant;
};

interface IState {
    anchorEl?: React.ReactNode;
    expanded: boolean;
    showUploadModal: boolean;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { applicant } = ownProps;
  
    return {
        signatures: applicant ? Object.values(state.waiversignature.data).filter((item)=>item.applicant===applicant.id) : [],    
    };
  };

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ApplicantWaiversCard extends React.PureComponent<FullProps, IState> {
    public state = {
        anchorEl: null,
        expanded: false,
        showUploadModal: false,
    }

    public componentDidMount() {
        this.loadDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { applicant } = this.props;

        if (prevProps.applicant != applicant)
            this.loadDependencies();
    }

    public async loadDependencies() {
        const { applicant, dispatch } = this.props;

        if (applicant)
            dispatch(waiverSignatureActions.index(undefined, undefined, {applicant:applicant.id}));
    }
    
    render() {
        const { applicant, classes, signatures } = this.props;
        const { anchorEl, expanded, showUploadModal } = this.state;

        const signaturesMarkup = signatures ? signatures.map((signature, index) => (
            <WaiverItem index={index} signature={signature} key={index} />
        )) : [];
        
        return (
            <Paper className={classes.itemPaper}>
                <Typography variant="subtitle1" className={classes.paperTitle}>
                    Waivers
                </Typography>
                {signaturesMarkup && signaturesMarkup.length > 0 ? (
                    <List>{signaturesMarkup}</List>
                ) : (
                    <EmptyState icon='error' text='None to display' />
                )}
                <div className={classes.paperActions}>
                    <Button color="primary" onClick={this.onUploadClick}>Upload</Button>
                </div>
                <WaiverUploadModal applicant={applicant} open={showUploadModal} onDismiss={this.handleUploadDismiss} />
            </Paper>
        )
    }

    private handleExpandMenu = (event: React.MouseEvent) => {
        this.setState({
            anchorEl: event.target, 
            expanded: true
        });
    }

    private handleCloseMenu = () => {
        this.setState({expanded: false});
    }

    private onUploadClick = () => {
        this.setState({showUploadModal: true});
    }

    private handleUploadDismiss = () => {
        this.setState({showUploadModal: false});
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(ApplicantWaiversCard)));