import React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  Card,
  CardContent,
  Divider,
  Fab,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  withTheme, 
  WithTheme 
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Add as AddIcon, NavigateNext } from '@material-ui/icons';
import '../../styles/App.css';
import MainLayout from '../../components/MainLayout';
import LoadIndicator from '../../components/LoadIndicator';
// import UserCard from '../components/User/Card';
import RoleGate from '../../components/RoleGate';
import EntityItem from '../../components/entity/Item';
import EntityAddModal from '../../components/entity/AddEditModal';
import { actions as locationActions, IReturn as Location } from '../../api/resources/Location';
import { ApplicationState } from '../../reducers';

const styles = (theme: Theme) =>
    createStyles({
        fab: {
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },      
        searchBar: {
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0',
        },
        filterBox: {
          minWidth: 120,
        },
        filler: {
          display: 'flex',
          flex: 1
        }
    });

interface IProps {
  entities: Location[];
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
  return {
    entities: Object.values(state.location.data),
  };
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class Entities extends React.PureComponent<FullProps> {
  state = {
    isLoading: false,
    filter: 'all',
    search: '',
    showAddModal: false,
  }
  
  public async componentDidMount() {
    this.retrieveData();
  }

  public async retrieveData() {
    const { dispatch } = this.props;
    const { search } = this.state;
    if (search) {
      dispatch(locationActions.index(undefined, undefined, {search}));
    }
    else {
      dispatch(locationActions.index(undefined, undefined, {}));
    }
  }
  
  render() {
    const { classes, entities } = this.props;
    const { isLoading, search, filter, showAddModal } = this.state;

    const entityMarkup = entities
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((entity) => !search || entity.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
      .map((entity, index) => {
        return (
          <EntityItem index={index} entity={entity} key={index} />
        )
      });
    
    return (
      <MainLayout title='Manage Entities'>
          <RoleGate role='admin' />
          {isLoading ? (
            <LoadIndicator />
          ) : (
            <>
              <div className={classes.searchBar}>
                <div className={classes.filler} />
                <TextField label="Search" variant="filled" onChange={(evt) => this.setState({search:evt.target.value})} />
                {/* <FormControl variant="filled" className={classes.filterBox}>
                  <InputLabel id="filter-label">Filter</InputLabel>
                  <Select
                    labelId="filter-label"
                    value={filter}
                    onChange={this.handleFilterChange}
                  >
                    <MenuItem value={'all'}><em>None</em></MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>
                  </Select>
                </FormControl> */}
              </div>
              <Card>            
                <CardContent>
                  <List>
                    { entityMarkup }                
                  </List>
                </CardContent>
              </Card>
            </>
          )}
          <Fab color="primary" aria-label="add" className={classes.fab} onClick={this.handleAddClick}>
            <AddIcon />
          </Fab>
          <EntityAddModal open={showAddModal} onDismiss={this.handleDismiss} />
      </MainLayout>
    );
  }

  private handleFilterChange = (event: ChangeEvent<{name?: string, value: unknown}>) => {
    this.setState({filter: event.target.value});
  }

  private handleAddClick = () => {
    this.setState({showAddModal: true});
  }

  private handleDismiss = (shouldRefresh: boolean) => {
    this.setState({showAddModal: false});
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(Entities)));