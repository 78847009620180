import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	agency: string;
	description: string;
	id: number;
	lsv: number;
	note: string;
	ori: number;
	pricing: number;
	prompt_location: boolean;
	prompt_vendor: boolean;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "applicanttype",
	rootUrl: getAPIUrl("/applicanttypes"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});