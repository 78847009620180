import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IDynamicFieldEntry {
	name: string;
	value: string;
}

export interface IReturn {
	applicant: number;
	created: string;
	field_data: IDynamicFieldEntry[];
	id: number;
	signature_file: string;
	signature_type: string;
	signature_url: string;
	waiver: number;
	waiver_file: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "waiversignature",
	rootUrl: getAPIUrl("/waiversignatures"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});