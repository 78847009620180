import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as Vendor, actions as vendorActions } from '../../api/resources/Vendor';

const styles = (theme: Theme) =>
    createStyles({
        
    });

type PartialVendor = Partial<Vendor>;

interface IState {
    vendor?: PartialVendor;
}

interface IProps {
    vendor?: Vendor;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class VendorAddEditModal extends React.Component<FullProps, IState> {
    public state = {
        vendor: {} as PartialVendor,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            const { vendor } = this.props;
        
            this.setState({
                vendor: vendor || {} as PartialVendor
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open } = this.props;
        const { vendor } = this.state;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{vendor?.id ? (<>Edit Vendor</>) : (<>Add Vendor</>)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {vendor?.id ? (
                            <>
                                Edit vendor details below.
                            </>
                        ) : (
                            <>
                                Enter new vendor details below.
                            </>
                        )}                        
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Vendor Name"
                        fullWidth
                        value={vendor.name}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                name: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="identifier"
                        label="Identifier"
                        fullWidth
                        value={vendor.identifier}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                identifier: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="street1"
                        label="Street Address"
                        fullWidth
                        value={vendor.street1}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                street1: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="street2"
                        label="Suite / PO"
                        fullWidth
                        value={vendor.street2}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                street2: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="city"
                        label="City"
                        fullWidth
                        value={vendor.city}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                city: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="state"
                        label="State"
                        fullWidth
                        value={vendor.state}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                state: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="zip_code"
                        label="Zip"
                        fullWidth
                        value={vendor.zip_code}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                zip_code: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="contact_email"
                        label="Email"
                        fullWidth
                        type="email"
                        value={vendor.contact_email}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                contact_email: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="contact_first"
                        label="Contact First Name"
                        fullWidth
                        value={vendor.contact_first_name}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                contact_first_name: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="contact_last"
                        label="Contact Last Name"
                        fullWidth
                        value={vendor.contact_last_name}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                contact_last_name: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="contact_phone"
                        label="Contact Phone"
                        fullWidth
                        type="phone"
                        value={vendor.contact_phone}
                        onChange={(evt) => {
                            const vendor = {
                                ...this.state.vendor,
                                contact_phone: evt.target.value
                            };
                            this.setState({vendor});
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { dispatch } = this.props;
        const { vendor } = this.state;
        if (vendor?.id) {
            await dispatch(vendorActions.update(vendor.id, vendor)).catch(console.error);
        } 
        else {
            await dispatch(vendorActions.create(undefined, vendor)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(VendorAddEditModal));