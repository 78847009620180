import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Theme, 
    withStyles, 
    WithStyles 
} from '@material-ui/core';
import { IReturn as Applicant, actions as applicantActions } from '../../api/resources/Applicant';

const styles = (theme: Theme) =>
    createStyles({
        
    });

type PartialApplicant = Partial<Applicant>;

interface IState {
    applicant?: PartialApplicant;
}

interface IProps {
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class ApplicantAddModal extends React.Component<FullProps, IState> {
    public state = {
        applicant: {
            locations: [],
        } as PartialApplicant,
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        if (nextProps.open && !this.props.open) {
            this.setState({
                applicant: {
                    locations: [],
                } as PartialApplicant
            });

            return true;
        }
        return nextProps !== this.props || nextState !== this.state;
    }

    render() {
        const { open } = this.props;
        const { applicant } = this.state;
        
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Applicant</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter new applicant details below.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="transaction_id"
                        label="Transaction ID"
                        fullWidth
                        value={applicant.transaction_id}
                        onChange={(evt) => {
                            const applicant = {
                                ...this.state.applicant,
                                transaction_id: evt.target.value
                            };
                            this.setState({applicant});
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="first_name"
                        label="First Name"
                        fullWidth
                        value={applicant.first_name}
                        onChange={(evt) => {
                            const applicant = {
                                ...this.state.applicant,
                                first_name: evt.target.value
                            };
                            this.setState({applicant});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="last_name"
                        label="Last Name"
                        fullWidth
                        value={applicant.last_name}
                        onChange={(evt) => {
                            const applicant = {
                                ...this.state.applicant,
                                last_name: evt.target.value
                            };
                            this.setState({applicant});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        fullWidth
                        value={applicant.email}
                        onChange={(evt) => {
                            const applicant = {
                                ...this.state.applicant,
                                email: evt.target.value
                            };
                            this.setState({applicant});
                        }}
                        required
                    />
                    <TextField
                        margin="dense"
                        id="phone"
                        label="Phone"
                        fullWidth
                        value={applicant.phone}
                        onChange={(evt) => {
                            const applicant = {
                                ...this.state.applicant,
                                phone: evt.target.value
                            };
                            this.setState({applicant});
                        }}
                        required
                    />
                    {/* <TextField
                        margin="dense"
                        id="appointment_date"
                        label="Appointment Date"
                        type="date"
                        fullWidth
                        value={applicant.appointment_date}
                        onChange={(evt) => {
                            const applicant = {
                                ...this.state.applicant,
                                appointment_date: evt.target.value
                            };
                            this.setState({applicant});
                        }}
                    /> */}
                    {/* <TextField
                        margin="dense"
                        id="tcn"
                        label="TCN"
                        fullWidth
                        value={applicant.tcn}
                        onChange={(evt) => {
                            const applicant = {
                                ...this.state.applicant,
                                tcn: evt.target.value
                            };
                            this.setState({applicant});
                        }}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = () => {
        this.saveAndDismiss();
    }

    private async saveAndDismiss() {
        const { dispatch } = this.props;
        const { applicant } = this.state;
        await dispatch(applicantActions.create(undefined, applicant)).catch(console.error);
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(ApplicantAddModal));