import React from 'react';
import {
    Chip,
    Theme,
    Typography,
    withTheme, 
    WithTheme,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField,
    Button,
    Divider,
    ExpansionPanelActions,
    Grid
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
    createStyles({
        panelContent: {
            width: '100%',
        }
    });

const filterToQueryMapping = Object({
    first_name: 'first_name',
    last_name: 'last_name',
    appointment_after: 'appointment__date_time__gte',
    appointment_before: 'appointment__date_time__lte',
    submit_after: 'submitted__gte',
    submit_before: 'submitted__lte',
    status_code: 'status_code__description',
    status_after: 'applicanthistory__date__gte',
    status_before: 'applicanthistory__date__lte',
    dob: 'dob',
    ssn4: 'ssn4',
    identifier: 'identification__identifier',
    transaction_id: 'transaction_id',
    applicant_type: 'applicant_type__ori__description',
    role: 'position',
    location_name: 'locations__name__icontains',
});

const queryToFilterMapping = Object({
    first_name: 'first_name',
    last_name: 'last_name',
});

interface IProps {
    query: any;
    onChange: (query: any) => void;
};

interface IFilters {
    first_name?: string;
    last_name?: string;
    appointment_after?: string;
    appointment_before?: string;
    submit_after?: string;
    submit_before?: string;
    status_code?: string;
    status_after?: string;
    status_before?: string;
    dob?: string;
    ssn4?: string;
    identifier?: string;
    transaction_id?: string;
    applicant_type?: string;
    role?: string;
    location_name?: string;
}

interface IState {
    filters: IFilters;
}

type FullProps = IProps & WithStyles<typeof styles> & WithTheme;
class SearchPanel extends React.PureComponent<FullProps, IState> {
    public state = {
        filters: {} as IFilters,
    }
    
    componentDidMount() {
        this.populateFilters();
    }

    populateFilters() {
        const { query } = this.props;
        
        this.setState({
            // TODO: populate initial filters
        })
    }

    render() {
        const { classes, query } = this.props;
        const { filters } = this.state;

        const searchChips = Object.entries(query).map((entry, index) => {
            return (
                <Chip size="small" label={`${entry[0]}: ${entry[1]}`} />
            )
        });
        
        return (
            <ExpansionPanel defaultExpanded={Object.keys(query).length <= 0}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="search-panel-content"
                    id="search-header"
                >
                    <Typography>Search:</Typography>
                    {searchChips}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.panelContent}>
                                <Typography variant="subtitle2">
                                    Applicant Name
                                </Typography>
                                <TextField
                                    margin="dense"
                                    id="first_name"
                                    label="First Name"
                                    fullWidth
                                    value={filters.first_name}
                                    onChange={(evt) => this.updateFilter('first_name', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="last_name"
                                    label="Last Name"
                                    fullWidth
                                    value={filters.last_name}
                                    onChange={(evt) => this.updateFilter('last_name', evt.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.panelContent}>
                                <Typography variant="subtitle2">
                                    Appointment
                                </Typography>
                                <TextField
                                    margin="dense"
                                    id="appointment_after"
                                    label="Date after"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    type="date"
                                    value={filters.appointment_after}
                                    onChange={(evt) => this.updateFilter('appointment_after', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="appointment_before"
                                    label="Date before"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    type="date"
                                    value={filters.appointment_before}
                                    onChange={(evt) => this.updateFilter('appointment_before', evt.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.panelContent}>
                                <Typography variant="subtitle2">
                                    Submission
                                </Typography>
                                <TextField
                                    margin="dense"
                                    id="submit_after"
                                    label="Date after"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    type="date"
                                    value={filters.submit_after}
                                    onChange={(evt) => this.updateFilter('submit_after', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="submit_before"
                                    label="Date before"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    type="date"
                                    value={filters.submit_before}
                                    onChange={(evt) => this.updateFilter('submit_before', evt.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.panelContent}>
                                <Typography variant="subtitle2">
                                    Hire Status
                                </Typography>
                                <TextField
                                    margin="dense"
                                    id="status"
                                    label="Status"
                                    fullWidth
                                    value={filters.status_code}
                                    onChange={(evt) => this.updateFilter('status_code', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="status_after"
                                    label="Date after"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    type="date"
                                    value={filters.status_after}
                                    onChange={(evt) => this.updateFilter('status_after', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="status_before"
                                    label="Date before"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    type="date"
                                    value={filters.status_before}
                                    onChange={(evt) => this.updateFilter('status_before', evt.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.panelContent}>
                                <Typography variant="subtitle2">
                                    Applicant Identifiers
                                </Typography>
                                <TextField
                                    margin="dense"
                                    id="dob"
                                    label="Date of Birth"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    type="date"
                                    value={filters.dob}
                                    onChange={(evt) => this.updateFilter('dob', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="ssn4"
                                    label="SSN"
                                    fullWidth
                                    value={filters.ssn4}
                                    onChange={(evt) => this.updateFilter('ssn4', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="identifier"
                                    label="DL/PP number"
                                    fullWidth
                                    value={filters.identifier}
                                    onChange={(evt) => this.updateFilter('identifier', evt.target.value)}
                                />
                                
                            </div>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            <div className={classes.panelContent}>
                                <Typography variant="subtitle2">
                                    Background Check
                                </Typography>
                                
                                <TextField
                                    margin="dense"
                                    id="transaction_id"
                                    label="TCN"
                                    fullWidth
                                    value={filters.transaction_id}
                                    onChange={(evt) => this.updateFilter('transaction_id', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="applicant_type"
                                    label="Applicant Type"
                                    fullWidth
                                    value={filters.applicant_type}
                                    onChange={(evt) => this.updateFilter('applicant_type', evt.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="role"
                                    label="Role"
                                    fullWidth
                                    value={filters.role}
                                    onChange={(evt) => this.updateFilter('role', evt.target.value)}
                                />
                                
                            </div>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            <div className={classes.panelContent}>
                                <Typography variant="subtitle2">
                                    Work Location
                                </Typography>
                                
                                <TextField
                                    margin="dense"
                                    id="location_name"
                                    label="Work Location"
                                    fullWidth
                                    value={filters.location_name}
                                    onChange={(evt) => this.updateFilter('location_name', evt.target.value)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button onClick={this.performSearch} size="small" color="primary">Search</Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        )
    }

    public updateFilter = (field: string, value: string) => {
        const filters = {
            ...this.state.filters,
            [field]: value
        };
        // Object.
        // filters[field] = value;
        this.setState({filters});
        // const location = {
        //     ...this.state.location,
        //     name: evt.target.value
        // };
        // this.setState({location});
    }

    public performSearch = () => {
        const { onChange } = this.props;
        const { filters } = this.state;

        onChange(this.transformFilters(filters));
    }

    private transformFilters(filters: IFilters): any {
        const filterEntries = Object.entries(filters).filter((entry) => entry[1] !== '');
        const queryEntries = filterEntries.map((entry) => [filterToQueryMapping[entry[0]], entry[1]])
        return Object.fromEntries(queryEntries);
    }
}

export default withTheme(withStyles(styles)(SearchPanel));