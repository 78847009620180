import { Button, Grid, Theme, Paper, FormControl, InputLabel, Select, TextField, MenuItem, Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import React from 'react';
import AnonLayout from '../../../components/AnonLayout';
import '../../../styles/App.css';
import { actions as newWizardActions } from '../../../actions/newWizard';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IReturn as LivescanVendor } from '../../../api/resources/LivescanVendor';
import { actions as applicantTypeActions, IReturn as ApplicantType } from '../../../api/resources/ApplicantType';
import { actions as locationActions, IReturn as Location } from '../../../api/resources/Location';
import { ApplicationState } from '../../../reducers';
import { getEnvironmentID, isEnvironmentEmployer, shouldPromptPayment, defaultPaymentMethod, supportedPaymentOptions } from '../../../selectors/environment';
import { getLSV } from '../../../selectors/wizard';
import { actions as vendorActions, IReturn as Vendor } from '../../../api/resources/Vendor';
import { Dictionary } from 'lodash';
import RequiresActiveWizard from '../../../components/RequiresActiveWizard';

const PAYMENT_METHODS = {
	'CC': 'Pay at Appointment',
	'OL': 'Pay Online',
	'IN': 'Invoice Company',
} as Dictionary<string>;

interface IProps {
	applicationTypes: ApplicantType[];
	environmentID?: number;
	isEnvironmentEmployer?: boolean;
	jobLocations: Location[];
	vendors: Vendor[];
	lsv?: LivescanVendor;
	shouldPromptPayment?: boolean;
	defaultPaymentMethod?: string;
	supportedPaymentOptions?: string;
}

interface IState {
	applicantType?: number;
	jobLocation?: number;
	vendor?: number;
	paymentMethod?: string;
	notes: string;
}

const mapStateToProps = (state: ApplicationState) => ({
	applicationTypes: Object.values(state.applicanttype.data),
	environmentID: getEnvironmentID(state),
	isEnvironmentEmployer: isEnvironmentEmployer(state),
	jobLocations: Object.values(state.location.data),
	vendors: Object.values(state.vendor.data),
	lsv: getLSV(state),
	shouldPromptPayment: shouldPromptPayment(state),
	defaultPaymentMethod: defaultPaymentMethod(state),
	supportedPaymentOptions: supportedPaymentOptions(state),
});

const styles = (theme: Theme) =>
	createStyles({
		paper: {
			// alignItems: 'center',
			// display: 'flex',
			// flexDirection: 'column',
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		},
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
			'&>div': {
				marginTop: theme.spacing(2),
			},
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		centeredButton: {
			marginTop: theme.spacing(2),
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
			display: 'flex',
			flex: 1,
		},
	});

type FullProps = WithStyles<typeof styles> & IProps & IDispatchProp & RouteComponentProps;
class SchedulerSelections extends React.PureComponent<FullProps, IState> {
  
  state = {
	applicantType: undefined as number|undefined,
	jobLocation: undefined as number|undefined,
	paymentMethod: undefined as string|undefined,
	vendor: undefined as number|undefined,
	notes: '' as string,
  };	

  public componentDidMount() {
	this.loadDependencies();
  }

  public componentDidUpdate(prevProps: FullProps) {
	const { environmentID } = this.props;

	if (prevProps.environmentID != environmentID)
	  this.loadDependencies();
  }

  private async loadDependencies() {
	const { dispatch, environmentID, isEnvironmentEmployer, lsv, defaultPaymentMethod } = this.props;
	if (environmentID && lsv) {
		if (isEnvironmentEmployer) {
			dispatch(applicantTypeActions.index(undefined, undefined, {lsv: lsv.id, employer: environmentID})).catch(console.error);
			dispatch(locationActions.index(undefined, undefined, {employer: environmentID})).catch(console.error);
			dispatch(vendorActions.index(undefined, undefined, {employer: environmentID})).catch(console.error);
		}
		else {
			dispatch(applicantTypeActions.index(undefined, undefined, {lsv: lsv.id, network: environmentID})).catch(console.error);
		}
		// this.setState({loading: false});
		this.setState({paymentMethod: defaultPaymentMethod});
	}	
  }
  
  render() {
    const { classes, applicationTypes, isEnvironmentEmployer, jobLocations, vendors, shouldPromptPayment, defaultPaymentMethod, supportedPaymentOptions } = this.props;
	const { applicantType, jobLocation, notes, paymentMethod, vendor } = this.state;

	const applicantTypeMap = applicationTypes.sort((a, b) => a.description.localeCompare(b.description)).map((entry) => (
		<MenuItem value={entry.id}>{entry.agency && <>{entry.agency} - </>}{entry.description} - ${entry.pricing}</MenuItem>
	));

	const locationMap = jobLocations.sort((a, b) => a.name.localeCompare(b.name)).map((entry) => (
		<MenuItem value={entry.id}>{entry.identifier && `${entry.identifier} - `}{entry.name}</MenuItem>
	));

	const vendorMap = vendors.sort((a, b) => a.name.localeCompare(b.name)).map((entry) => (
		<MenuItem value={entry.id}>{entry.name}</MenuItem>
	));

	const methodMap = supportedPaymentOptions ? supportedPaymentOptions.split(",").map((option) => (
		<MenuItem value={option}>{PAYMENT_METHODS[option]}</MenuItem>
	)) : [];

	const aType = applicationTypes.find((entry) => entry.id === applicantType);
	const valid = (applicantType && (!isEnvironmentEmployer || ((!aType?.prompt_location || jobLocation) && (!aType?.prompt_vendor || vendor))));

	return (
      <AnonLayout title='New Application' subtitle='Please provide the information below'>
		<RequiresActiveWizard/>
        <Paper className={classes.paper}>
			<form className={classes.form} noValidate autoComplete="off">
				<FormControl variant="filled" className={classes.formControl}>
					<InputLabel id="type-label">Select your applicant type</InputLabel>
					<Select
					labelId="type-label"
					id="select-type"
					value={applicantType}
					onChange={(evt) => this.setState({applicantType: evt.target.value as number})}
					>
						{applicantTypeMap}
					</Select>
				</FormControl>
				{isEnvironmentEmployer && aType?.note && (
					<Typography variant="h6" className={classes.formControl}>
						{aType.note}
					</Typography>
				)}
				{isEnvironmentEmployer && aType?.prompt_location && (
					<FormControl variant="filled" className={classes.formControl}>
						<InputLabel id="location-label">Select your job/volunteer location</InputLabel>
						<Select
						labelId="location-label"
						id="select-location"
						value={jobLocation}
						onChange={(evt) => this.setState({jobLocation: evt.target.value as number})}
						>
							{locationMap}
						</Select>
					</FormControl>
				)}
				{isEnvironmentEmployer && aType?.prompt_vendor && (
					<FormControl variant="filled" className={classes.formControl}>
						<InputLabel id="vendor-label">Select your vendor</InputLabel>
						<Select
						labelId="vendor-label"
						id="select-vendor"
						value={vendor}
						onChange={(evt) => this.setState({vendor: evt.target.value as number})}
						>
							{vendorMap}
						</Select>
					</FormControl>
				)}
				{shouldPromptPayment && (
					<FormControl variant="filled" className={classes.formControl}>
						<InputLabel id="payment-label">Select payment method</InputLabel>
						<Select
						labelId="payment-label"
						id="select-payment"
						value={paymentMethod}
						defaultValue={defaultPaymentMethod}
						onChange={(evt) => this.setState({paymentMethod: evt.target.value as string})}
						>
							{methodMap}
						</Select>
					</FormControl>
				)}
				<TextField 
					className={classes.formControl} 
					name="notes" 
					label="Any notes or special requests? (optional)" 
					variant="filled" 
					fullWidth 
					value={notes}
					onChange={(evt) => this.setState({notes:evt.target.value})}
				/>
			</form>
			<Grid container={true} className={classes.centeredButton} justify="center" spacing={2}>
				<Button disabled={!valid} onClick={this.onProceed} variant="contained" color="primary">
					Continue
				</Button>
			</Grid>
		</Paper>
      </AnonLayout>
    );
  }

  onProceed = () => {
	const { applicationTypes, dispatch, history, isEnvironmentEmployer } = this.props;
	const { 
		applicantType,
		jobLocation,
		notes,
		vendor,
		paymentMethod
	} = this.state;

	const aType = applicationTypes.find((entry) => entry.id === applicantType);
	
	if (applicantType === undefined || (isEnvironmentEmployer && ((aType?.prompt_location && jobLocation === undefined) || (aType?.prompt_vendor && vendor === undefined)))) {
		return;
	}

	console.log("Payment method", paymentMethod);
	dispatch(newWizardActions.selectType(applicantType, jobLocation, notes, vendor, paymentMethod));
	history.push("/data");
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SchedulerSelections)));