import { 
    Avatar,
    Button,
    Collapse, 
    Divider, 
    Link, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    Paper,
    Theme,
    Typography
} from '@material-ui/core';
import {
	AccountCircle,
	AttachMoney,
	BorderAll,
	Dashboard,
	ExitToApp,
	ExpandLess,
	ExpandMore,
	List as ListIcon,
	Lock,
	Smartphone,
	Help,
	ShoppingCart
} from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
// import { SessionState } from '../../global/mysession';
import { ApplicationState } from '../../reducers';
import { getSessionEmail } from '../../selectors';

const styles = (theme: Theme) =>
	createStyles({
		account: {
			display: 'inline-block',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
        },
        navHeader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '16px',
        },
		nested: {
			paddingLeft: theme.spacing(4),
		},
		root: {
			backgroundColor: theme.palette.background.paper,
			maxWidth: 360,
			width: '100%',
		},
	});

interface IProps {
	sessionEmail: string;
}

interface IState {
	open: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		sessionEmail: getSessionEmail(state),
	};
};

class Navigation extends React.PureComponent<IProps & WithStyles<typeof styles>, IState> {
	public state = {
		open: false,
	};

	public render() {
		const { classes, sessionEmail: email } = this.props;
		const { open } = this.state;
        const { pathname } = window.location;
		return (
			<React.Fragment>
                <div className={classes.navHeader}>
                    <Avatar>{ email?.slice(0,2).toUpperCase() }</Avatar>
                    <Typography variant="subtitle1">
                        { email }
                    </Typography>
                    <Button component={RouterLink} to="/account">
                        {'Manage Account'}
                    </Button>
                </div>
				<Divider />
				<List>
					<ListItem button={true} component={RouterLink} to="/dashboard" selected={pathname.startsWith('/dashboard')}>
						<ListItemIcon>
							<Dashboard />
						</ListItemIcon>
						<ListItemText primary={('Dashboard')} />
					</ListItem>
					<ListItem button={true} component={RouterLink} to="/admin" selected={pathname.startsWith('/mytests')}>
						<ListItemIcon>
							<ListIcon />
						</ListItemIcon>
						<ListItemText primary={('Administrative')} />
					</ListItem>
				</List>
				<Divider />
				<List>
					<ListItem button={true} component={RouterLink} to="/logout" selected={pathname.startsWith('/devices')}>
						<ListItemIcon>
							<Smartphone />
						</ListItemIcon>
						<ListItemText primary={('Log Out')} />
					</ListItem>
				</List>
			</React.Fragment>
		);
	}

	private handleAccountClick = () => {
		this.setState(state => ({
			open: !state.open,
		}));
	};
}

export default connect(mapStateToProps)(withStyles(styles)(Navigation));
