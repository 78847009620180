import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Card,
    CardActions,
    CardContent,
    Input,
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    InputProps,
    TextField,
    IconButton,
    List
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import {
    Delete,
} from '@material-ui/icons';
// import { IReturn as LivescanVendor, actions as lsvActions } from '../../../api/resources/LivescanVendor';
import { IReturn as BlockedTime, actions as blockedTimeActions } from '../../../api/resources/BlockedTime';
import BlockItem from './Item';
import Moment from 'react-moment';
import moment from 'moment';
import EmptyState from '../../EmptyState';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        metaData: {
            marginTop: 12,
        },
        timeField: {
            marginRight: theme.spacing(1),
            flexGrow: 1,
        },
        timeRow: {
            display: 'flex',
        },
        spacer: {
            flex: 1,
        }
    });

interface IProps {
    blocks: BlockedTime[];
};

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class AvailabilityBlocksCard extends React.PureComponent<FullProps> {
    render() {
        const { blocks, classes } = this.props;
        
        return (
            <Card>
                <CardContent>
                    <List>
                        {blocks.length > 0 ? blocks.map((block, index) => (
                            <BlockItem block={block} index={index} />
                        )) : (
                            <EmptyState icon='error' text='No blocked times to display' />
                        )}
                    </List>
                </CardContent>
            </Card>
        )
    }
}

export default connect()(withTheme(withStyles(styles)(AvailabilityBlocksCard)));