import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Card,
    CardActions,
    CardContent,
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as LivescanVendor, actions as lsvActions } from '../../../api/resources/LivescanVendor';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';


const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        metaData: {
            marginTop: 12,
        },  
    });

interface IProps {
    lsv: LivescanVendor;
};

interface IState {
    editing: boolean;
}

const FormSchema = Yup.object().shape({
	name: Yup.string().required(),
    office_email: Yup.string().email().required(),
    office_phone: Yup.string().required(),
    url: Yup.string().url().required(),
    office_street1: Yup.string().required(),
    office_street2: Yup.string(),
    office_city: Yup.string().required(),
    office_state: Yup.string().required(),
    office_postal: Yup.string().required(),
});

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ProfileSummaryCard extends React.PureComponent<FullProps, IState> {
    public state = {
        editing: false
    }
    
    render() {
        const { lsv, classes } = this.props;
        const { editing } = this.state;

        return (
            <Card>
                
                    {editing ? (
                        
                        <Formik
                            initialValues={{
                                name: lsv.name,
                                office_email: lsv.office_email,
                                office_phone: lsv.office_phone,
                                url: lsv.url,
                                office_street1: lsv.office_street1,
                                office_street2: lsv.office_street2,
                                office_city: lsv.office_city,
                                office_state: lsv.office_state,
                                office_postal: lsv.office_postal
                            }}
                            validationSchema={FormSchema}
                            onSubmit={(values, {setSubmitting}) => {
                                this.onSave(values);
                            }}
                        >
                          {({ submitForm, isSubmitting }) => (
                              <Form>
                                <CardContent>
                                    <Grid container spacing={2} className={classes.metaData}>
                                        <Grid item sm={6} xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">Name</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="name" fullWidth required autoFocus />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">Email</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="office_email" fullWidth required />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">Phone</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="office_phone" fullWidth required />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">URL</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="url" fullWidth required />
                                                </Grid>
                                                
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">Address 1</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="office_street1" fullWidth required />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">Address 2</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="office_street2" fullWidth />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">City</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="office_city" fullWidth required />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">State</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="office_state" fullWidth required />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">Zip Code</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field component={TextField} name="office_postal" fullWidth required />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button color="primary" onClick={submitForm}>Save</Button>
                                    <Button onClick={this.onCancel}>Cancel</Button>
                                </CardActions>
                            </Form>
                        )}
                        </Formik>
                    ) : (
                        <>
                            <CardContent>
                                <Grid container spacing={2} className={classes.metaData}>
                                    <Grid item sm={6} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Name</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.name}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Email</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.office_email}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Phone</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.office_phone}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">URL</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.url}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Address 1</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.office_street1}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Address 2</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.office_street2}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">City</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.office_city}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">State</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.office_state}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Zip Code</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{lsv.office_postal}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" onClick={this.onModify}>Modify</Button>
                            </CardActions>
                        </>
                    )}
            </Card>
        )
    }

    public onModify = () => {
        this.setState({editing: true});
    };

    public onSave = (values: Partial<LivescanVendor>) => {
        const { lsv, dispatch } = this.props;
        
        dispatch(lsvActions.update(lsv.id, values));

        this.setState({editing: false});
    }

    public onCancel = () => {
        this.setState({editing: false});
    }
}

export default connect()(withTheme(withStyles(styles)(ProfileSummaryCard)));