import { IReturn as LivescanVendor } from "../api/resources/LivescanVendor";
import { IDynamicFieldEntry } from "../api/resources/WaiverSignature";

export const actionTypes = {
    CLEAR: 'wizard.new.clear',
    START: 'wizard.new.start',
    ACCEPT_DISCLAIMER: 'wizard.new.accept.disclaimer',
    SELECT_LSV: 'wizard.new.select.lsv',
    SELECT_DATETIME: 'wizard.new.select.datetime',
    SELECT_TYPE: 'wizard.new.set.type',
    SET_DATA: 'wizard.new.set.data',
    SIGN_WAIVER: 'wizard.new.sign.waiver',
    SET_PAYMENT: 'wizard.new.set.payment',
    CONFIRMED: 'wizard.new.confirmed',
};

export const actions = {
    clear: () => ({
        type: actionTypes.CLEAR
    }),

    start: () => ({
        type: actionTypes.START
    }),

    acceptDisclaimer: () => ({
        type: actionTypes.ACCEPT_DISCLAIMER
    }),

    selectLSV: (lsv: LivescanVendor) => ({
        type: actionTypes.SELECT_LSV,
        lsv
    }),
    
    selectDateTime: (date: Date) => ({
        type: actionTypes.SELECT_DATETIME,
        date
    }),

    selectType: (applicantType: number, location?: number, notes?: string, vendor?: number, paymentMethod?: string) => ({
        type: actionTypes.SELECT_TYPE,
        applicantType,
        location,
        notes,
        vendor,
        paymentMethod
    }),

    setData: (
        citizenship: string,
        firstName: string,
        middleName: string, 
        lastName: string, 
        dob: Date,
        ssn4: string,
        phone: string,
        email: string,
        gender: string,
        race: string,
        height: string,
        weight: string,
        eye: string,
        hair: string,
        pob: string,
        reason: string,
        residence: string
    ) => ({
        type: actionTypes.SET_DATA,
        citizenship,
        firstName, 
        middleName,
        lastName, 
        dob,
        ssn4,
        phone,
        email,
        gender,
        race,
        height,
        weight,
        eye,
        hair,
        pob,
        reason,
        residence
    }),

    signWaiver: (id: number, fields: IDynamicFieldEntry[], signature: string) => ({
        type: actionTypes.SIGN_WAIVER,
        id,
        fields,
        signature
    }),

    setPayment: (cardNumber: string, cardExpiry: string, cardholderName: string, cardCode: string, cardZip: string) => ({
        type: actionTypes.SET_PAYMENT,
        cardNumber,
        cardExpiry,
        cardholderName,
        cardCode,
        cardZip
    }),

    confirm: () => ({
        type: actionTypes.CONFIRMED
    }),
};
