import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	city: string;
	contact_email: string;
	contact_first_name: string;
	contact_last_name: string;
	contact_phone: string;
	employer: number;
	entity_type?: string;
	id: number;
	identifier?: string;
	name: string;
	state: string;
	street1: string;
	street2: string;
	zip_code: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "vendor",
	rootUrl: getAPIUrl("/vendors"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});