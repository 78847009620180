import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	end_datetime: string;
	id: number;
	lsv: number;
	start_datetime: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "blockedtime",
	rootUrl: getAPIUrl("/blockedtimes"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});