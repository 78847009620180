import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	applicant: number;
	change_type: "CR"|"DM"|"SM";
	date: string;
	id: number;
	note: string;
	user: number;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "applicanthistory",
	rootUrl: getAPIUrl("/applicanthistories"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});