import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Button,
    Collapse,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withTheme, 
    WithTheme 
} from '@material-ui/core';
import { Delete, MoreVert } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { ApplicationState } from '../../../reducers';
import { IReturn as ApplicantType, actions as applicantTypeActions } from '../../../api/resources/ApplicantType';
import { IReturn as ORI, actions as oriActions } from '../../../api/resources/ORI';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        }
    });

interface IProps {
    index: number;
    applicantType: ApplicantType;
    ori?: ORI;
    onDelete: (applicantType: ApplicantType) => void;
};

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { applicantType } = ownProps;
  
    return {
        ori: applicantType ? Object.values(state.ori.data).find((item)=>item.id===applicantType.ori) : undefined,    
    };
};

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ApplicantTypeItem extends React.PureComponent<FullProps> {
    public state = {}
    
    render() {
        const { index, applicantType, ori, classes } = this.props;
        
        return (
            <React.Fragment>
                {index != 0 && (
                <Divider component="li" />
                )}
                <ListItem>
                    <ListItemText primary={(
                        <>{ori?.description}</>
                    )} secondary={(
                        <>{ori?.ori}</>
                    )} />
                    
                    <ListItemSecondaryAction>
                        <IconButton onClick={this.handleDeleteClick}>
                            <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {/* <UserChangePasswordModal user={user} open={showChangeModal} onDismiss={this.handleChangePasswordDismiss} />
                <UserDeleteModal open={showDeleteModal} onDismiss={this.handleDeleteDismiss} />
                <UserEditModal user={user} open={showEditModal} onDismiss={this.handleEditDismiss} /> */}
            </React.Fragment>
        )
    }

    private handleDeleteClick = () => {
        const { applicantType, onDelete } = this.props;
        onDelete(applicantType);
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(ApplicantTypeItem)));