import { Resource, CombineResource, IStateBucket, Call } from "restdux";
import { getAPIUrl, getHeaders, transformIndex, getCallListAPIUrlFunction } from "../global/globals";

export interface IReturn {
	id: string;
	creator: number;
	created: string;
	employer: number;
	lsv: number;
	modified: string;
	mime_type: string;
	original_name: string;
	s3_key_override: string;
	url: string;
}

export interface IUploadable {
	upload_url: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "file",
	rootUrl: getAPIUrl("/files"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {
	upload: Call<{}, {}, IStateBucket<IReturn & IUploadable>>({
		headers: getHeaders,
		method: "POST",
		url: getCallListAPIUrlFunction("/files", "upload"),
	})
});
