import { Resource, CombineResource } from "restdux";
import { getAPIUrl, getHeaders, transformIndex } from "../global/globals";

export type CardType = 'VS'|'MC'|'AX'|'DS';

export interface IReturn {
	appointment: number;
	card_type: CardType;
	id: number;
	name: string;
	token: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

const resource = Resource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "paymenttoken",
	rootUrl: getAPIUrl("/paymenttokens"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {});