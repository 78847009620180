import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Card,
    CardActions,
    CardContent,
    Input,
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    InputProps
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as LivescanVendor, actions as lsvActions } from '../../../api/resources/LivescanVendor';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';


const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        metaData: {
            marginTop: 12,
        }, 
        readOnlyArea: {
            // backgroundColor: "#fafafa",
            border: "1px solid #c8c8c8",
        }
    });

interface IProps {
    lsv: LivescanVendor;
};

interface IState {
    editing: boolean;
}

const FormSchema = Yup.object().shape({
    applicant_instructions: Yup.string(),
    location_name: Yup.string().required(),
    location_email: Yup.string().email().required(),
    location_phone: Yup.string().required(),
    location_street1: Yup.string().required(),
    location_street2: Yup.string(),
    location_city: Yup.string().required(),
    location_state: Yup.string().required(),
    location_postal: Yup.string().required(),
});

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ProfileSummaryCard extends React.PureComponent<FullProps, IState> {
    public state = {
        editing: false
    }
    
    render() {
        const { lsv, classes } = this.props;
        const { editing } = this.state;

        return (
            <Card>
                {editing ? (
                    <Formik
                        initialValues={{
                            applicant_instructions: lsv.applicant_instructions,
                            location_name: lsv.location_name,
                            location_email: lsv.location_email,
                            location_phone: lsv.location_phone,
                            location_street1: lsv.location_street1,
                            location_street2: lsv.location_street2,
                            location_city: lsv.location_city,
                            location_state: lsv.location_state,
                            location_postal: lsv.location_postal
                        }}
                        validationSchema={FormSchema}
                        onSubmit={(values, {setSubmitting}) => {
                            this.onSave(values);
                        }}
                    >
                    {({ submitForm, isSubmitting }) => (
                        <Form>
                            <CardContent>
                                <Grid container spacing={2} className={classes.metaData}>
                                    <Grid item sm={6} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Name</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="location_name" fullWidth required autoFocus />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Email</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="location_email" fullWidth required />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Phone</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="location_phone" fullWidth required />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">Applicant Instructions</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field component={TextField} InputProps={{multiline: true}} name="applicant_instructions" fullWidth />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Address 1</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="location_street1" fullWidth required />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Address 2</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="location_street2" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">City</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="location_city" fullWidth required />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">State</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="location_state" fullWidth required />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">Zip Code</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field component={TextField} name="location_postal" fullWidth required />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" onClick={submitForm}>Save</Button>
                                <Button onClick={this.onCancel}>Cancel</Button>
                            </CardActions>
                        </Form>
                    )}
                    </Formik>
                ) : (
                    <>
                        <CardContent>
                            <Grid container spacing={2} className={classes.metaData}>
                                <Grid item sm={6} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Name</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.location_name}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Email</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.location_email}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Phone</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.location_phone}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Applicant Instructions</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2"><strong>{lsv.applicant_instructions}</strong></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Address 1</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.location_street1}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Address 2</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.location_street2}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">City</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.location_city}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">State</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.location_state}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Zip Code</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{lsv.location_postal}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button color="primary" onClick={this.onModify}>Modify</Button>
                        </CardActions>
                    </>
                )}
            </Card>
        )
    }

    public onModify = () => {
        this.setState({editing: true});
    };

    public onSave = (values: Partial<LivescanVendor>) => {
        const { lsv, dispatch } = this.props;
        
        dispatch(lsvActions.update(lsv.id, values));

        this.setState({editing: false});
    }

    public onCancel = () => {
        this.setState({editing: false});
    }
}

export default connect()(withTheme(withStyles(styles)(ProfileSummaryCard)));