import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
  createStyles,
  Grid,
  List,
  Paper,
  Theme,
  WithTheme,
  withTheme,
  WithStyles,
  withStyles,
  Typography,
} from '@material-ui/core';
import '../../styles/App.css';
import MainLayout from '../../components/MainLayout';
import LoadIndicator from '../../components/LoadIndicator';
import RoleGate from '../../components/RoleGate';
import ApplicantAddressesCard from '../../components/applicant/addresses/Card';
import ApplicantIDsCard from '../../components/applicant/ids/Card';
import ApplicantLocationsCard from '../../components/applicant/locations/Card';
import ApplicantNotesCard from '../../components/applicant/notes/Card';
import ApplicantPositionCard from '../../components/applicant/position/Card';
import ApplicantStatusCard from '../../components/applicant/status/Card';
import ApplicantSummaryCard from '../../components/applicant/summary/Card';
import ApplicantWaiversCard from '../../components/applicant/waivers/Card';
import { actions as applicantActions, IReturn as Applicant } from '../../api/resources/Applicant';
import { ApplicationState } from '../../reducers';

const styles = (theme: Theme) =>
    createStyles({
        paperActions: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
        paperTitle: {
          padding: theme.spacing(2),
          paddingBottom: 0,
        },
        centeredPaperContent: {
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        centeredAction: {
          margin: theme.spacing(1),
        },
        paperContent: {
          padding: theme.spacing(2),
        },
        item: {
          display: 'flex',
        },
        itemPaper: {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        spacer: {
          flex: 1,
        },
        contentArea: {
          marginBottom: theme.spacing(2),
        }
    });

interface IState {
  isLoading: boolean;
};

interface IProps {
  applicant?: Applicant;
  applicantId: number;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
  const { applicantId } = ownProps;
  const applicant = state.applicant.data[applicantId];

	return {
    applicant,
    identifications: Object.values(state.identification.data).filter((item)=>item.applicant===applicantId),
	};
};

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class Record extends React.PureComponent<FullProps, IState> {
  state = {
    isLoading: false,
  }
  
  public async componentDidMount() {
    const { applicantId, dispatch } = this.props;
    let { applicant } = this.props;
    applicant || await dispatch(applicantActions.cacheread({}, applicantId)).then((value) => {
      applicant = value.result;
    }).catch(console.error);
  }

  render() {
    const { isLoading } = this.state;
    const { applicant, classes } = this.props;
    
    return (
      <>
        {applicant ? (
          <MainLayout title={`${applicant.last_name}, ${applicant.first_name}`}>
              <RoleGate role='admin' />
              {isLoading ? (
                <LoadIndicator />
              ) : (
                <>
                  <Grid container spacing={3} className={classes.contentArea}>
                    <Grid item xs={12}>
                      <ApplicantSummaryCard applicant={applicant} />
                    </Grid>

                    <Grid item sm={4} xs={12} className={classes.item}>
                      <ApplicantPositionCard applicant={applicant} />
                    </Grid>
                    <Grid item sm={4} xs={12} className={classes.item}>
                      <ApplicantLocationsCard applicant={applicant} />
                    </Grid>
                    <Grid item sm={4} xs={12} className={classes.item}>
                      <ApplicantStatusCard applicant={applicant} />
                    </Grid>
                    
                    <Grid item sm={4} xs={12} className={classes.item}>
                      <ApplicantAddressesCard applicant={applicant} addressType='HOME' />
                    </Grid>
                    <Grid item sm={4} xs={12} className={classes.item}>
                      <ApplicantAddressesCard applicant={applicant} addressType='WORK' />
                    </Grid>
                    <Grid item sm={4} xs={12} className={classes.item}>
                      <ApplicantIDsCard applicant={applicant} />
                    </Grid>

                    <Grid item sm={6} xs={12} className={classes.item}>
                      <ApplicantWaiversCard applicant={applicant} />
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.item}>
                      <ApplicantNotesCard applicant={applicant} />
                    </Grid>
                  </Grid>
                </>
              )}
          </MainLayout>
          ) : (
            <LoadIndicator />
          )}        
      </>
    );
  }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(Record)));