import { Call, Resource, CombineResource, IStateBucket } from "restdux";
import { getAPIUrl, getCallAPIUrlFunction, getCallListAPIUrlFunction, getHeaders, transformIndex } from "../global/globals";

export interface IReturn {
	applicant_type: number;
	category: string;
	created?: string;
	dob?: string;
	citizenship?: string;
	email: string;
	employer: number;
	eye: string;
	first_name: string;
	gender: string;
	hair: string;
	height: string;
	id: number;
	last_name: string;
	locations: number[];
	middle_name?: string;
	phone: string;
	phone_ext: string;
	pob: string;
	notes: string;
	race: string;
	reason: string;
	residence: string;
	safe_date: string;
	ssn4: string;
	status_code: number;
	submitted?: string;
	transaction_id?: string;
	position: string;
	vendor: number | null;
	weight: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {}

export interface ISubmission {
	modified_date: string;
	tcn: string;
}

export interface ILastCheckReturn {
	timestamp: string;
}

export interface ILookupParameters {
	reg_number?: string;
	last_name: string;
	email: string;
}

export interface IFindParameters {
	reg_number?: string;
	first_name?: string;
	last_name?: string;
}

const resource = Resource<IParameters, ISend, IReturn>({
	batchReads: true,
	headers: getHeaders,
	methodUpdate: "PATCH",
	name: "applicant",
	rootUrl: getAPIUrl("/applicants"),
	transformIndex,
});

export const { actions, types, reducer } = CombineResource(resource, {
	applySubmissions: Call<{}, ISubmission[], IReturn, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "POST",
		url: getCallListAPIUrlFunction("/applicants", "submissions"),
	}),
	find: Call<IFindParameters, {}, IReturn, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "GET",
		url: getCallListAPIUrlFunction("/applicants", "find"),
	}),
	lastCheck: Call<{}, {}, ILastCheckReturn, IStateBucket<ILastCheckReturn>>({
		headers: getHeaders,
		method: "GET",
		url: getCallListAPIUrlFunction("/applicants", "last_checked"),
	}),
	lookup: Call<ILookupParameters, {}, IReturn, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "GET",
		url: getCallListAPIUrlFunction("/applicants", "lookup"),
	}),
	illegible: Call<{}, {}, IReturn, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "PATCH",
		reducer: (state: IStateBucket<IReturn> = {data: {}, meta: {}}, action) => {
			if (!action.id || !action.sent) {
				return state;
			}
			switch (action.type) {
				case types.illegible.success:
					return resource.updateEntity(state, action.id, action.result);
			}
			return state;
		},
		url: getCallAPIUrlFunction("/applicants", "illegible"),
	}),
	resend: Call<{}, {}, IReturn, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: "PATCH",
		reducer: (state: IStateBucket<IReturn> = {data: {}, meta: {}}, action) => {
			if (!action.id || !action.sent) {
				return state;
			}
			switch (action.type) {
				case types.resend.success:
					return resource.updateEntity(state, action.id, action.result);
			}
			return state;
		},
		url: getCallAPIUrlFunction("/applicants", "resend"),
	}),
});