import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
    Paper,
    List
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as Applicant } from '../../../api/resources/Applicant';
import { actions as addressActions, IReturn as Address } from '../../../api/resources/Address';
import AddressItem from '../../../components/address/Item';
import { ApplicationState } from '../../../reducers';
import EmptyState from '../../EmptyState';
import AddressAddModal from '../../address/AddEditModal';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        itemPaper: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        metaData: {
            marginTop: 12,
        },  
        paperActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        paperTitle: {
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        spacer: {
            flex: 1,
        }
    });

interface IProps {
    addresses?: Address[];
    applicant: Applicant;
    addressType: "HOME"|"WORK";
};

interface IState {
    showAddModal: boolean;
}

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => {
    const { applicant } = ownProps;
  
    return {
        addresses: applicant ? Object.values(state.address.data).filter((item)=>item.applicant===applicant.id) : [],    
    };
  };

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ApplicantAddressesCard extends React.PureComponent<FullProps, IState> {
    public state = {
        showAddModal: false
    }

    public componentDidMount() {
        this.loadDependencies();
    }

    public componentDidUpdate(prevProps: FullProps) {
        const { applicant } = this.props;

        if (prevProps.applicant != applicant)
            this.loadDependencies();
    }

    public async loadDependencies() {
        const { applicant, dispatch } = this.props;

        if (applicant)
            dispatch(addressActions.index(undefined, undefined, {applicant:applicant.id}));
    }
    
    render() {
        const { applicant, classes, addresses, addressType } = this.props;
        const { showAddModal } = this.state;

        const addressesMarkup = addresses ? addresses.filter((address) => address.address_type == addressType).map((address, index) => (
            <AddressItem index={index} address={address} key={index} />
        )) : [];

        return (
            <Paper className={classes.itemPaper}>
                <Typography variant="subtitle1" className={classes.paperTitle}>
                    {addressType.charAt(0).toUpperCase()+addressType.slice(1).toLowerCase()} Addresses
                </Typography>
                
                {addressesMarkup && addressesMarkup.length > 0 ? (
                    <List>{addressesMarkup}</List>
                ) : (
                    <EmptyState icon='error' text='None to display' />
                )}

                <div className={classes.spacer}></div>
                <div className={classes.paperActions}>
                    <Button color="primary" onClick={this.onAdd}>Add</Button>
                </div>
                <AddressAddModal addressType={addressType} applicant={applicant} open={showAddModal} onDismiss={this.handleAddDismiss} />
                
            </Paper>
        )
    }

    private onAdd = () => {
        this.setState({showAddModal: true});
    }

    private handleAddDismiss = () => {
        this.setState({showAddModal: false});
    }
}

export default connect(mapStateToProps)(withTheme(withStyles(styles)(ApplicantAddressesCard)));