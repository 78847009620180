import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import { 
    Button,
    createStyles, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme, 
    withStyles, 
    WithStyles, 
    MenuItem
} from '@material-ui/core';
import { IReturn as Applicant } from '../../api/resources/Applicant';
import { IReturn as Identification, actions as identificationActions } from '../../api/resources/Identification';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';


const styles = (theme: Theme) =>
    createStyles({});

interface IProps {
    applicant?: Applicant;
    identification?: Identification;
    onDismiss: (shouldRefresh: boolean) => void;
    open: boolean;
};

const FormSchema = Yup.object().shape({
    id_type: Yup.string().required(),
    state: Yup.string().required(),
    identifier: Yup.string().required(),
});

type FullProps = IProps & WithStyles<typeof styles> & IDispatchProp;
class IdentificationAddEditModal extends React.Component<FullProps> {
    render() {
        const { classes, identification, open } = this.props;
    
        return (
            <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{identification?.id ? (<>Edit Identification</>) : (<>Add Identification</>)}</DialogTitle>
                <Formik
                    initialValues={{
                        id_type: identification ? identification.id_type : '',
                        identifier: identification ? identification.identifier : '',
                        state: identification ? identification.state : '',
                    }}
                    validationSchema={FormSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        this.handleSave(values);
                    }}
                >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            <DialogContentText>
                                {identification?.id ? (
                                    <>Edit identification below.</>
                                ) : (
                                    <>Add new identification below.</>
                                )}                        
                            </DialogContentText>
                            <Field component={TextField} name="id_type" label="ID Type" fullWidth select required>
                                <MenuItem value={'DL'}>Driver's License</MenuItem>
                                <MenuItem value={'PP'}>Passport</MenuItem>
                            </Field>
                            <Field component={TextField} label="Issuer State" name="state" fullWidth required />
                            <Field component={TextField} label="Identifier" name="identifier" fullWidth required />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancel} color="primary">Cancel</Button>
                            <Button onClick={submitForm} color="primary">Save</Button>
                        </DialogActions>
                    </Form>
                )}
                </Formik>
                
            </Dialog>
        )
    }

    private handleCancel = () => {
        this.props.onDismiss(false);
    }

    private handleSave = (values: Partial<Identification>) => {
        this.saveAndDismiss(values);
    }

    private async saveAndDismiss(values: Partial<Identification>) {
        const { dispatch } = this.props;
        const { identification } = this.props;
        if (identification?.id) {
            const hydratedModel = {
                ...identification,
                ...values
            };
            await dispatch(identificationActions.update(identification.id, hydratedModel)).catch(console.error);
        } 
        else {
            const { applicant } = this.props;
            const hydratedModel = {
                applicant: applicant?.id,
                ...values
            };
            await dispatch(identificationActions.create(undefined, hydratedModel)).catch(console.error);
        }
        this.props.onDismiss(true);
    }
}

export default connect()(withStyles(styles)(IdentificationAddEditModal));