import React from 'react';
import { connect } from 'react-redux';
import { IDispatchProp } from 'restdux';
import {
    Card,
    CardActions,
    CardContent,
    Input,
    Theme,
    withTheme, 
    WithTheme, 
    Typography,
    Grid,
    Button,
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IReturn as Applicant, actions as applicantActions } from '../../../api/resources/Applicant';
import Moment from 'react-moment';
import moment from 'moment';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '8px 16px',
        },
        itemActions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        metaData: {
            marginTop: 12,
        },  
    });

interface IProps {
    applicant: Applicant;
};

interface IState {
    editing: boolean;
    snapshot?: IPartialApplicant;
}

interface IPartialApplicant {
    dob: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    safe_date: string;
    ssn4: string;
    transaction_id?: string;
    submitted: string;
};

type FullProps = IProps & WithStyles<typeof styles> & WithTheme & IDispatchProp;
class ApplicantSummaryCard extends React.PureComponent<FullProps, IState> {
    public state = {
        editing: false,
        snapshot: {
            dob: "",
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            safe_date: "",
            ssn4: "",
            transaction_id: "" as string|undefined,
            submitted: "",
        }
    }
    
    render() {
        const { applicant, classes } = this.props;
        const { editing, snapshot } = this.state;

        return (
            <Card>
                <CardContent>
                    <Typography variant="h4">
                    {applicant.last_name}, {applicant.first_name}
                    </Typography>
                    <Grid container spacing={2} className={classes.metaData}>
                    <Grid item sm={6} xs={12}>
                        <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Phone</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {editing ? (
                                <Input value={snapshot.phone} onChange={this.updatePhone} />
                            ) : (
                                <Typography variant="body2">{applicant.phone} {applicant.phone_ext && (<>x {applicant.phone_ext}</>)}</Typography>
                            )}                            
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Email</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {editing ? (
                                <Input value={snapshot.email} onChange={this.updateEmail} />
                            ) : (
                                <Typography variant="body2">{applicant.email}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">SSN</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {editing ? (
                                <Input value={snapshot.ssn4} onChange={this.updateSSN} />
                            ) : (
                                <Typography variant="body2">{applicant.ssn4}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">DOB</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {editing ? (
                                // <Input value={applicant.dob} disabled />
                                <Input type="date" value={snapshot.dob} onChange={this.updateDOB} />
                            ) : (
                                <Typography variant="body2">{applicant.dob ? <Moment format="l">{applicant.dob}</Moment> : <>Not set</>}</Typography>
                            )}                            
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Grid container spacing={1}>
                        
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Created</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {editing ? (
                                <Input value={applicant.created} disabled />
                            ) : (
                                <Typography variant="body2"><Moment format="l">{applicant.created}</Moment></Typography>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Submitted</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {editing ? (
                                // <Input value={applicant.submitted} disabled />
                                <Input type="date" value={snapshot.submitted} onChange={this.updateSubmittedDate} />
                            ) : (
                                <Typography variant="body2">{applicant.submitted ? <Moment format="l">{applicant.submitted}</Moment> : <>Not set</>}</Typography>
                            )}
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Transaction ID</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {editing ? (
                                <Input value={snapshot.transaction_id} onChange={this.updateTransactionID} />
                            ) : (
                                <Typography variant="body2">{applicant.transaction_id}</Typography>
                            )}                            
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">SAFE Expiration</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {editing ? (
                                <Input type="date" value={snapshot.safe_date} onChange={this.updateSAFEDate} />
                            ) : (
                                <Typography variant="body2">{applicant.safe_date ? <Moment format="l">{applicant.safe_date}</Moment> : <>N/A</>}</Typography>
                            )}
                        </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    {editing ? (
                        <>
                            <Button color="primary" onClick={this.onSave}>Save</Button>
                            <Button onClick={this.onCancel}>Cancel</Button>
                        </>
                    ) : (
                        <Button color="primary" onClick={this.onModify}>Modify</Button>
                    )}
                </CardActions>
            </Card>
        )
    }

    public onModify = () => {
        const { applicant } = this.props;
        const { dob, first_name, last_name, safe_date, ssn4, phone, email, transaction_id, submitted } = applicant;
        const snapshot = {
            dob: (dob as any) as string,
            first_name,
            last_name,
            phone,
            email,
            safe_date,
            ssn4,
            transaction_id,
            submitted: submitted ? moment(submitted).format('YYYY-MM-DD') : "",
        };
        // debugger;
        this.setState({editing: true, snapshot});
    };

    public onSave = () => {
        const { applicant, dispatch } = this.props;
        const { snapshot } = this.state;

        if (snapshot.submitted) {
            dispatch(applicantActions.update(applicant.id, {...snapshot, submitted: new Date(snapshot.submitted + "T12:00:00Z").toISOString()}));
        }
        else {
            dispatch(applicantActions.update(applicant.id, snapshot));
        }

        this.setState({editing: false});
    }

    public onCancel = () => {
        this.setState({editing: false});
    }

    public updatePhone = (inputProps: React.ChangeEvent<HTMLInputElement>) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: {
                ...snapshot,
                phone: inputProps.target.value
            }
        });
    }

    public updateEmail = (inputProps: React.ChangeEvent<HTMLInputElement>) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: {
                ...snapshot,
                email: inputProps.target.value
            }
        });
    }

    public updateSSN = (inputProps: React.ChangeEvent<HTMLInputElement>) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: {
                ...snapshot,
                ssn4: inputProps.target.value
            }
        });
    }

    public updateTransactionID = (inputProps: React.ChangeEvent<HTMLInputElement>) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: {
                ...snapshot,
                transaction_id: inputProps.target.value
            }
        });
    }

    public updateSAFEDate = (inputProps: React.ChangeEvent<HTMLInputElement>) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: {
                ...snapshot,
                safe_date: inputProps.target.value
            }
        });
    }

    public updateSubmittedDate = (inputProps: React.ChangeEvent<HTMLInputElement>) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: {
                ...snapshot,
                submitted: inputProps.target.value
            }
        });
    }

    public updateDOB = (inputProps: React.ChangeEvent<HTMLInputElement>) => {
        const { snapshot } = this.state;
        this.setState({
            snapshot: {
                ...snapshot,
                dob: inputProps.target.value
            }
        });
    }
}

export default connect()(withTheme(withStyles(styles)(ApplicantSummaryCard)));