import { actionTypes } from '../actions/illegibleWizard';
import { IReturn as Applicant } from '../api/resources/Applicant';
import { IReturn as LivescanVendor } from '../api/resources/LivescanVendor';

interface IBaseAction {
  type: string;
}

interface IAllPossiblePayloads {
  regNumber: string;
  email: string;
  lastName: string;
  applicant: Applicant;
  lsv: LivescanVendor;
  date: Date;
};

type WizardAction = IBaseAction & Partial<IAllPossiblePayloads>;

export interface IWizardState {
  disclaimerAccepted?: boolean;

  regNumber?: string;
  email?: string;
  lastName?: string;

  applicant?: Applicant;

  lsv?: LivescanVendor;

  appointmentDateTime?: Date;
  
  step?: number;
}

const reducer = (state: IWizardState = {}, action: WizardAction) => {
  switch (action.type) {
    case actionTypes.CLEAR:
      return {};
    case actionTypes.START:
      return {
        step: 1
      };
    case actionTypes.ACCEPT_DISCLAIMER:
      return {
        ...state,
        step: 2
      };
    case actionTypes.SET_DATA:
      return {
        ...state,
        step: 3,
        regNumber: action.regNumber,
        email: action.email,
        lastName: action.lastName
      };
    case actionTypes.SET_RECORD:
      return {
        ...state,
        step: 4,
        applicant: action.applicant
      };
    case actionTypes.SELECT_LSV:
      return {
        ...state,
        step: 5,
        lsv: action.lsv
      };
    case actionTypes.SELECT_DATETIME:
      return {
        ...state,
        step: 6,
        appointmentDateTime: action.date
      };
    case actionTypes.CONFIRMED:
      return {
        ...state,
        step: 7
      };
  }
  return state;
}

export default reducer;
