import { Button, Grid, Snackbar, TextField, Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { authenticate } from 'redux-simple-auth'
import { Link as RouterLink } from 'react-router-dom';
import Password from '../../components/Password';
import React from 'react';
import LoginLayout from '../../components/LoginLayout';
import '../../styles/App.css';

interface IProps {
	dispatch: any,
}

const styles = (theme: Theme) =>
	createStyles({
		form: {
			marginTop: theme.spacing(1),
			width: '100%', // Fix IE 11 issue.
		},
		link: {
			padding: theme.spacing(2),
		},
		progress: {
			width: '100%',
		},
		submit: {
			marginTop: theme.spacing(2),
		},
	});

class Login extends React.PureComponent<WithStyles<typeof styles> & IProps> {
  
  state = {
		error: null,
		username: '',
		password: '',
		requested: false,
	};

  render() {
    const { classes } = this.props;
	const { error,requested } = this.state;

	const setUsername = (event: React.ChangeEvent<HTMLInputElement>) =>
		this.setState({
			username: (event.target).value,
		});
	const setPassword = (event: React.ChangeEvent<HTMLInputElement>) =>
		this.setState({
			password: (event.target).value,
		});
	const handleClose = () =>
		this.setState({error: null});

    return (
      <LoginLayout title={'Login'} name={'BIMS'} logo={'/bims-color.png'}>
        
        <h4>
			Restricted System Logon Authorization
		</h4>
		<p>
			This system is for the sole use of Biometric Information Management (BIM) and its customers. System usage may be monitored, recorded and subject to audit. Use of this system indicates consent to monitoring and recording. Unauthorized use is prohibited and may be subject to criminal and/or civil penalties.
		</p>
		
		<form className={classes.form} onSubmit={this.handleSubmitClick}>
			<TextField
				autoComplete="username"
				autoFocus={true}
				fullWidth={true}
				inputProps={{ maxLength: 250 }}
				label={'Username'}
				onChange={setUsername}
				required={true}
				type="text"
				disabled={requested}
			/>
			<Password label={'Password'} fullWidth={true} required={true} onChange={setPassword} disabled={requested} />
			<Grid container={true} justify="center">
				<RouterLink className={classes.link} to={'/forgotpassword'}>
					{'Forgot Password?'}
				</RouterLink>
			</Grid>
			<Button type="submit" fullWidth={true} variant="contained" color="primary" className={classes.submit} disabled={requested}>
				{'Log In'}
			</Button>
			{/* <Button component={RouterLink} to="/dashboard" type="submit" fullWidth={true} variant="contained" color="primary" className={classes.submit} disabled={requested}>
				{'Log In'}
			</Button> */}
		</form> 
		<Snackbar
          anchorOrigin={{vertical:'bottom',horizontal:'left'}}
          open={!!error}
          autoHideDuration={2500}
          onClose={handleClose}
          message={error}
        />
      </LoginLayout>
    );
  }

  handleSubmitClick = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    const { username, password } = this.state;
    
	this.setState({requested:true});
	dispatch(authenticate('credentials', {username, password}))
	.then(() => {
		this.setState({requested:false});
	})
	.catch((error: any) => {
		if (error.payload && error.payload.detail) {
			this.setState({
				error: error.payload.detail, 
				requested: false
			});
		}
		else {
			this.setState({
				error:"Failed to log in",
				requested: false
			});
		}	
	});
  }
}

export default connect()(withStyles(styles)(Login));